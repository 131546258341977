import {
  Component
} from "react";
import Definition from "../../../../../../lib/Definition.js";
import {
  Fun
} from '../../../../../../lib/Function.lib.js';
import Chips from "../../../../../Forms/Chips.js";
import Box from '../../../../../Layout/Wrappers/Box.jsx';
import Menu from '../../../../../Layout/Wrappers/Menu.jsx';
import TextField from '../../../../../Layout/Wrappers/TextField.jsx';
import Typography, { Label } from '../../../../../Layout/Wrappers/Typography.jsx';
import AndElement from "../Operators/And.js";
import NotElement from "../Operators/Not.js";
import OrElement from "../Operators/Or.js";
import ChipElement from "./ChipElement.js";
import ComplexElement from "./ComplexElement.js";
import JobTags from "./JobTags.js";
import OperatorElement from "./OperatorElement.js";
import TextElement from "./TextElement.js";

const ElementComponents = {
  'chip': ChipElement,
  'text': TextElement,
  'complex': ComplexElement,
  'operator': OperatorElement,
  'and': AndElement,
  'or': OrElement,
  'not': NotElement,
};

const Operators = [
  { name: 'and' },
  { name: 'or' },
  { name: 'not' }
];

const CHIP_KEYS_BY_CATEGORY = ['technicalSkills', 'positiveSignals', 'negativeSignals', 'jobLevelGroup'];
const categoriesWithKeys = ['technicalSkills', 'positiveSignals', 'negativeSignals'];

class ComplexForm extends Component {
  constructor() {
    super(...arguments);

    let primary = !!this.props.updateAble ? this.props.updateAble.args.find(el => !!el.preferred) : null;

    this.state = {
      number: !!this.props.updateAble ? this.props.updateAble.atLeast : 1,
      operator: !!this.props.updateAble ? this.props.updateAble.type : 'or',
      chips: !!this.props.updateAble ? this.props.updateAble.args.map(arg => {
        let label = Definition.getLabel(arg.key, arg.value)
        if (arg.type === 'chipGroup') {
          label = Definition.getGroupObj(arg.key, arg.value).name;
        }
        if (arg.type === 'chipTag') {
          label = arg.value;
        }
        return { key: arg.key, id: arg.value, type: arg.type, label, selected: true };
      }) : [],
      specialInstruction: !!this.props.updateAble ? this.props.updateAble.specialInstruction : "",
      primary: !!primary ? { id: primary.value, key: primary.key } : '',
      category: !!this.props.updateAble ? this.props.updateAble.category : 'technicalSkills'
    }
    this._constJobTags = this.props.jobTags.map(tag => ({ id: tag.id, key: tag.key, label: tag.value })) || [];

  }

  finalStruct = () => {
    let { number, operator, chips, specialInstruction, primary } = this.state;

    let conditional = {};

    if (operator === 'or') {
      conditional.atLeast = number;
    }

    return {
      ...conditional,
      type: operator,
      specialInstruction,
      // category,
      args: chips.map(obj => {
        let type = !!obj.type ? obj.type : 'chip';
        type = !!obj.key && !categoriesWithKeys.includes(obj.key) ? 'chipTag' : type;
        let conditional = {};

        if (operator === 'or') {
          conditional.preferred = ((obj.id === primary.id) && (obj.key === primary.key));
        }

        const toReturn = {
          type: type,
          key: obj.key,
          value: type !== 'chipTag' ? obj.id : obj.label,
          specialInstruction: '',
          ...conditional
        }
        if (type !== 'chipTag') {
          toReturn['category'] = obj.key;
        }

        return toReturn;
      })
    }
  }

  updateAttr = (key, value) => {

    const { onUpdate } = this.props;
    const updateObj = { [key]: value };

    if (key === 'category') {
      updateObj['chips'] = [];
      updateObj['primary'] = '';
    }

    if (key === 'operator') {
      if (value === 'not') {
        updateObj['chips'] = [];
        updateObj['primary'] = '';
      }
      else if (value === 'or') {
        updateObj['number'] = 1;
      }
    }

    this.setState(updateObj, () => {
      Fun(onUpdate)(this.finalStruct());
    });

  }

  gridOnChange = (newData, filter) => {
    let { onUpdate } = this.props;
    let { operator } = this.state;

    const otherData = this.state.chips.filter(filter);
    let finalChips = [...newData, ...otherData];

    if (operator === 'not') {
      finalChips = !!newData.length ? [newData.pop()] : [];
    }

    this.setState({ chips: finalChips }, () => {
      !!onUpdate && onUpdate(this.finalStruct())
    });
  }

  getAdditionalGroups = (key) => {
    let itemsGroup = [];

    try {
      itemsGroup = Definition.getRawDef(key).groups.map(g => ({
        label: g.name,
        id: g.id,
        key: key,
        type: 'chipGroup'
      }))
    } catch (e) {

    }
    return itemsGroup;
  }

  render() {

    const { number, operator, chips, specialInstruction, primary } = this.state;
    const { onUpdate, editable, dialogClass } = this.props;
    const OperatorComponent = ElementComponents[operator];
    const items = CHIP_KEYS_BY_CATEGORY.map(
      (key) => Definition.get(key).map(
        (category) => {
          category.key = (key === 'jobLevelGroup') ? category.key : key;
          return category;
        }
      )
    );

    return (
      <Box column w100
        role='Complex'
      >

        <TextField role='Complex__SpecialInstruction'
          rows={2}
          rowsMax={2}
          placeholder={"Write special instruction here"}
          value={specialInstruction}
          onChange={(event, text) => {
            this.updateAttr('specialInstruction', text)
          }}
          className='mt-1'
        />

        <Box row w100 noWrap
          role='Complex__DropdownsBar'
          className='mt-1'
        >

          <Box row wAuto mr>
            <Menu dropdown avoidNullOption
              name="level"
              required
              value={operator}
              onChange={(number) => this.updateAttr('operator', number)}
              options={Operators.map((obj, id) => (obj.name))}
              className='w-auto'
            />
          </Box>

          <Box row wAuto mr>
            <OperatorComponent
              number={number}
              onUpdate={this.updateAttr}
              editable={editable}
            />
          </Box>

          {!!chips && !!chips.length && (operator === 'or') &&
            <Box row wAuto noWrap>
              <Typography>
                Pick Primary
              </Typography>
              <Chips
                name="technicalSkills"
                items={chips.filter(chip => chip.type !== "chipGroup").map(chip => {
                  return { label: Definition.getLabel(chip.key, chip.id), ...chip }
                })}
                values={!!primary ? [primary] : []}
                commonCase={true}
                onChange={(chips, chipsObj) => {
                  let singleElement = chips.length ? chipsObj.pop() : '';
                  this.setState({ primary: singleElement }, () => {
                    !!onUpdate && onUpdate(this.finalStruct())
                  });
                }}
                hidePopupContent={() => {
                  document.getElementsByClassName(dialogClass)[0].classList.add("removeClass");
                }}
                displayPopupContent={() => {
                  document.getElementsByClassName(dialogClass)[0].classList.remove("removeClass");
                }}
              />
            </Box>
          }

        </Box>

        <Box row w100
          role='Complex__JobTags'
          className='mt-3'
        >
          <JobTags
            data={{
              items: this._constJobTags,
              additionalItems: items[3],
              values: chips.filter(chip => !categoriesWithKeys.includes(chip.key)),
              onChange: (chipsObjId, chipsObj) => {
                this.gridOnChange(chipsObj, (item) => categoriesWithKeys.includes(item.key))
              },
              dialogClass
            }}
          />
        </Box>

        <Box row w100 noWrap
          role='Complex__ChipsGroups'
          className='flex-align-left-top mt-3'
        >
          {
            [
              {
                acl: true,
                name: "technicalSkills",
                label: "Technical Skills",
                items: items[0],
                additionalItems: this.getAdditionalGroups("technicalSkills"),
                values: chips.filter(chip => chip.key === 'technicalSkills'),
                commonCase: true,
                onChange: (chips, chipsObjs) => {
                  this.gridOnChange(chipsObjs, (item) => item.key !== 'technicalSkills')
                },
                withOutDialog: true
              },
              {
                acl: true,
                name: "positiveSignals",
                label: "Positive Signals",
                items: items[1],
                additionalItems: this.getAdditionalGroups("positiveSignals"),
                values: chips.filter(chip => chip.key === 'positiveSignals'),
                commonCase: true,
                onChange: (chips, chipsObjs) => {
                  this.gridOnChange(chipsObjs, (item) => item.key !== 'positiveSignals')
                },
                withOutDialog: true
              },
              {
                acl: true,
                name: "negativeSignals",
                label: "Negative Signals",
                items: items[2],
                additionalItems: this.getAdditionalGroups("negativeSignals"),
                values: chips.filter(chip => chip.key === 'negativeSignals'),
                commonCase: true,
                onChange: (chips, chipsObjs) => {
                  this.gridOnChange(chipsObjs, (item) => item.key !== 'negativeSignals')
                },
                withOutDialog: true
              },

            ].map(
              (
                {
                  acl = true,
                  ...category
                },
                index
              ) => (
                <Box column flex1 h100
                  acl={acl}
                  key={`complex__chips__${index}_${category.name}`}
                  role='ComplexForm__ChipsColumn'
                  className='p-05'
                >
                  <Label className='tt-unset mb-05'>
                    {category.label}
                  </Label>
                  <Chips
                    {...category}
                    name={category.name}
                    label={category.label}
                    items={category.items}
                    additionalItems={category.additionalItems}
                    values={category.values}
                    onChange={category.onChange}
                    withOutDialog
                    commonCase
                  />
                </Box>
              )
            )}
        </Box>

      </Box>
    );
  }
}

export default ComplexForm;
