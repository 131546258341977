import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from "@mui/material";
import moment from 'moment';
import {
  useState
} from "react";
import {
  STAGE_CONFIRMATION,
  STAGE_OFFER,
  STAGE_ONSITE,
  STAGE_REVIEW,
  STAGE_SCREEN,
  STAGE_SUBMISSION
} from "../../dictionaries/Engagement.dic";
import EngagementUI from '../../dictionaries/EngagementUI.dic';
import AccountLib from '../../lib/Account';
import {
  defaultTrue
} from '../../lib/Boolean.lib';
import Core from "../../lib/Core";
import {
  ACCOUNT_ACTION__EDIT_ACCOUNT,
  ACCOUNT_ACTION__EDIT_CANDIDATE,
  REC_MSG_TYPE__REJECTION_ID
} from "../../lib/Definition";
import {
  EngagementLib
} from "../../lib/Engagement";
import globalErrorHandler from '../../lib/Error/globalErrorHandler.fun';
import {
  validateEmail
} from "../../lib/GenericTools.lib";
import Job from '../../lib/Job';
import jobSourceList from '../../lib/jobSourceList';
import {
  combineEmailsLists,
  mapEmailsListToRecipients,
  mapEmailsListToStringArray,
  sendSafeEmail
} from "../../lib/services/Email/Email.lib";
import Streak from '../../lib/Streak';
import {
  getJobSourceByJobId
} from '../../lib/tools/getJobSourceListApis';
import EmailPreview from "../Dialogs/EmailPreview";
import Button from '../Layout/Wrappers/Button';
import Checkbox from '../Layout/Wrappers/Checkbox';
import Col from "../Layout/Wrappers/Col";
import DatePicker from '../Layout/Wrappers/DatePicker';
import Divider from '../Layout/Wrappers/Divider';
import IconButton from '../Layout/Wrappers/IconButton';
import Message from '../Layout/Wrappers/Message';
import MultipleSelect from "../Layout/Wrappers/MultipleSelect";
import Row from "../Layout/Wrappers/Row";
import TextField from '../Layout/Wrappers/TextField';
import {
  RejectionReasonInfo
} from "./RejectionReasonInput";
import {
  RejectionReasonsMenu
} from "./RejectionReasonsMenu";
import {
  StatusesMenu
} from "./StatusesMenu";

const DATE_FORMAT = 'YYYY-MM-DD';

export const CLOSE_JOB__SEND_EMAILS__ACTIVE_ENGAGEMENTS__FLAG_KEY = 'close_job__send_emails__active_engagements';
export const CLOSE_JOB__SEND_EMAILS__PENDING_ENGAGEMENTS__FLAG_KEY = 'close_job__send_emails__pending_engagements';
export const CLOSE_JOB__SEND_EMAILS__SUBMITTED_ENGAGEMENTS__FLAG_KEY = 'close_job__send_emails__submitted_engagements';

function ActiveEngagementsNotificationPreview({
  jobId,
  snippets,
  onChange = () => null
}) {
  const [engagements, setEngagements] = useState();
  const [selected, setSelected] = useState();
  const [recipients, setRecipients] = useState();
  const [closedEngagements, setClosedEngagements] = useState();
  const [, reRender] = useState();

  const SEND_EMAILS = defaultTrue(Core.getKeyValue(CLOSE_JOB__SEND_EMAILS__ACTIVE_ENGAGEMENTS__FLAG_KEY));

  function getSelectedEngagements() {
    return engagements.filter(({ id }) => selected?.includes(id));
  }

  Core.setKeyValue('ActiveNotificationPreviewRef', {
    refetch({ showMessage }) { setTimeout(() => fetch({ showMessage })) },
    getSelectedEngagements,
    getUnselectedEngagements() {
      return engagements.filter(({ id }) => !selected?.includes(id));
    },
    getEngagements: () => engagements || [],
    getClosedEngagements: () => closedEngagements || []
  });

  async function fetch({ showMessage } = {}) {
    Core.showLoader();
    const validStages = [STAGE_REVIEW, STAGE_SCREEN, STAGE_ONSITE, STAGE_OFFER];
    const engagements = await EngagementLib.getWhere({ jobId, stage: { inq: validStages }, state: 'Open' }, undefined, undefined, true);
    setEngagements(engagements);
    let _selected = engagements.filter(({ id, stage }) => selected?.includes(id) || stage !== STAGE_OFFER).map(({ id }) => id);
    setSelected(_selected);
    let _recipients = {};
    engagements.forEach(engagement => {
      let recruiter = engagement.recruiter;
      let candidate = engagement.candidate;
      let recruiterRecipients = mapEmailsListToRecipients({
        emailsList: recruiter.emailsList,
        primaryContact: { name: recruiter._name, email: recruiter.email, accountType: 'Recruiter Main Contact' },
        accountType: 'Recruiter Additional Contact',
        messageTypes: [REC_MSG_TYPE__REJECTION_ID]
      });
      const candidateRecruiterRecipients = mapEmailsListToRecipients({
        emailsList: candidate ? candidate.emailsList : [],
        accountType: 'Candidate Recruiter Contact',
        messageTypes: [REC_MSG_TYPE__REJECTION_ID]
      });
      recruiterRecipients.emails = combineEmailsLists(candidateRecruiterRecipients.emails, recruiterRecipients.emails);
      recruiterRecipients.to = combineEmailsLists(candidateRecruiterRecipients.to, recruiterRecipients.to);
      recruiterRecipients.cc = combineEmailsLists(candidateRecruiterRecipients.cc, recruiterRecipients.cc);
      recruiterRecipients.bcc = combineEmailsLists(candidateRecruiterRecipients.bcc, recruiterRecipients.bcc);
      _recipients[engagement.id] = recruiterRecipients;
      _recipients[engagement.id]._to = mapEmailsListToStringArray(_recipients[engagement.id].to);
    });
    setRecipients(_recipients);
    onChange({ selected: _selected, recipients: _recipients });
    Core.hideMessage();
  }

  const handleToggleAll = (value) => () => {
    if (selected.length === engagements.length) {
      setSelected([]);
      onChange({ selected: [], recipients });
    }
    else {
      let _selected = engagements.map(({ id }) => id);
      setSelected(_selected);
      onChange({ selected: _selected, recipients });
    }
  };

  const handleToggle = (value) => () => {
    const currentIndex = selected.indexOf(value);
    const newChecked = [...selected];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setSelected(newChecked);
    onChange({ selected: newChecked, recipients });
  };

  if (!engagements) { fetch({ showMessage: false }); }

  return !engagements?.length ? (<Message>There are not already submitted candidates</Message>) : (
    <>
      <div className="d-flex flex-column">
        {engagements && selected && (
          <div>
            <Row>
              <Col className="d-flex flex-align-left-center" fullWidth>
                {SEND_EMAILS ? (
                  <label className="m-0 mr-1 t-normal">
                    Notify recruiters for already submitted candidates
                  </label>

                ) : (
                  <label className="m-0 mr-1 t-normal">
                    Submitted engagements
                  </label>
                )}
                <IconButton title="Refetch Engagements" onClick={event => fetch({ showMessage: true })}>
                  <i className="material-icons">refresh</i>
                </IconButton>
                <Checkbox
                  checked={SEND_EMAILS}
                  onChange={(event) => {
                    Core.setKeyValue(
                      CLOSE_JOB__SEND_EMAILS__ACTIVE_ENGAGEMENTS__FLAG_KEY,
                      event.target.checked
                    );
                    reRender(Date.now());
                  }}
                  labelProps={{ className: 'f-sm fw-500 c-black-medium' }}
                  tooltip={EngagementUI.closeEngagements.sendEmails.tooltip}
                >
                  Send emails
                </Checkbox>
              </Col>
            </Row>
            <div className="ml-4">
              <List sx={{ bgcolor: 'background.paper' }}>
                <ListItem>
                  <div className="d-flex flex-align-left-center w-50">
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={selected.length === engagements.length}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': `checkbox-list-label-all` }}
                        onClick={handleToggleAll()}
                      />
                    </ListItemIcon>
                    <ListItemText id={`checkbox-list-label-all`} primary={<strong>All</strong>} />
                  </div>
                </ListItem>
                {engagements.map(engagement => {
                  const { id, stage, status, state, candidate } = engagement;
                  const labelId = `checkbox-list-label-${id}`;
                  return (
                    <ListItem
                      key={id}
                      disablePadding
                    >
                      <ListItemButton role={undefined} dense>
                        <div className="d-flex flex-align-left-center w-50">
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              checked={selected.indexOf(id) !== -1}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ 'aria-labelledby': labelId }}
                              onClick={handleToggle(id)}
                            />
                          </ListItemIcon>
                          <ListItemText className="truncate" id={labelId} primary={`${candidate._name} - ${stage} - ${status} - ${state}`} />
                        </div>
                        {SEND_EMAILS && (
                          <div className="d-flex flex-align-left-center w-50 ml-auto d-none">
                            {recipients && recipients[engagement.id] && (
                              <>
                                <MultipleSelect
                                  placeholder={('type an email')}
                                  multiple="tags"
                                  data={recipients[engagement.id].emails.filter(c => validateEmail(c.email)).map(contact => {
                                    let accountType = contact.accountType ? <strong>{contact.accountType}:</strong> : '';
                                    let name = contact.name && String(contact.name).trim() ? ` ${contact.name}` : '';
                                    let email = ` <${contact.email}>`;
                                    return {
                                      label: (<small>{accountType}{name}{email}</small>),
                                      id: `${name}${email}`.trim()
                                    }
                                  })}
                                  value={recipients[engagement.id]._to}
                                  onChange={to => {
                                    let _recipients = { ...recipients };
                                    _recipients[engagement.id]._to = to;
                                    setRecipients(_recipients);
                                    onChange({ selected, recipients: _recipients });
                                  }}
                                  optionLabelProp
                                  maxTagCount={false}
                                />
                              </>
                            )}
                            {Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_ACCOUNT }) && (
                              <IconButton
                                aria-label="Popup Edit Account - REFETCH ENGAGEMENTS AFTER SAVE CONTACTS"
                                className="hint--left"
                                onClick={ev => Core.openPopUpFullSize(`/#/account/edit/${engagement.recruiter.id}`)}
                              >
                                <i className="material-icons">edit</i>
                              </IconButton>
                            )}
                          </div>
                        )}
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
              {SEND_EMAILS && (
                <div className="px-2">
                  <EmailPreview ref={self => Core.setKeyValue('ActiveNotificationEmailPreview', self)} omitUIRecipients snippets={snippets} taller />
                </div>
              )}
              <ActionBar
                id='ActiveEngagementsActionBar'
                selected={selected}
                sendEmailsFlag={SEND_EMAILS}
                onSubmit={({
                  status,
                  rejectionReason,
                  rejectionReasonAdditionalInfo,
                  holdDate,
                  holdDetails,
                }) => {

                  try {

                    let activeNotificationParams;

                    if (SEND_EMAILS) {
                      activeNotificationParams = Core.getKeyValue('ActiveNotificationEmailPreview').getParams();

                      if (!activeNotificationParams.subject) {
                        return Core.showError('Email subject is missing');
                      }
                      if (!activeNotificationParams.html) {
                        return Core.showError('Email content is missing');
                      }
                    }

                    let selectedEngagements = getSelectedEngagements();

                    Core.dialog.open({
                      title: <>Action Summary</>,
                      message: (
                        <>
                          <div>
                            {SEND_EMAILS
                              ? `Recruiters to receive notification and engagements to `
                              : `Engagements to `}
                            {!!status?.match(/e -/i) ? 'close' : !!status?.match(/h -/i) ? 'hold' : ''}
                            <ul>
                              {selectedEngagements.map((engagement, index) => {
                                const { candidate, stage, status, state } = engagement;
                                return (
                                  <li key={`engagement-to-close-${engagement.id}`}>
                                    {recipients[engagement.id]._to.join(', ')} - {candidate._name} - {stage} - {status} - {state}
                                  </li>
                                )
                              })}
                            </ul>
                          </div>
                          {!!engagements.filter(engagement => !selected.includes(engagement.id)).length && (
                            <div>
                              Engagements that will <b><u>not</u></b> be {!!status?.match(/e -/i) ? 'closed' : !!status?.match(/h -/i) ? 'hold' : ''}
                              <ul>
                                {engagements.filter(engagement => !selected.includes(engagement.id)).map((engagement, index) => {
                                  const { candidate, stage, status, state } = engagement;
                                  return (
                                    <li key={`engagement-to-close-${engagement.id}`}>{candidate._name} - {stage} - {status} - {state}</li>
                                  )
                                })}
                              </ul>
                            </div>
                          )}
                        </>
                      ),
                      actions: [
                        <Button outlined minW120>
                          Cancel
                        </Button>,
                        <Button primary minW120
                          onClick={async ev => {
                            let ok;
                            if (SEND_EMAILS) {
                              Core.showLoader('Sending Recruiter Emails');
                              ok = await Promise.all(
                                selected.map(async (engagementId, index) => {
                                  let _params = {
                                    ...activeNotificationParams,
                                    to: recipients[engagementId]._to.join(','),
                                    boxKey: engagements.find(engagement => {
                                      return engagementId === engagement.id
                                    })?.boxKey
                                  };
                                  return sendSafeEmail(_params);
                                })
                              ).catch(exception => Core.failure({ message: exception }));
                              console.debug('RECRUITERS', ok);
                              if (!ok) { return; }
                            }
                            Core.showLoader('Closing Engagements');
                            ok = await Promise.all(
                              selectedEngagements.map(async (engagement, index) => {
                                if (!!status?.match(/e -/i)) {
                                  return EngagementLib.update(engagement, {
                                    state: 'Closed',
                                    closed: new Date().getTime(),
                                    status,
                                    rejectionReason,
                                    rejectionReasonAdditionalInfo
                                  });
                                }
                                else if (!!status?.match(/h -/i)) {
                                  return EngagementLib.update(engagement, {
                                    status,
                                    holdDate
                                  });
                                }
                              })
                            ).then(() => {
                              setClosedEngagements(selectedEngagements);
                              setTimeout(() => {
                                fetch({ showMessage: true });
                                Core.getKeyValue('RecruiterOtherNotificationPreviewRef').refetch({ showMessage: true });
                              });
                            }).catch(exception => Core.failure({ message: exception }));
                            console.debug('ENGAGEMENTS', ok);
                          }}>
                          Confirm
                        </Button>
                      ],
                      paperStyle: {
                        minWidth: '50vw',
                        maxWidth: '70vw'
                      },
                    });
                  }
                  catch (error) {
                    globalErrorHandler(error);
                  }
                }}
              />
            </div>
          </div>
        )}
      </div>
      <Divider className="mt-4 mb-2" />
    </>
  );
}

function PendingEngagementsNotificationPreview({
  jobId,
  snippets,
  onChange = () => null
}) {
  const [engagements, setEngagements] = useState();
  const [selected, setSelected] = useState();
  const [recipients, setRecipients] = useState();
  const [closedEngagements, setClosedEngagements] = useState();
  const [, reRender] = useState();

  const SEND_EMAILS = defaultTrue(Core.getKeyValue(CLOSE_JOB__SEND_EMAILS__PENDING_ENGAGEMENTS__FLAG_KEY));

  async function fetch({ showMessage } = {}) {
    Core.showLoader();
    const validStages = [STAGE_SUBMISSION, STAGE_CONFIRMATION];
    const engagements = await EngagementLib.getWhere({ jobId, stage: { inq: validStages }, state: 'Open' }, undefined, undefined, true);
    setEngagements(engagements);
    let _selected = engagements.map(({ id }) => id);
    setSelected(_selected);
    let _recipients = {};
    engagements.forEach(engagement => {
      let recruiter = engagement.recruiter;
      let candidate = engagement.candidate;
      let recruiterRecipients = mapEmailsListToRecipients({
        emailsList: recruiter.emailsList,
        primaryContact: { name: recruiter._name, email: recruiter.email, accountType: 'Recruiter Main Contact' },
        accountType: 'Recruiter Additional Contact',
        messageTypes: [REC_MSG_TYPE__REJECTION_ID]
      });
      const candidateRecruiterRecipients = mapEmailsListToRecipients({
        emailsList: candidate ? candidate.emailsList : [],
        accountType: 'Candidate Recruiter Contact',
        messageTypes: [REC_MSG_TYPE__REJECTION_ID]
      });
      recruiterRecipients.emails = combineEmailsLists(candidateRecruiterRecipients.emails, recruiterRecipients.emails);
      recruiterRecipients.to = combineEmailsLists(candidateRecruiterRecipients.to, recruiterRecipients.to);
      recruiterRecipients.cc = combineEmailsLists(candidateRecruiterRecipients.cc, recruiterRecipients.cc);
      recruiterRecipients.bcc = combineEmailsLists(candidateRecruiterRecipients.bcc, recruiterRecipients.bcc);
      _recipients[engagement.id] = recruiterRecipients;
      _recipients[engagement.id]._to = mapEmailsListToStringArray(_recipients[engagement.id].to);
    });
    setRecipients(_recipients);
    onChange({ selected: _selected, recipients: _recipients });
    Core.hideMessage();
  }
  function getSelectedEngagements() {
    return engagements.filter(({ id }) => selected.includes(id));
  }

  Core.setKeyValue('PendingNotificationPreviewRef', {
    refetch({ showMessage }) { setTimeout(() => fetch({ showMessage })); },
    getSelectedEngagements,
    getUnselectedEngagements() {
      return engagements.filter(({ id }) => !selected.includes(id));
    },
    getEngagements: () => engagements || [],
    getClosedEngagements: () => closedEngagements || []
  });

  const handleToggleAll = (value) => () => {
    if (selected.length === engagements.length) {
      let _selected = selected.filter(engagementId => engagements.find(({ id }) => (id === engagementId)).__toNotifyREcruiter);
      setSelected(_selected);
      onChange({
        selected: _selected,
        recipients
      });
    }
    else {
      let _selected = engagements.map(({ id }) => id);
      setSelected(_selected);
      onChange({ selected: _selected, recipients });
    }
  };

  const handleToggle = (value) => () => {
    const currentIndex = selected.indexOf(value);
    const newChecked = [...selected];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setSelected(newChecked);
    onChange({ selected: newChecked, recipients });
  };

  if (!engagements) { fetch({ showMessage: false }); }

  return !engagements?.length ? (<Message>There are not active confirmation and submission stage candidates</Message>) : (
    <>
      <div className="d-flex flex-column">
        {engagements && selected && (
          <div>
            <Row>
              <Col className="d-flex flex-align-left-center" fullWidth>
                {SEND_EMAILS ? (
                  <label className="m-0 mr-1 t-normal">
                    Notify recruiters active confirmation and submission stage candidates
                  </label>
                ) : (
                  <label className="m-0 mr-1 t-normal">
                    Engagements on confirmation and submission stage
                  </label>
                )}
                <IconButton title="Refetch Engagements" onClick={event => fetch({ showMessage: true })}>
                  <i className="material-icons">refresh</i>
                </IconButton>
                <Checkbox
                  checked={SEND_EMAILS}
                  onChange={(event) => {
                    Core.setKeyValue(
                      CLOSE_JOB__SEND_EMAILS__PENDING_ENGAGEMENTS__FLAG_KEY,
                      event.target.checked
                    );
                    reRender(Date.now());
                  }}
                  labelProps={{ className: 'f-sm fw-500 c-black-medium' }}
                  tooltip={EngagementUI.closeEngagements.sendEmails.tooltip}
                >
                  Send emails
                </Checkbox>
              </Col>
            </Row>
            <div className="ml-4">
              <List sx={{ bgcolor: 'background.paper' }}>
                <ListItem>
                  <div className="d-flex flex-align-left-center w-50">
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={selected.length === engagements.length}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': `checkbox-list-label-all` }}
                        onClick={handleToggleAll()}
                      />
                    </ListItemIcon>
                    <ListItemText id={`checkbox-list-label-all`} primary={<strong>All</strong>} />
                  </div>
                </ListItem>
                {engagements.map(engagement => {
                  const { id, stage, status, state, candidate, __toNotifyREcruiter } = engagement;
                  const labelId = `checkbox-list-label-${id}`;
                  return (
                    <ListItem
                      key={id}
                      disablePadding
                      className={__toNotifyREcruiter ? 'bg-main' : ''}
                    >
                      <ListItemButton role={undefined} dense>
                        <div className="d-flex flex-align-left-center w-50">
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              checked={selected.indexOf(id) !== -1}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ 'aria-labelledby': labelId }}
                              onClick={handleToggle(id)}
                              disabled={__toNotifyREcruiter}
                            />
                          </ListItemIcon>
                          <ListItemText className="truncate" id={labelId} primary={`${candidate._name} - ${stage} - ${status} - ${state}`} />
                        </div>
                        {SEND_EMAILS && (
                          <div className="d-flex flex-align-left-center w-50 ml-auto d-none">
                            {recipients && recipients[engagement.id] && (
                              <>
                                <MultipleSelect
                                  placeholder={('type an email')}
                                  multiple="tags"
                                  data={recipients[engagement.id].emails.filter(c => validateEmail(c.email)).map(contact => {
                                    let accountType = contact.accountType ? <strong>{contact.accountType}:</strong> : '';
                                    let name = contact.name && String(contact.name).trim() ? ` ${contact.name}` : '';
                                    let email = ` <${contact.email}>`;
                                    return {
                                      label: (<small>{accountType}{name}{email}</small>),
                                      id: `${name}${email}`.trim()
                                    }
                                  })}
                                  value={recipients[engagement.id]._to}
                                  onChange={to => {
                                    let _recipients = { ...recipients };
                                    _recipients[engagement.id]._to = to;
                                    setRecipients(_recipients);
                                    onChange({ selected, recipients: _recipients });
                                  }}
                                  optionLabelProp
                                  maxTagCount={false}
                                />
                              </>
                            )}
                            {Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_CANDIDATE }) && (
                              <IconButton
                                aria-label="Popup Edit Candidate - REFETCH ENGAGEMENTS AFTER SAVE RECRUITER CONTACTS"
                                className="hint--left"
                                onClick={ev => Core.openPopUpFullSize(`/#/candidate/edit/${engagement.candidate.id}`)}
                              >
                                <i className="material-icons">edit</i>
                              </IconButton>
                            )}
                            {Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_ACCOUNT }) && (
                              <IconButton
                                aria-label="Popup Edit Account - REFETCH ENGAGEMENTS AFTER SAVE RECRUITER CONTACTS"
                                className="hint--left"
                                onClick={ev => Core.openPopUpFullSize(`/#/account/edit/${engagement.recruiter.id}`)}
                              >
                                <i className="material-icons">edit</i>
                              </IconButton>
                            )}
                          </div>
                        )}
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
              {SEND_EMAILS && (
                <div className="px-2">
                  <EmailPreview ref={self => Core.setKeyValue('PendingNotificationEmailPreview', self)} omitUIRecipients snippets={snippets} taller />
                </div>
              )}
              <ActionBar
                selected={selected}
                sendEmailsFlag={SEND_EMAILS}
                onSubmit={({
                  status = '',
                  rejectionReason,
                  rejectionReasonAdditionalInfo,
                  holdDate,
                  holdDetails,
                }) => {
                  try {
                    let pendingNotificationParams;
                    if (SEND_EMAILS) {
                      pendingNotificationParams = Core.getKeyValue('PendingNotificationEmailPreview').getParams();
                      if (!pendingNotificationParams.subject) {
                        return Core.showError('Email subject is missing');
                      }
                      if (!pendingNotificationParams.html) {
                        return Core.showError('Email content is missing');
                      }
                    }
                    let selectedEngagements = getSelectedEngagements();
                    Core.dialog.open({
                      title: <>Action Summary</>,
                      paperStyle: {
                        minWidth: '50vw',
                        maxWidth: '70vw'
                      },
                      message: (
                        <>
                          <div>
                            {SEND_EMAILS
                              ? `Recruiters to receive notification and engagements to `
                              : `Engagements to `}
                            {!!status?.match(/e -/i) ? 'close' : !!status?.match(/h -/i) ? 'hold' : ''}
                            <ul>
                              {selectedEngagements.map((engagement, index) => {
                                const { candidate, stage, status, state } = engagement;
                                return (
                                  <li key={`engagement-to-close-${engagement.id}`}>
                                    {recipients[engagement.id]._to.join(', ')} - {candidate._name} - {stage} - {status} - {state}
                                  </li>
                                )
                              })}
                            </ul>
                          </div>
                          {!!engagements.filter(engagement => !selected.includes(engagement.id)).length && (
                            <div>
                              Engagements that will <b><u>not</u></b> be {!!status?.match(/e -/i) ? 'closed' : !!status?.match(/h -/i) ? 'hold' : ''}
                              <ul>
                                {engagements.filter(engagement => !selected.includes(engagement.id)).map((engagement, index) => {
                                  const { candidate, stage, status, state } = engagement;
                                  return (
                                    <li key={`engagement-to-close-${engagement.id}`}>{candidate._name} - {stage} - {status} - {state}</li>
                                  )
                                })}
                              </ul>
                            </div>
                          )}
                        </>
                      ),
                      actions: [
                        <Button outlined minW120>
                          Cancel
                        </Button>,
                        <Button primary minW120
                          onClick={async ev => {
                            Core.dialog.close();
                            let ok;
                            if (SEND_EMAILS) {
                              Core.showLoader('Sending Recruiter Emails');
                              ok = await Promise.all(
                                selected.map(async (engagementId, index) => {
                                  let _params = {
                                    ...pendingNotificationParams,
                                    to: recipients[engagementId]._to.join(','),
                                    boxKey: engagements.find(engagement => {
                                      return engagementId === engagement.id
                                    })?.boxKey
                                  };
                                  return sendSafeEmail(_params);
                                })
                              ).catch(exception => Core.failure({ message: exception }));
                              console.debug('RECRUITERS', ok);
                              if (!ok) { return; }
                            }
                            Core.showLoader('Closing Engagements');
                            ok = await Promise.all(
                              selectedEngagements.map(async (engagement, index) => {
                                if (!!status.match(/e -/i)) {
                                  return EngagementLib.update(engagement, {
                                    state: 'Closed',
                                    closed: new Date().getTime(),
                                    status,
                                    rejectionReason,
                                    rejectionReasonAdditionalInfo
                                  });
                                }
                                else if (!!status.match(/h -/i)) {
                                  return EngagementLib.update(engagement, {
                                    status,
                                    holdDate
                                  });
                                }
                                return;
                              })
                            ).then(() => {
                              setClosedEngagements(selectedEngagements);
                              setTimeout(() => {
                                fetch({ showMessage: true });
                                Core.getKeyValue('RecruiterOtherNotificationPreviewRef').refetch({ showMessage: true });
                              });
                            }).catch(exception => Core.failure({ message: exception }));
                            console.debug('ENGAGEMENTS', ok);
                          }}>
                          Confirm
                        </Button>
                      ]
                    });
                  }
                  catch (error) {
                    globalErrorHandler(error);
                  }
                }}
              />
            </div>
          </div>
        )}
      </div>
      <Divider className="mt-4 mb-2" />
    </>
  );
}

function SubmittedEngagementsNotificationPreview({
  jobId,
  snippets,
  onChange = () => null
}) {
  const [collection, setCollection] = useState();
  const [selected, setSelected] = useState();
  const [recipients, setRecipients] = useState();
  const [sourceList, setSourceList] = useState([]);

  async function fetch({ notifiedRecruiters = [], showMessage } = {}) {
    Core.showLoader();
    const validStages = [STAGE_SUBMISSION, STAGE_CONFIRMATION];
    let engagements = await EngagementLib.getWhere({ jobId }, undefined, undefined, true);
    engagements = engagements.filter(engagement => !!engagement.submitted || (validStages.includes(engagement.stage) && !!engagement.matchedByWho));

    const _sourceList = (await getJobSourceByJobId({ jobId }) || []); setSourceList(_sourceList);
    const recruiterIdsByJobSourcing = sourceList.map(({ accountId }) => accountId);
    let recruiters = await AccountLib.getWhere({ _id: { inq: recruiterIdsByJobSourcing } });

    let recruiterHash = {};
    recruiters.forEach(recruiter => {
      recruiterHash[recruiter.id] = { id: recruiter.id, recruiter, __fromSourcingList: true };
    });
    engagements.forEach(engagement => {
      let { recruiter, candidate } = engagement;
      if (!recruiterHash[recruiter.id]) {
        recruiterHash[recruiter.id] = { id: recruiter.id, recruiter };
      }
      recruiterHash[recruiter.id].__candidateNames = recruiterHash[recruiter.id].__candidateNames || [];
      recruiterHash[recruiter.id].__candidateNames.push(candidate._name);
    });

    engagements = Object.keys(recruiterHash).map(key => recruiterHash[key]);

    let recruitersToExclude = {};
    let sectionA = Core.getKeyValue('ActiveNotificationPreviewRef');
    let sectionB = Core.getKeyValue('PendingNotificationPreviewRef');
    sectionA.getEngagements().forEach(({ recruiter }) => recruitersToExclude[recruiter.id] = true);
    sectionA.getClosedEngagements().forEach(({ recruiter }) => recruitersToExclude[recruiter.id] = true);
    sectionB.getEngagements().forEach(({ recruiter }) => recruitersToExclude[recruiter.id] = true);
    sectionB.getClosedEngagements().forEach(({ recruiter }) => recruitersToExclude[recruiter.id] = true);
    notifiedRecruiters.forEach(({ recruiter }) => recruitersToExclude[recruiter.id] = true);

    engagements = engagements.filter(({ recruiter }) => !recruitersToExclude[recruiter.id]);

    setCollection(engagements);

    let _selected = (
      !!selected
        ? engagements.filter(({ id }) => selected.includes(id)).map(({ id }) => id)
        : []
    );
    setSelected(_selected);
    let _recipients = {};
    engagements.forEach(engagement => {
      let {
        recruiter = {},
        candidate = {}
      } = engagement;
      let recruiterRecipients = mapEmailsListToRecipients({
        emailsList: recruiter.emailsList,
        primaryContact: { name: recruiter._name, email: recruiter.email, accountType: 'Recruiter Main Contact' },
        accountType: 'Recruiter Additional Contact',
        messageTypes: [REC_MSG_TYPE__REJECTION_ID]
      });
      const candidateRecruiterRecipients = mapEmailsListToRecipients({
        emailsList: candidate ? candidate.emailsList : [],
        accountType: 'Candidate Recruiter Contact',
        messageTypes: [REC_MSG_TYPE__REJECTION_ID]
      });
      recruiterRecipients.emails = combineEmailsLists(candidateRecruiterRecipients.emails, recruiterRecipients.emails);
      recruiterRecipients.to = combineEmailsLists(candidateRecruiterRecipients.to, recruiterRecipients.to);
      recruiterRecipients.cc = combineEmailsLists(candidateRecruiterRecipients.cc, recruiterRecipients.cc);
      recruiterRecipients.bcc = combineEmailsLists(candidateRecruiterRecipients.bcc, recruiterRecipients.bcc);
      _recipients[engagement.id] = recruiterRecipients;
      _recipients[engagement.id]._to = mapEmailsListToStringArray(_recipients[engagement.id].to);
    });
    setRecipients(_recipients);
    onChange({ selected: _selected, recipients: _recipients });
    Core.hideMessage();
  }

  function getSelectedItems() {
    return collection.filter(({ id }) => selected.includes(id));
  }

  Core.setKeyValue('RecruiterOtherNotificationPreviewRef', {
    refetch({ showMessage }) { setTimeout(() => fetch({ showMessage })); },
    getSelectedItems
  });

  const handleToggleAll = (value) => () => {
    if (selected.length === collection.length) {
      setSelected([]);
      onChange({ selected: [], recipients });
    }
    else {
      let _selected = collection.map(({ id }) => id);
      setSelected(_selected);
      onChange({ selected: _selected, recipients });
    }
  };

  const handleToggle = (value) => () => {
    const currentIndex = selected.indexOf(value);
    const newChecked = [...selected];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setSelected(newChecked);
    onChange({ selected: newChecked, recipients });
  };

  if (!collection) { fetch({ showMessage: false }); }

  return !collection?.length ? (<Message>There are not recruiters with past submissions or actively sourcing for job</Message>) : (
    <div className="d-flex flex-column">
      {collection && selected && (
        <div>
          <Row>
            <Col className="d-flex flex-align-left-center" fullWidth>
              <label className="m-0 mr-1 t-normal">Notify recruiters with past submissions or actively sourcing for job</label>
              <IconButton title="Refetch Engagements" onClick={event => fetch({ showMessage: true })}>
                <i className="material-icons">refresh</i>
              </IconButton>
            </Col>
          </Row>
          <div className="ml-4">
            <List sx={{ bgcolor: 'background.paper' }}>
              <ListItem>
                <div className="d-flex flex-align-left-center w-50">
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={selected.length === collection.length}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': `checkbox-list-label-all` }}
                      onClick={handleToggleAll()}
                    />
                  </ListItemIcon>
                  <ListItemText id={`checkbox-list-label-all`} primary={<strong>All</strong>} />
                </div>
              </ListItem>
              {collection.map(engagement => {
                const { id, recruiter, __fromSourcingList, __candidateNames = [] } = engagement;
                const labelId = `checkbox-list-label-${id}`;
                return (
                  <ListItem
                    key={id}
                    disablePadding
                  >
                    <ListItemButton role={undefined} dense>
                      <div className="d-flex flex-align-left-center w-100">
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={selected.indexOf(id) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-labelledby': labelId }}
                            onClick={handleToggle(id)}
                          />
                        </ListItemIcon>
                        <ListItemText
                          className="truncate"
                          id={labelId}
                          primary={
                            <>
                              <span>{recruiter._name}</span>
                              {__fromSourcingList && (<> - <span className='c-red'>(currently sourcing for this role)</span></>)}
                              {!!__candidateNames.length && <>  - <span className='c-black-medium f-sm'>{__candidateNames.join(', ')}</span></>}
                            </>
                          }
                        />
                      </div>
                      <div className="d-flex flex-align-left-center w-50 ml-auto d-none">
                        {recipients && recipients[engagement.id] && (
                          <>
                            <MultipleSelect
                              placeholder={('type an email')}
                              multiple="tags"
                              data={recipients[engagement.id].emails.filter(c => validateEmail(c.email)).map(contact => {
                                let accountType = contact.accountType ? <strong>{contact.accountType}:</strong> : '';
                                let name = contact.name && String(contact.name).trim() ? ` ${contact.name}` : '';
                                let email = ` <${contact.email}>`;
                                return {
                                  label: (<small>{accountType}{name}{email}</small>),
                                  id: `${name}${email}`.trim()
                                }
                              })}
                              value={recipients[engagement.id]._to}
                              onChange={to => {
                                let _recipients = { ...recipients };
                                _recipients[engagement.id]._to = to;
                                setRecipients(_recipients);
                                onChange({ selected, recipients: _recipients });
                              }}
                              optionLabelProp
                              maxTagCount={false}
                            />
                          </>
                        )}
                        {Core.isAdminOrCoordinator() && (
                          <>
                            <IconButton
                              aria-label="Popup Edit Candidate - REFETCH ENGAGEMENTS AFTER SAVE RECRUITER CONTACTS"
                              className="hint--left"
                              onClick={ev => Core.openPopUpFullSize(`/#/candidate/edit/${engagement.candidate.id}`)}
                            >
                              <i className="material-icons">edit</i>
                            </IconButton>
                            <IconButton
                              aria-label="Popup Edit Account - REFETCH ENGAGEMENTS AFTER SAVE RECRUITER CONTACTS"
                              className="hint--left"
                              onClick={ev => Core.openPopUpFullSize(`/#/account/edit/${engagement.recruiter.id}`)}
                            >
                              <i className="material-icons">edit</i>
                            </IconButton>
                          </>
                        )}
                      </div>
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
            <div className="px-2">
              <EmailPreview ref={self => Core.setKeyValue('RecruiterNotificationEmailPreview', self)} omitUIRecipients snippets={snippets} taller />
            </div>
            <ActionBar
              selected={selected}
              noSubOptions
              onSubmit={() => {
                /** */
                try {
                  let recruiterNotificationParams = Core.getKeyValue('RecruiterNotificationEmailPreview').getParams();
                  if (!recruiterNotificationParams.subject) {
                    return Core.showError('Email subject is missing');
                  }
                  if (!recruiterNotificationParams.html) {
                    return Core.showError('Email content is missing');
                  }
                  let selectedItems = getSelectedItems();
                  Core.dialog.open({
                    title: <>Action Summary</>,
                    paperStyle: {
                      minWidth: '50vw',
                      maxWidth: '70vw'
                    },
                    message: (
                      <>
                        <div>
                          Notify recruiters:
                          <ul>
                            {selectedItems.map((engagement, index) => {
                              const { __fromSourcingList, __candidateNames = [] } = engagement;
                              return (
                                <li key={`engagement-to-close-${engagement.id}`}>
                                  <span>{recipients[engagement.id]._to.join(', ')}</span>
                                  {__fromSourcingList && (<> - <span className='c-red'>To remove job from sourcing list</span></>)}
                                  {!!__candidateNames.length && <>  - <span className='c-black-medium f-sm'>{__candidateNames.join(', ')}</span></>}
                                </li>
                              )
                            })}
                          </ul>
                        </div>
                      </>
                    ),
                    actions: [
                      <Button outlined minW120>
                        Cancel
                      </Button>,
                      <Button primary minW120
                        onClick={async ev => {
                          Core.dialog.close();
                          let hash = {};
                          let recruiterJobSourcesToEdit = [];
                          let uniqueNotifications = selectedItems.filter(({ id, candidate = {}, recruiter }) => {
                            if (!candidate._name) { recruiterJobSourcesToEdit.push(recruiter.id); }
                            if (hash[id]) { return false; }
                            hash[id] = true;
                            return true;
                          });
                          let ok;
                          Core.showLoader('Sending Recruiter Emails');
                          ok = await Promise.allSettled(
                            uniqueNotifications.map(async ({ id, recruiter }, index) => {
                              let _params = {
                                ...recruiterNotificationParams,
                                to: recipients[id]._to.join(','),
                                boxKey: collection.find(item => {
                                  return id === item.id
                                })?.boxKey
                              };
                              await sendSafeEmail(_params);
                              if (recruiterJobSourcesToEdit.includes(recruiter.id)) {
                                Core.showLoader('Editing Job Sourcing');
                                await Promise.allSettled(
                                  sourceList.map(async ({ id, accountId }) => {
                                    if (recruiter.id === accountId) {
                                      await jobSourceList.deleteJob(id);
                                    }
                                    return true;
                                  })
                                );
                              }
                              return true;
                            })
                          ).catch(exception => Core.failure({ message: exception }));
                          if (!ok) { return; }
                          console.debug('NOTIFICATIONS', ok);
                          setTimeout(() => {
                            fetch({ notifiedRecruiters: uniqueNotifications, showMessage: true });
                          });
                        }}>
                        Confirm
                      </Button>
                    ]
                  });
                }
                catch (error) {
                  globalErrorHandler(error);
                }
                /** */
              }}
            />
          </div>
        </div>
      )
      }
    </div >
  );
}

function ActionBar({
  id,
  onSubmit = () => null,
  selected = [],
  noSubOptions,
  sendEmailsFlag = true
}) {

  const [status, setStatus] = useState();
  const [rejectionReason, setRejectionReason] = useState();
  const [rejectionReasonAdditionalInfo, setRejectionReasonAdditionalInfo] = useState();
  const [holdDate, setHoldDate] = useState();
  const [holdDetails, setHoldDetails] = useState();

  return (
    <div className="">
      <div className="d-flex flex-column p-2">
        {!noSubOptions && (
          <div className="d-flex w-100 space-between">
            <StatusesMenu
              filter={/(e|h) - /i}
              className="mx-1 w-20"
              style={{ height: 30 }}
              onChange={status => {
                setStatus(status);
              }}
            />
            {!!status?.match(/h -/i) && (
              <>
                <div className="d-flex flex-align-left-top w-30 mr-1">
                  <strong className="mr-1 c-cyan-darker nowrap">
                    Hold Until
                  </strong>
                  <div>
                    <DatePicker
                      value={holdDate ? moment(holdDate, DATE_FORMAT) : ''}
                      format={DATE_FORMAT}
                      placeholder={`Type a date`}
                      style={{ width: 280 }}
                      className="rounded"
                      onChange={holdDate =>
                        setHoldDate(holdDate ? holdDate.toISOString() : '')
                      }
                      popupStyle={{ zIndex: 9999 }}
                    />
                  </div>
                </div>
                <div className="d-flex flex-align-left-top w-50 d-none">
                  <strong className="mr-1 c-cyan-darker">
                    Details
                  </strong>
                  <TextField
                    variant="standard"
                    color="primary"
                    size="small"
                    value={holdDetails}
                    onChange={event => { let holdDetails = event.target.value; setHoldDetails(holdDetails); }}
                    multiline
                    rowsMax={5}
                    style={{ width: '100%' }}
                  />
                </div>
              </>
            )}
            {!!status?.match(/e -/i) && (
              <>
                <RejectionReasonsMenu
                  className="mr-1 w-30"
                  style={{ height: 30 }}
                  onChange={setRejectionReason}
                >
                  Reason
                </RejectionReasonsMenu>
                <RejectionReasonInfo
                  className="flex-align-left-top w-50"
                  style={{ minHeight: 30 }}
                  onChange={setRejectionReasonAdditionalInfo}
                >
                  <span style={{ height: 30 }}>Details</span>
                </RejectionReasonInfo>
              </>
            )}
          </div>
        )}
        <Button primary
          size="large"
          className="m-auto my-2 px-8 py-3"
          style={{ minWidth: 222 }}
          disabled={!selected.length}
          onClick={event => {
            if (!noSubOptions) {
              if (!status) {
                return Core.showError('Status has not selected!');
              }
              if (!!status.match(/e - /i) && !rejectionReason) {
                return Core.showError('Reason has not selected!');
              }
            }
            onSubmit({
              status,
              rejectionReason,
              rejectionReasonAdditionalInfo,
              holdDate,
              holdDetails,
            });
          }}
        >
          {sendEmailsFlag ? `Notify (${selected.length}) Recruiters` : `Close (${selected.length}) Engagements`}
        </Button>
      </div>
    </div>
  );
}

export default function CloseJob({ jobId, snippets }) {

  const [job, setJob] = useState();
  async function fetchJob() { try { setJob(await Job.get(jobId)) } catch { } }
  if (!job) { fetchJob(); }

  return (
    <>
      <div className="sticky-top bg-main">
        <Row className="py-1">
          <Col className="d-flex flex-align-left-center px-0" fullWidth>
            <IconButton
              className="mx-1"
              onClick={event => {
                event.stopPropagation();
                Core.closeDrawer()
              }}
            >
              <i className="material-icons">close</i>
            </IconButton>
            <label className="m-0 mr-1 t-normal">
              <strong className="mr-1">Close Job: {Object(job)._name}</strong>
            </label>
          </Col>
        </Row>
        <Divider />
      </div>
      <ActiveEngagementsNotificationPreview jobId={jobId} snippets={snippets} />
      <PendingEngagementsNotificationPreview jobId={jobId} snippets={snippets} />
      <SubmittedEngagementsNotificationPreview jobId={jobId} snippets={snippets} />
    </>
  );
}

export async function openCloseJob({ jobId }) {
  Core.showLoader();
  const snippets = await Streak.getSnippets();
  Core.setKeyValue(CLOSE_JOB__SEND_EMAILS__ACTIVE_ENGAGEMENTS__FLAG_KEY, true);
  Core.setKeyValue(CLOSE_JOB__SEND_EMAILS__PENDING_ENGAGEMENTS__FLAG_KEY, true);
  Core.setKeyValue(CLOSE_JOB__SEND_EMAILS__SUBMITTED_ENGAGEMENTS__FLAG_KEY, true);
  Core.hideMessage();
  Core.openDrawer({
    style: {
      width: 1200,
      maxWidth: 'calc(100vw - 2rem)',
    },
    content: (
      <CloseJob jobId={jobId} snippets={snippets} />
    ),
  });
}
