import {
  AutoComplete as AntdAutoComplete,
  Select
} from 'antd';
import {
  isString
} from 'lodash';
import AppUI from '../../../dictionaries/AppUI.dic';
import {
  Arr,
  sanitizeArr
} from '../../../lib/Array.lib';
import Core from '../../../lib/Core';
import {
  Fun
} from '../../../lib/Function.lib';
import useState from '../../../lib/hooks/useState.hook';
import {
  Str,
  trim
} from '../../../lib/String.lib';
import {
  joinClassName,
  mapWrapper,
  THEME__VARIANT__STYLED
} from '../Libraries/Theme.lib';
import Box from './Box';
import Icon from './Icon';

/**
 * Autocomplete component with various customizations and async options support.
 * 
 * @note
 * We use `AntD.Select` by default instead of `AntD.AutoComplete`,
 * because their behaviors differ slightly, and in most cases
 * (especially for `FilterControl`), the former is preferred.
 * 
 * `AntD.AutoComplete`
 * - Is used when `select` is set to `false`.
 * - This permit to use onChange to update the parent value (as soon as the user types).
 * 
 * `AntD.Select`
 * - Is used when `select` is set to `true`.
 * - This permit to use onSelect to update the parent value (click or keydown Enter).
 * 
 * 
 * @param {Object} props - Component properties.
 * @param {boolean} [props.select=true] - Select mode.
 * @param {function} [props.inputRender] - Custom input render.
 * @param {any[]} [props.options=[]] - Options for the autocomplete.
 * @param {function} [props.asyncOptions] - Function returning a promise with options.
 * @param {any} [props.value=null] - Selected value.
 * @param {function} [props.onChange] - Callback on option change.
 * @param {function} [props.onFocus] - Callback on input focus.
 * @param {function} [props.onBlur] - Callback on input blur.
 * @param {function} [props.onPressEnter] - Callback on key press.
 * @param {function} [props.renderSelected] - Function to render the selected option.
 * @param {string} [props.size='medium'] - Size of the input.
 * @param {string} [props.placeholder=AppUI.placeholder.autocomplete] - Placeholder text.
 * @param {Object} [props.InputProps={}] - Additional input props.
 * @param {boolean} [props.acl=true] - Access control flag.
 * @param {boolean} [props.debug=false] - Debug flag.
 * @param {boolean} [props.open=false] - Open state of the autocomplete.
 * @param {boolean} [props.autoFocus=false] - Auto focus flag.
 * @param {boolean} [props.disableUnderline=false] - Disable underline.
 * @param {boolean} [props.fullWidth=false] - Full width flag.
 * @param {boolean} [props.clearOnSelect=false] - Clear input on select.
 * @param {Object} [props.wrapperProps={}] - Additional wrapper props.
 * @param {Object} [props.inputProps={}] - Additional input props.
 * 
 * @returns {JSX.Element|null} The Autocomplete component.
 */
export default function Autocomplete({
  select = true,
  inputRender: AutoCompleteInput = select ? Select : AntdAutoComplete,
  asyncOptions = async (value) => ([/** { id, ... } */]),
  options: propsOptions = [],  // [ string || { id, label, ... } ]
  value: propsValue = null,
  onChange = (value, option) => null,
  onSelect = (value, option) => null,
  onSearch = (value) => null,
  onFocus = Fun,
  onBlur = Fun,
  onPressEnter = Fun,
  onClear = Fun,
  onDropdownVisibleChange = Fun,
  renderLabel = ({ label = '' }) => (label),
  size = 'medium',
  placeholder = AppUI.placeholder.autocomplete,
  InputProps = {},
  acl = true,
  debug = false,
  autoFocus = false,
  disableUnderline = false,
  fullWidth = false,
  clearOnSelect = false,
  wrapperProps = {},
  inputProps = {},
  variant = THEME__VARIANT__STYLED,
  className = '',
  style = {},
  ...props
}) {

  // Define the initial state based on props
  const observedState = {
    value: propsValue
  };

  const [{
    options = [],
    value = null,
    isFocused = false,
    isSelected = false,
  }, updateState] = useState(observedState, observedState);

  if (!acl) return null;

  const flags = { debug };

  const open = (
    !!isFocused &&
    !isSelected &&
    !!options.length && (
      autoFocus || !!trim(value)
    )
  );

  // FETCH
  const _fetchOptions = async (value) => {
    try {
      value = trim(value);
      console.debug('VALUE', value);
      let options = sanitizeArr(
        [
          value,
          ...Arr(await Fun(asyncOptions)(value)),
          ...propsOptions
        ]
      ).map((option) => {
        if (isString(option)) {
          option = { id: option, label: option };
        }
        return option;
      });
      if (options.length >= 2) {
        await updateState({ options });
      }
    }
    catch (error) {
      Core.showError(error);
    }
  }

  // SEARCH
  const _onSearch = async (value) => {
    console.debug('SEARCH', value);
    await updateState({
      value: value || null,
      isSelected: false
    });
    onSearch(value);
    _fetchOptions(value);
  }

  // CHANGE
  const _onChange = async (value, option) => {
    console.debug('CHANGE', value, option);
    if (clearOnSelect) {
      await updateState({ value: null });
    }
    else {
      await updateState({ value });
    }
    onChange(value, option);
  };

  // SELECT
  const _onSelect = async (value, option) => {
    console.debug('SELECT', value, option);
    if (clearOnSelect) {
      await updateState({ value: null });
    }
    else {
      await updateState({
        value,
        isSelected: true
      });
    }
    onSelect(value, option);
  };

  // FOCUS
  const _onFocus = async (event) => {
    console.debug('FOCUS', event);
    const update = { isFocused: true };
    await updateState(update);
    onFocus(event);
    autoFocus && _fetchOptions();
  }

  // BLUR
  const _onBlur = async (event) => {
    console.debug('BLUR', event);
    const update = { isFocused: false };
    await updateState(update);
    onBlur(event);
  }

  // PRESS ENTER
  const _onKeyDown = async (event) => {
    if (Str(event.key).match(/enter/i)) {
      event.preventDefault();
      console.debug('ENTER', value);
      onPressEnter(value);
    }
  }

  const _onClear = () => {
    console.debug('CLEAR');
    onClear();
  }

  const _onDropdownVisibleChange = (open) => {
    onDropdownVisibleChange(open);
  }

  mapWrapper(
    {
      role: 'Autocomplete',
      props: wrapperProps,
      assign: {
        row: true,
        w100: true,
        className: joinClassName([
          variant,
          className
        ]),
        style: {
          width: '100%',
          textAlign: 'left',
          ...style
        },
        children: (
          <>
            <Icon icon='search' xl className='mx-05' />
            <AutoCompleteInput
              {...mapWrapper(
                {
                  role: 'AutocompleteInput',
                  props: inputProps,
                  assign: {
                    ...props,
                    open: open,
                    autoFocus,
                    showSearch: true,
                    allowClear: true,
                    placeholder,
                    value,
                    onSearch: _onSearch,
                    onChange: _onChange,
                    onSelect: _onSelect,
                    onKeyDown: _onKeyDown,
                    onFocus: _onFocus,
                    onBlur: _onBlur,
                    onClear: _onClear,
                    onDropdownVisibleChange: _onDropdownVisibleChange,
                    className: joinClassName([
                      variant,
                      className
                    ]),
                    style: {
                      width: '100%',
                      textAlign: 'left',
                      ...style
                    },
                    dropdownStyle: {
                      borderRadius: 4,
                      zIndex: 9999
                    },
                    optionFilterProp: 'children',
                    filterOption: (input, option) => (
                      option.children?.toLowerCase().indexOf(
                        input.toLowerCase()
                      ) >= 0
                    ),
                    suffixIcon: false,
                    children: options.map(
                      (option, index) => (
                        <Select.Option
                          key={`option_${option.id}(required-by-react)`}
                          value={option.id}
                        >
                          {renderLabel(option)}
                        </Select.Option>
                      )
                    )
                  },
                  flags
                }
              )}
            />
          </>
        )
      },
      flags
    }
  );

  return (
    <Box {...wrapperProps} />
  );
}
