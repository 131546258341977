import {
  isEqual
} from 'lodash';
import {
  Component
} from "react";
import {
  NOT
} from '../../../../../../lib/Boolean.lib';
import Box from '../../../../../Layout/Wrappers/Box';
import TextField from '../../../../../Layout/Wrappers/TextField';
import Typography from '../../../../../Layout/Wrappers/Typography';
import ComparisonHaves from "../../../../../Match/Candidate/ComparisonHaves";
import BasicChip from "../../../../../Match/Haves/v1/Chip";
import ChipGroup from "../../../../../Match/Haves/v1/ChipGroup";
import ChipTag from "../../../../../Match/Haves/v1/ChipTag";
import NumberDropdown from "../Dropdowns/NumberDropdown";
import OperatorDropdown from "../Dropdowns/OperatorDropdown";
import AndElement from '../Operators/And';
import NotElement from '../Operators/Not';
import OrElement from '../Operators/Or';
import ChipElement from './ChipElement';
import OperatorElement from './OperatorElement';
import TextElement from './TextElement';

export default class ComplexElement extends Component {

  constructor() {
    super(...arguments);
    this.state = {
      element: this.props.element
    };
  }

  handlerOperatorOption = (operator) => {
    let { element } = this.state;
    element.type = operator;
    this.setState({ element, openOperatorDD: false }, () => {
      const { onApply } = this.props;
      !!onApply && onApply(element);
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (NOT(isEqual(prevProps.element, this.props.element))) {
      this.setState({
        element: this.props.element
      });
    }
  }

  handlerNumberOption = (number) => {
    let { element } = this.state;
    element.atLeast = number;
    this.setState({ element }, () => {
      const { onApply } = this.props;
      !!onApply && onApply(element);
    });
  }

  handlerSpecialInstruction = (event) => {
    let { element } = this.state;
    element.specialInstruction = event.target.value;
    this.setState({ element }, () => {
      const { onApply } = this.props;
      !!onApply && onApply(element);
    });
  }

  handlerCategory = (category) => {
    let { element } = this.state;
    element.category = category;
    this.setState({ element }, () => {
      const { onApply } = this.props;
      !!onApply && onApply(element);
    });
  }

  render() {

    let { onlyView, toMatchWith, candidateResume, pageSource } = this.props;
    const { element } = this.state;

    return (
      <Box column w100
        role='ComplexElement'
      >

        <Box row w100 className='flex-align-left-top'>

          {
            !!onlyView && !!toMatchWith &&
            <ComparisonHaves
              element={element}
              toMatchWith={toMatchWith}
              candidateResume={candidateResume}
              className='mr-1'
              style={{ minHeight: 36 }}
            />
          }

          {!!element.type &&
            <OperatorDropdown
              type={element.type}
              handlerOperator={this.handlerOperatorOption}
              onlyView={onlyView}
              operators={Operators}
              className='mr-1'
              style={{ minHeight: 36 }}
            />
          }

          {!!element.atLeast && element.type === 'or' &&
            <>
              <NumberDropdown
                atLeast={element.atLeast}
                handlerNumber={this.handlerNumberOption}
                onlyView={onlyView}
                className='mr-1'
                style={{ minHeight: 36 }}
              />
              <Typography alignLeft className='mr-1' style={{ minHeight: 36 }}>
                of
              </Typography>
            </>
          }

          <Box row wrap
            role='ComplexElementWrapperComponent'
            style={{ minHeight: 36 }}
          >
            {!!element.args && element.args.map((el, index) => {
              let type = el.type;

              if (['or', 'and', 'not'].includes(type)) {
                type = 'complex';
              }

              if (type === 'chipGroup') {
                type = 'chip';
              }
              if (type === 'chipTag') {
                type = 'chip';
              }

              let isMatched = false;
              if (!!toMatchWith) {
                if (!!el.type && el.type === "chipGroup") {
                  isMatched = ChipGroup.result(el, toMatchWith);
                } else if (!!el.type && el.type === "chipTag") {
                  isMatched = ChipTag.result(el, candidateResume);
                } else {
                  isMatched = BasicChip.isChipMatched(el, toMatchWith);
                }
              }
              let ElementComponent = ElementComponents[type];

              return (
                <ElementComponent
                  key={index}
                  element={el}
                  onlyView={onlyView}
                  isMatchedComplex={isMatched}
                  toMatchWith={toMatchWith}
                  candidateResume={candidateResume}
                  source='complex'
                  pageSource={pageSource}
                />
              );

            })}
          </Box>

        </Box>

        {!onlyView && !!element.specialInstruction && (
          <TextField multiLine
            value={element.specialInstruction}
            onChange={(event) => this.handlerSpecialInstruction(event)}
            autoSize={{ minRows: 1, maxRows: 5 }}
          />
        )}

        <Typography
          acl={!!onlyView}
          role='ComplexElementSpecialInstruction'
        >
          {element.specialInstruction}
        </Typography>

      </Box>
    )
  }
}

const ElementComponents = {
  'chip': ChipElement,
  'text': TextElement,
  'complex': ComplexElement,
  'operator': OperatorElement,
  'and': AndElement,
  'or': OrElement,
  'not': NotElement,
};

const Operators = [
  { name: 'and' },
  { name: 'or' },
  { name: 'not' }
];
