
const Ui3DotsMenu = {
  Common: {
    editEntity: 'Edit {{ENTITY}}',
    deleteEntity: 'Delete {{ENTITY}}',
  },
  Candidate: {
    PREFIX: 'Candidate: ',
    ENTITY: 'candidate',
    downloadResume: '{{PREFIX}}download resume (download CV{{COUNTER}})',
    viewResumeHighlights: '{{PREFIX}}view resume highlights',
    viewResumeText: '{{PREFIX}}view text resume',
  },
  Engagement: {
    PREFIX: 'Engagement: ',
    ENTITY: 'engagement',
  },
  Job: {
    PREFIX: 'Job: ',
    ENTITY: 'job',
  },
  Account: {
    PREFIX: 'Account: ',
    ENTITY: 'account',
  },
  Employer: {
    PREFIX: 'Employer: ',
    ENTITY: 'employer',
  },
  Recruiter: {
    PREFIX: 'Recruiter: ',
    ENTITY: 'recruiter',
  },
}

export default Ui3DotsMenu;
