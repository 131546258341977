import moment from 'moment-timezone';
import {
  Component
} from 'react';
import {
  FULLDAY_MILL_SECOND_REFERENCE,
  FULLDAY_SECOND_REFERENCE,
  TIMEZONE__LA
} from '../../lib/Constants';
import Core from '../../lib/Core';
import {
  EMP_MSG_TYPE__ALL_ID,
  EMP_MSG_TYPE__SUBMISSION_ID
} from '../../lib/Definition';
import {
  replaceRegExp
} from '../../lib/GenericTools.lib';
import Google from '../../lib/Google';
import {
  joinHTML
} from '../../lib/HTML.lib';
import fixQuillHtml from '../../lib/Html/fixQuillHtml.fun';
import Job from '../../lib/Job';
import {
  Obj
} from '../../lib/Object.lib';
import {
  compileText,
  isValidEmail,
  trim
} from '../../lib/String.lib';
import cleanHtml from '../../lib/tools/cleanHtml';
import {
  setLocation
} from '../../lib/URL.lib';
import ConfirmDialog from '../Dialogs/ConfirmDialog';
import Autocomplete from '../Layout/Wrappers/Autocomplete';
import Box from '../Layout/Wrappers/Box';
import Button from '../Layout/Wrappers/Button';
import Checkbox from '../Layout/Wrappers/Checkbox';
import Drawer from '../Layout/Wrappers/Drawer';
import Fieldset from '../Layout/Wrappers/Fieldset';
import { Html } from '../Layout/Wrappers/Html';
import IconButton from '../Layout/Wrappers/IconButton';
import Menu from '../Layout/Wrappers/Menu';
import Paper from '../Layout/Wrappers/Paper';
import RichTextBox from '../Layout/Wrappers/RichTextBox';
import TextField from '../Layout/Wrappers/TextField';
import Typography from '../Layout/Wrappers/Typography';

const DEBUG_CALENDAR_EVENT = Core.debug('DEBUG_CALENDAR_EVENT');

const emailRE =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const USER_TIMEZONE = moment.tz.guess() || TIMEZONE__LA;

const templates = {
  phone: {
    label: 'Phone Interview',
    duration: '30,m',
    interviewers: [],
    description:
      'Please be in a quiet space with reliable signal during your call.',
  },
  video: {
    label: 'Video Interview',
    duration: '30,m',
    interviewers: [],
    description:
      'Please be in a quiet space with reliable internet during your interview.',
  },
  technical: {
    label: 'Technical Phone Interview',
    duration: '1,h',
    interviewers: [],
    description:
      'Please be in a quiet space with reliable signal during your call.',
  },
  coding: {
    label: 'Technical Video Interview',
    duration: '1,h',
    interviewers: [],
    description:
      'Please be in a quiet space with reliable internet and ensure you’ve tested that your computer is ready to connect for a video/coding interview.',
  },
  onsite: {
    label: 'Onsite Interview',
    duration: '4,h',
    interviewers: [],
    description:
      'Please arrive/park 15 minutes before your scheduled start time so that you are not rushed when finding the office and checking in.',
  },
  informal: {
    label: 'Informal In-Person Meeting',
    duration: '1,h',
    interviewers: [],
    description:
      'Please arrive/park 15 minutes before your scheduled start time so that you are not rushed when finding the office and checking in.',
  },
};
const eventModel = {
  summary: '',
  location: '',
  description: '',
  start: {
    dateTime: moment().tz(USER_TIMEZONE).startOf('day').format(),
    date: moment().tz(USER_TIMEZONE).startOf('day').format(),
    timeZone: USER_TIMEZONE,
  },
  end: {
    dateTime: moment()
      .tz(USER_TIMEZONE)
      .startOf('day')
      .add(1, 'h')
      .format(),
    date: moment().tz(USER_TIMEZONE).endOf('day').format('YYYY-MM-DD'),
    timeZone: USER_TIMEZONE,
  },
  attendees: [],
  reminders: {
    useDefault: false,
    overrides: [
      { method: 'email', minutes: 10 },
      { method: 'popup', minutes: 10 },
    ],
  },
  colorId: '5', // "5": { "background": "#fbd75b", "foreground": "#1d1d1d" }
  sendNotifications: false,
};
/*
 *  Calendar events are set to their time in Americas/Los Angeles time zone as the majority of our business
 *  activity is in the San Francisco bay area. When we don't know the time of the event we create an all day
 *  event, e.g. one without a specific time.  The date stored in the database to represent the event will
 *  have the time value set to 00:00:01.300 in the Americas/Los Angeles time zone. The UI uses this value
 *  to hide the time for appointment in the Screen/Onsite column to not confuse real appointments with
 *  known times with those with unknown times.
 */

class CalendarEvent extends Component {
  constructor() {
    super(...arguments);
    this.parent = this.props.parent;
    this.templates = Object.assign({}, templates);
    this.state = {
      isFullday: true,
      event: Object.assign({}, eventModel),
      template: Object.assign({}, this.templates['phone']),
      templateKey: 'phone',
      prefix: '',
      sourceInterviewers: [],
      sourceCandidates: [],
      sourceLocations: [],
      candidates: [],
      resumes: [],
      right: false,
    };
    this.state.selectedTimezone = getTimezoneLabel({ timezone: this.state.event.start.timeZone });
    DEBUG_CALENDAR_EVENT && console.debug({ initCalEvState: this.state });
  }
  setDate = ({ date, timezone, duration, isFullday } = {}) => {
    DEBUG_CALENDAR_EVENT && console.debug({ date, timezone, duration, isFullday });
    this.setState(
      state => {
        state.isFullday = (isFullday !== undefined) ? isFullday : state.isFullday;
        if (timezone !== undefined) { state.selectedTimezone = timezone; }
        state.event.start.timeZone = parseTimezone(state.selectedTimezone);
        state.event.end.timeZone = parseTimezone(state.selectedTimezone);
        state.template.duration = duration || state.template.duration;
        date = date || (state.isFullday ? state.event.start.date : state.event.start.dateTime);
        DEBUG_CALENDAR_EVENT && console.debug('DATE', date);
        state.event.start.dateTime = moment(date).format('YYYY-MM-DDTHH:mm:ss');
        state.event.start.date = moment(date).format('YYYY-MM-DD');
        return this.mapEndDateTime(state);
      },
      then => DEBUG_CALENDAR_EVENT && console.debug({ Event: this.state.event })
    );
  }
  mapEndDateTime = (state) => {
    const duration = state.template.duration.split(',');
    state.event.end.dateTime = moment(state.event.start.dateTime).add(duration[0], duration[1]).format('YYYY-MM-DDTHH:mm:ss');
    state.event.end.date = moment(state.event.start.dateTime).endOf('day').format('YYYY-MM-DDTHH:mm:ss');
    DEBUG_CALENDAR_EVENT && console.debug('setEndDateTime', state);
    return state;
  };
  setTemplate = (key, state) => {
    const engagement = state.engagement;
    state.templateKey = key;
    if (!!engagement.job.calEvTemplates) {
      this.templates = Object.assign({}, engagement.job.calEvTemplates);
      state.template = Object.assign({}, this.templates[key]);
      state.sourceInterviewers = state.template.interviewers.map(
        (interviewer) => interviewer.email
      );
    } else {
      this.templates = Object.assign({}, templates);
      state.template = Object.assign({}, this.templates[key]);

      state.sourceInterviewers = [
        engagement.employer.primaryContactEmail,
        ...engagement.job.emailsList
          .filter(({ to }) => (
            to.includes(EMP_MSG_TYPE__ALL_ID) ||
            to.includes(EMP_MSG_TYPE__SUBMISSION_ID)
          )).map(({ email }) => email),
      ].filter(email => email && emailRE.test(email));

      state.template.interviewers = [
        ...state.template.interviewers,
        ...state.sourceInterviewers.map((email) => ({
          email,
        })),
      ];
    }
    state.event.summary = [
      engagement.candidate._name,
      '-',
      this.templates[key].label,
      '@',
      engagement.employer.name,
      '|',
      engagement.job.jobTitle || '',
    ].join(' ');
    if (/onsite|informal/i.test(key)) {
      state.event.location = engagement.employer._address;
      state.prefix = `
        <p>
          ${[
          'Please call/text 10x10 at',
          Core.getCalendarCoordinatorPhone(),
          'for any last minute change or assistance',
        ].join(' ')}
        </p>
      `;
    } else {
      const phone = engagement.candidate.phone
        .replace(/\D/g, '')
        .replace(/(\.*)(\d{3})(\d{3})(\d{4})$/, '$1 $2-$3-$4');
      if (/phone|technical/i.test(key)) {
        state.event.location = [
          engagement.employer.name,
          'will call <b> ',
          `${engagement.candidate._name}</b> `,
          '@',
          `<strong>${phone}</strong>`,
        ]
          .filter((i) => !!i)
          .join(' ');
      } else {
        state.event.location =
          'Please use: <strong>[REPLACE WITH CONFERENCE URL]</strong>';
      }
      state.prefix = compileText(joinHTML(`
        <p>{{LOCATION}}</p>
        <p>
          <strong>3-min rule:</strong>
          &nbsp;Late/no show past 3 mins? Call candidate directly, avoid rescheduling.
          &nbsp;Notify 10x10 at <a href="tel:{{PHONE}}">{{PHONE}}</a>
          &nbsp;|&nbsp;<a href = "mailto:{{EMAIL}}">{{EMAIL}}</a> for assistance.
        </p>
      `), {
        LOCATION: state.event.location,
        PHONE: Core.getEnvValue('TEAM_PHONE'),
        EMAIL: Core.getEnvValue('TEAM_EMAIL')
      });
    }
    state.sourceLocations = [state.event.location]; //[state.event.location].filter(e => e && e.trim());
    const regex = /(<([^>]+)>)/gi;
    state.event.location = state.event.location.replace(regex, '');
    this.mapEndDateTime(state);
    const tests = {
      'Missing template': !this.templates[key],
      'Missing candidate name': !engagement.candidate._name,
      'Missing candidate email': !engagement.candidate.email,
      'Missing candidate phone': !engagement.candidate.phone,
      'Missing job title': !engagement.job.jobTitle,
      'Missing employer name': !engagement.employer.name,
      'Missing employer address': !state.event.location,
      'Missing coordinator phone': !Core.getCalendarCoordinatorPhone(),
    };
    const failTests = [];
    Object.keys(tests).forEach((test) => {
      if (!!tests[test]) {
        failTests.push(<p key={Core.getKey()}>{test}</p>);
      }
    });
    if (failTests.length) {
      Core.showFailure(failTests);
    }
    if (!state.template.interviewers.length) {
      Core.showWarning('Missing interviewers');
    }
    return state;
  };
  toggleDrawer = (side, open) => (em) => {
    this.setState({ [side]: open });
  };

  defaultCalendar = () => {
    return this.state.calendars.items.find(
      (cal) => cal.id === 'recruiter@10by10.io'
    );
  };

  open = ({ engagement, callback }) => {
    if (!engagement || !engagement.id) {
      return Core.showWarning('The Engagement reference is missing');
    }
    else {
      if (!engagement.boxKey) { console.debug('The Streak box key is missing'); }
      this.setState(
        (state) => {
          state.right = true;
          state.engagement = engagement;
          state.event.start.timeZone = state.event.end.timeZone = moment.tz.guess(); // TIMEZONE__LA;
          state.sourceResumes = (
            Object(engagement.candidate).resumes || []
          ).map((resume) => resume.url);
          state.resumes = state.sourceResumes.map((resume) => ({
            url: resume,
            filename: resume.split('/').pop(),
          }));
          state.sourceCandidates = [engagement.candidate.email].filter(
            (email) => email && emailRE.test(email)
          );
          state.candidates = state.sourceCandidates.map((email) => ({ email }));
          this.setTemplate(
            /onsite/.test(engagement.stage) ? 'onsite' : 'phone',
            state
          );
          state.callback = callback instanceof Function ? callback : (em) => { };
          return state;
        },
        (then) => {
          Google.listCalendars(
            (calendars) =>
              Google.getColors(
                (colors) => {
                  colors.event = Obj(colors.event);
                  const colorId =
                    Object.keys(colors.event).find(
                      (id) => colors.event[id].background === '#fbd75b'
                    ) || '5';

                  this.setState(
                    (state) => {
                      state.calendars = calendars;
                      state.event.colorId = colorId;
                      return state;
                    },
                    (then) => Core.log({ CalEvState: this.state })
                  );
                },
                (error) => Core.showFailure(error)
              ),
            (error) => {
              Core.dialog.open({
                message: (
                  <>
                    Looks like your google account is not connected!
                  </>
                ),
                actions: [
                  <Button flat
                    label="Stay"
                    primary={true}
                    onClick={Core.dialog.close}
                  />,
                  <Button flat
                    label="Go to Settings"
                    primary={true}
                    onClick={(ev) => {
                      Core.dialog.close();
                      setLocation('/#/settings');
                    }}
                  />,
                ],
              });
            }
          );
        }
      );
    }
  };
  saveTemplate = (ev) => {
    const job = this.state.engagement.job;
    if (!!job.calEvTemplates) {
      job.calEvTemplates[this.state.templateKey] = this.state.template;
    } else {
      job.calEvTemplates = Object.assign({}, this.templates);
      job.calEvTemplates[this.state.templateKey] = this.state.template;
    }
    Job.update(job.id, { calEvTemplates: job.calEvTemplates }, (response) =>
      Core.showSuccess('Template(interviewers, duration & desc.) saved in Job')
    );
  };

  createCalendarEventOnly = (ev) => {
    const { isFullday } = this.state;
    const body = { ...this.state.event };
    if (isFullday) {
      delete body.start.dateTime;
      delete body.end.dateTime;
      body.start.date = moment(body.start.date).format('YYYY-MM-DD');
      body.end.date = moment(body.end.date).format('YYYY-MM-DD');
    } else {
      delete body.start.date;
      delete body.end.date;
    }

    body.attendees = [];
    body.transparency = 'transparent';
    body.colorRgbFormat = true;
    body.backgroundColor = '#fbd75b';
    body.description = cleanHtml(
      this.state.prefix +
      '<br/>' +
      fixQuillHtml(this.state.template.description) +
      '<br/><p>' +
      this.state.resumes
        .map((resume) => `<a href="${resume.url}">${resume.filename}</a>`)
        .join('<br/>') +
      '</p>'
    );
    body.reminders = {
      useDefault: false,
      overrides: [{ method: 'popup', minutes: 10 }],
    };
    Google.insertEvent(
      {
        calendarId: this.defaultCalendar().id,
        sendNotifications: false,
        body,
      },
      (response) => {
        Core.showSuccess('Created Calendar Event');
        this.commonCallback();
        this.setState(
          {
            insertEventResult: response,
            right: false,
          },
          (then) => Core.log({ SettingsState: this.state })
        );
      },
      (error) => Core.showFailure(error)
    );
  };

  createCalendarEventAndNotify = (ev) => {
    Core.getKeyValue('CalendarEventConfirmDialogRef').open({
      message: (
        <>
          <strong>Are you sure you want to invite guests?</strong>
        </>
      ),
      onConfirm: async ev => {
        const { isFullday } = this.state;
        const body = { ...this.state.event };
        if (isFullday) {
          delete body.start.dateTime;
          delete body.end.dateTime;
          body.start.date = moment(body.start.date).format('YYYY-MM-DD');
          body.end.date = moment(body.end.date).format('YYYY-MM-DD');
        } else {
          delete body.start.date;
          delete body.end.date;
        }
        body.colorRgbFormat = true;
        body.backgroundColor = '#fbd75b';
        body.attendees = [
          ...this.state.candidates,
          ...this.state.template.interviewers,
        ];
        body.transparency = 'opaque';
        body.description = cleanHtml(
          this.state.prefix +
          '<br/>' +
          fixQuillHtml(this.state.template.description) +
          '<br/><p>' +
          this.state.resumes
            .map((resume) => `<a href="${resume.url}">${resume.filename}</a>`)
            .join('<br/>') +
          '</p>'
        );
        body.reminders = {
          useDefault: false,
          overrides: [{ method: 'popup', minutes: 10 }],
        };
        Google.insertEvent(
          {
            calendarId: this.defaultCalendar().id,
            sendNotifications: true,
            body,
          },
          (response) => {
            this.commonCallback();
            this.setState(
              {
                insertEventResult: response,
                right: false,
              },
              (then) => Core.log({ SettingsState: this.state })
            );
            /** * /
            RESULTS OF TEST
            when it is created the event as busy
            then the owner calendar event is being updated to free
            also it is updated in guests calendar.
            Google.patchEvent({
              calendarId: this.state.calendars.items[0].id,
              eventId: response.id,
              sendNotifications: false,
              body: { transparency: "transparent" }
            });
            /** */
          },
          (error) => Core.showFailure(error)
        );
      }
    });
  };

  setDateOnly = (ev) => {
    this.commonCallback();
    this.setState({ right: false });
  };

  commonCallback = event => {
    const { isFullday } = this.state;
    let startDate;
    if (isFullday) {
      startDate = moment
        .tz(this.state.event.start.date, TIMEZONE__LA)
        .toDate();
      startDate.setSeconds(
        FULLDAY_SECOND_REFERENCE,
        FULLDAY_MILL_SECOND_REFERENCE
      );
    } else {
      startDate = moment
        .tz(this.state.event.start.dateTime, this.state.event.start.timeZone)
        .toDate();
      startDate.setSeconds(0);
    }
    DEBUG_CALENDAR_EVENT && console.debug('EVENT\n', {
      isFullday,
      date: this.state.event.start.date,
      dateTime: this.state.event.start.dateTime,
      tz: this.state.event.start.timeZone,
      iso: startDate.toISOString()
    });
    this.state.callback(
      startDate,
      isFullday
    );
  }

  render() {

    const {
      isFullday = false,
    } = this.state;

    const _setLocation = (location) => {
      if (trim(location)) {
        this.setState(
          (state) => {
            state.event.location = location;
            return state;
          }
        );
      }
    }

    const _setInterviewer = (newInterviewer) => {
      this.setState(
        (state) => {
          if (isValidEmail(newInterviewer)) {
            state.template.interviewers.push({
              email: newInterviewer
            });
          }
          else {
            Core.showError('Invalid email');
            state.newInterviewer = newInterviewer;
          }
          return state;
        }
      );
    }

    const _setNewCandidate = (newCandidate) => {
      this.setState(
        (state) => {
          if (isValidEmail(newCandidate)) {
            state.candidates.push({
              email: newCandidate
            });
          }
          else {
            Core.showError('Invalid email');
            state.newCandidate = newCandidate;
          }
          return state;
        }
      );
    }

    return (
      <Drawer
        anchor="right"
        open={this.state.right}
        onClose={this.toggleDrawer('right', false)}
      >
        <Box column scrollY style={{ width: sizes.m7 }}>

          <Paper square
            role='CalendarEventHeader'
            className='sticky-top bg-white'
          >

            <Fieldset
              title='Interview Type'
              role='CalendarEventInterviewType'
            >
              <Menu dropdown
                value={this.state.templateKey}
                onChange={(templateKey) =>
                  this.setState(
                    (state) => this.setTemplate(templateKey, state)
                  )
                }
                options={Object.keys(this.templates).map((key) => ({
                  id: key,
                  label: this.templates[key].label
                }))}
              />
            </Fieldset>

          </Paper>

          <Box column w100
            role='CalendarEventContent'
            className='bg-main py-1'
          >

            <Fieldset
              title='Summary'
              role='CalendarEventSummary'
            >
              <TextField
                name="summary"
                placeholder="Add title"
                value={this.state.event.summary}
                onChange={
                  (
                    event,
                    summary
                  ) => {
                    this.setState(
                      (state) => {
                        state.event.summary = summary;
                        return state;
                      }
                    );
                  }
                }
                rowsMax={3}
                fullWidth
              />
            </Fieldset>

            <Box row w100>

              <Fieldset
                title='Date'
                actionBar={
                  <Checkbox
                    checked={!isFullday}
                    onChange={(event) => {
                      this.setDate({ isFullday: !event.target.checked })
                    }}
                    checkboxProps={{ size: 'small' }}
                    labelProps={{ className: 'f-sm' }}
                  >
                    Add interview time?
                  </Checkbox>
                }
              >
                {isFullday ? (
                  <TextField
                    name="date"
                    key={'date-local'}
                    id="date-local"
                    type={'date'}
                    value={moment(this.state.event.start.date).format('YYYY-MM-DD')}
                    onChange={event => this.setDate({ date: event.target.value })}
                    fullWidth
                  />
                ) : (
                  <TextField
                    name="date"
                    key={'datetime-local'}
                    id="datetime-local"
                    type={'datetime-local'}
                    value={moment(this.state.event.start.dateTime)?.tz(this.state.event.end.timeZone)?.format('YYYY-MM-DDTHH:mm')}
                    onChange={event => this.setDate({ date: event.target.value })}
                    fullWidth
                  />
                )}
              </Fieldset>

              <Fieldset
                title='Duration'
                wrapperProps={{ style: { maxWidth: 200 } }}
              >
                <Menu dropdown
                  value={this.state.template.duration}
                  onChange={(duration) => this.setDate({ duration })}
                  options={[
                    { id: '15,m', label: '15 minutes' },
                    { id: '30,m', label: '30 minutes' },
                    { id: '45,m', label: '45 minutes' },
                    { id: '1,h', label: '1 hour' },
                    { id: '90,m', label: '1½ hour' },
                    { id: '2,h', label: '2 hours' },
                    { id: '3,h', label: '3 hours' },
                    { id: '4,h', label: '4 hours' },
                    { id: '5,h', label: '5 hours' },
                    { id: '6,h', label: '6 hours' },
                    { id: '7,h', label: '7 hours' },
                    { id: '8,h', label: '8 hours' }
                  ]}
                />
              </Fieldset>

            </Box>

            <Fieldset column
              title='Timezone'
              role='CalendarEventTimezone'
            >
              <Autocomplete
                options={getTimezoneLabels()}
                value={this.state.selectedTimezone}
                onSelect={(timezone) => this.setDate({ timezone })}
              />
            </Fieldset>

            <Fieldset column
              title='Location'
            >
              <Autocomplete
                name="location"
                placeholder="Add location"
                options={this.state.sourceLocations}
                value={this.state.event.location}
                onSelect={_setLocation}
              />
            </Fieldset>

            <Fieldset column
              title='Interviewers'
            >
              <Autocomplete
                name="interviewer"
                placeholder="Add interviewer email"
                options={this.state.sourceInterviewers}
                value={this.state.newInterviewer}
                onSelect={_setInterviewer}
                clearOnSelect
              />
              {
                this.state.template.interviewers.map((attendee, index) => (
                  <Box row w100 key={Core.getKey()} className="p-1">
                    <Typography mr>
                      {attendee.email}
                    </Typography>
                    <IconButton small
                      onClick={(ev) => {
                        this.setState((state) => {
                          state.template.interviewers.splice(index, 1);
                          return state;
                        });
                      }}
                      icon='cancel'
                    />
                  </Box>
                ))
              }
            </Fieldset>

            <Fieldset column
              title='Candidate'
            >
              <Autocomplete
                name="candidate"
                placeholder="Add candidate email"
                options={this.state.sourceCandidates}
                value={this.state.newCandidate}
                onSelect={_setNewCandidate}
                clearOnSelect
              />
              {this.state.candidates.map((attendee, index) => (
                <Box row w100 key={Core.getKey()} className="p-1">
                  <Typography mr>
                    {attendee.email}
                  </Typography>
                  <IconButton small
                    onClick={(ev) => {
                      this.setState((state) => {
                        state.candidates.splice(index, 1);
                        return state;
                      });
                    }}
                    icon='cancel'
                  />
                </Box>
              ))}
            </Fieldset>

            <Fieldset
              acl={!!this.state.resumes.length}
              title='Resumes'
            >
              {this.state.resumes.map((resume, index) => (
                <div key={Core.getKey()} className="inline-blocks">
                  <span
                    className="anchor"
                    onClick={(ev) => Core.openPopUp(resume.url)}
                  >
                    {resume.filename}
                  </span>
                  <IconButton
                    className="icon24"
                    onClick={(ev) => {
                      this.setState((state) => {
                        state.resumes.splice(index, 1);
                        return state;
                      });
                    }}
                  >
                    <i className="material-icons">close</i>
                  </IconButton>
                </div>
              ))}
            </Fieldset>

            <Fieldset column
              title='Description'
            >
              <Html
                value={this.state.prefix}
                className="c-black-medium"
              />
              <RichTextBox
                name="description"
                value={this.state.template.description}
                onChange={description =>
                  this.setState(
                    (state) => {
                      state.template.description = description;
                      return state;
                    },
                    (then) => Core.log({ CalEvState: this.state })
                  )
                }
              />
            </Fieldset>

            <Fieldset>
              <Button outlined w100
                label="Save template"
                onClick={this.saveTemplate}
              />
            </Fieldset>

          </Box>

          <Paper square
            role='CalendarEventFooter'
            className='sticky-bottom bg-white p-1'
          >

            <Button
              acl={
                !!this.state.calendars &&
                !!this.state.calendars.items &&
                !!this.state.calendars.items.length
              }
              variant='contained'
              color='secondary'
              className='mb-1'
              onClick={this.createCalendarEventOnly}
              fullWidth
            >
              SET TIME + CALENDAR (NO INVITE)
            </Button>

            <Button
              variant='outlined'
              color='secondary'
              className='mb-1'
              onClick={this.setDateOnly}
              fullWidth
            >
              SET TIME ONLY
            </Button>

            <Button
              acl={
                !!this.state.calendars &&
                !!this.state.calendars.items &&
                !!this.state.calendars.items.length
              }
              variant='outlined'
              color='error'
              className='mb-1'
              onClick={this.createCalendarEventAndNotify}
              fullWidth
            >
              SET TIME + CALENDAR (SEND INVITE)
            </Button>

          </Paper>

        </Box>

        <ConfirmDialog
          ref={self => Core.setKeyValue('CalendarEventConfirmDialogRef', self)}
          title="Confirmation"
          actionLabel="Confirm"
        />

      </Drawer>
    );
  }
}

export default CalendarEvent;

/**
 * 
 * @returns {string[]}
 */
export function getTimezoneLabels() {
  let timeZones = moment.tz.names();
  let offsetTmz = [];
  for (var i in timeZones) {
    offsetTmz.push(getTimezoneLabel({ timezone: timeZones[i], parse: false }));
  }
  return offsetTmz.filter(v => !!v && typeof v === 'string');
}

/**
 * 
 * @param {object} options if parse===true, timezone-value will be parsed to a valid timezone, if can't parse then will return user timezone
 * @param {string} options.timezone
 * @param {boolean} options.parse 
 * @returns {string}
 */
export function getTimezoneLabel({ timezone, parse = true }) {
  if (parse) { timezone = parseTimezone(timezone); }
  return (
    `(${moment.tz(timezone).format('z')}/${moment.tz(timezone).format('Z')}) ${timezone}`
  );
}

/**
 * 
 * @param {string} value value that will be parsed to a valid timezone, if can't parse then will return user timezone
 * @returns {string}
 */
export function parseTimezone(value) {
  value = String(value || '').split(' ').pop() || '';
  let timezone = (
    !!value && moment.tz.names().find(
      timezone => timezone.match(new RegExp(replaceRegExp(value).replace(/_/g, '.+'), 'i'))
    )
  );
  return timezone || USER_TIMEZONE;
}
