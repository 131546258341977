import {
  Avatar
} from '@mui/material';
import {
  Input
} from 'antd';
import {
  useState
} from 'react';
import {
  Arr
} from '../../../lib/Array.lib';
import Core from '../../../lib/Core';
import CrunchbaseLib, {
  CRUNCHBASE__FAVICON,
  SUGGESTION_TYPE__CBO
} from '../../../lib/Crunchbase.lib';
import {
  capitalize,
  escapeRegExp,
  evalSameName
} from '../../../lib/GenericTools.lib';
import {
  Obj
} from '../../../lib/Object.lib';
import {
  Abbreviation,
  compileText
} from '../../../lib/String.lib';
import { joinClassName } from '../../Layout/Libraries/Theme.lib';
import Box from '../../Layout/Wrappers/Box';
import Button from '../../Layout/Wrappers/Button';
import Col from '../../Layout/Wrappers/Col';
import Dialog from '../../Layout/Wrappers/Dialog';
import Divider from '../../Layout/Wrappers/Divider';
import IconButton from '../../Layout/Wrappers/IconButton';
import {
  WarningMessage
} from '../../Layout/Wrappers/Message';
import Row from '../../Layout/Wrappers/Row';
import SuggestionList, {
  SUGGESTION_TYPE_HEADER,
  SUGGESTION_TYPE_NEW
} from '../../Layout/Wrappers/SuggestionList';
import {
  Label
} from '../../Layout/Wrappers/Typography';
import EditList from '../../Shared/EditList';
import {
  reRenderEditCandidate,
  removeMappedSignalsFlag
} from '../CandidateSignalTags.lib';
import {
  addAliasToHistoryEntity
} from '../Forms/HistoryEntity';
import SignalChips from '../Forms/SignalChips';
import {
  mapOrganizationSignals,
  mapTenure
} from '../OrganizationSignals.lib';
import EntityEdit from './EntityEdit';

const DEBUG_EDIT_HISTORY = Core.debug('DEBUG_EDIT_HISTORY');

export default function HistoryEdit({
  history: model = {},
  onClick = () => null,
  onChange = history => null,
  onDelete = () => null,
  onClose = () => null,
  context,
  children,
  className,
  style,
}) {
  let {
    sectionKey,
    entityModelName,
    entityModelNamePlural,
    entityIdKey,
    entityNameKey,
    historyKey,
    historyNameKey,
    piecesKey,
    pieceKey,
    sortHistoryPieces,
    getEntityDuplicates,
    postEntity,
    getEntity,
    getHistoryModel,
    getSignals,
    fixEntityName,
    autoLinkEntity,
    filterHistoryPiecesWithTroubles,
    HistoryPieceEdit,
    candidate = {}
  } = context;
  const entityModelNameLowerCase = entityModelName.toLowerCase();
  const entityModelNamePluralLowerCase = entityModelNamePlural.toLowerCase();
  let { __dicOrganizations = {}, __dicSignals = {} } = candidate;
  let [state, setState] = useState({
    open: model.__newRecord,
    history: model,
    organization: __dicOrganizations[__dicSignals[model[entityIdKey]]?.crunchbaseOrganizationId],
  });
  let {
    fetchingSuggestions,
    suggestions = [],
    fetchedEntityId,
    fetchingEntityId,
    open = false,
    history = {},
    entity = {},
    organization = {},
    fetchingEntity,
    fetchingOrganization,
    wipeoutOrganization,
    error,
    search,
    showDisplayName,
    attemptToClose
  } = state;
  async function _updateState(update) {
    state = { ...state, ...update };
    setState(state);
    return await new Promise((resolve) => setTimeout(() => resolve(state)));
  }

  let { __newRecord } = history;

  let backgroundClass =
    !history[historyNameKey] && !history[entityIdKey] && !!history[piecesKey].length
      ? 'bg-a-red'
      : __newRecord || !history[entityIdKey] || !history[historyNameKey]
        ? 'bg-yellow-light'
        : 'bg-white-light';

  const defaultState = {
    open: false,
    error: false,
    fetchingEntity: false,
    fetchedEntityId: false,
    showDisplayName: undefined,
    entity: undefined,
    organization: undefined,
    search: undefined,
    history,
  };

  async function _onError(error) {
    _close();
    Core.showError(error);
  }
  function _open() {
    onClick();
    return _updateState({
      open: true,
      history: model,
      wipeoutOrganization: false
    });
  }

  /** 
   * @todo 
   * [HistoryEdit > New history]
   * Investigate odd behavior closing new history, seems to remove the first history but should not.
   * Legacy issue, it's already happen in production.
   */
  async function _close() {

    await _updateState({ attemptToClose: false });

    // Add to alternativeNames
    await addAliasToHistoryEntity({
      type: entityModelName,
      entityId: history[entityIdKey],
      name: history[historyNameKey]
    });

    // Remove unmodified new history pieces
    history[piecesKey] = history[piecesKey]
      .filter(({ __newRecord }) => !__newRecord)
      .sort((a, b) => sortHistoryPieces({ candidate, history, a, b }));
    await _updateState({ history });

    // Remove unmodified new history
    if (
      __newRecord ||
      (
        !history[historyNameKey] &&
        !history[entityIdKey] &&
        !history[piecesKey].length
      )
    ) {
      onDelete();
    }
    else {
      await _updateState(defaultState);
      await onChange(history);
    }
    setTimeout(onClose, 300);
  }

  function _delete() {
    Core.showConfirm({
      title: 'Confirm',
      message: (
        <span className='f-lg'>
          Are you sure you want to remove&nbsp;
          {history[historyNameKey]
            ? (
              <>
                {`this candidate's ${sectionKey} at `}
                <strong>
                  {history[historyNameKey]}
                </strong>
              </>
            )
            : <>this item</>
          }&nbsp;?
        </span>
      ),
      onAccept() {
        onDelete();
      },
    });
  }

  async function _postEntity({ name, lookAtCollectionFirst = true }) {
    DEBUG_EDIT_HISTORY && console.debug(
      'POST_ENTITY',
      { name, lookAtCollectionFirst }
    );
    const confirm = async () => {
      let duplicates = [
        ...await getEntityDuplicates({ name, exact: true }),
        ...(suggestions || []).filter(option => (option.type === SUGGESTION_TYPE__CBO) && !!String(option.label).match(new RegExp(`^${name}$`, 'i')))
      ].filter(v => !!v);
      Core.showConfirm({
        title: 'Confirm',
        content: (
          <Box column w100 className='f-lg'>
            <WarningMessage show={!!duplicates?.length} className='rounded f-lg'>
              {duplicates.length} {entityModelNamePluralLowerCase} with the same name already in the database. Delete/merge signals from multiple {entityModelNamePluralLowerCase} is not supported.
            </WarningMessage>
            <div>
              Are you sure you want to add <strong>{name}</strong> as a new {entityModelName.toLowerCase()}?
            </div>
            <div>
              This action can not be undone after creation.
            </div>
          </Box>
        ),
        async onAccept(event) {
          let error;
          const setError = (_error) => (error = _error);
          Core.showMessage('Posting a new record');
          let entity = await postEntity({ name }).catch(setError);
          if (error) { return Core.showError(error); }
          if (entity) {
            _setEntity({ entity, isAddingNewEntity: true });
          }
        },
        onAcceptLabel: `Add ${entityModelName.toLowerCase()}`,
        onCancel: () => _setHistory(name)
      });
    };
    if (lookAtCollectionFirst) {
      entity = await getEntity({ name });
      if (entity) {
        _setEntity({ entity });
      } else {
        confirm();
      }
    } else {
      confirm();
    }
  }

  async function _unsetEntity() {
    DEBUG_EDIT_HISTORY && console.debug('_unsetEntity');
    history[entityIdKey] = null;
    history.__newRecord = !!history.__newRecord;
    await _updateState({
      history,
      entity: {},
      organization: {},
      suggestions: [],
      fetchingSuggestions: false,
      wipeoutOrganization: true,
    });
    onChange(history);
  }

  /**
   * 
   * Sets the history in memory with out create a new entity record in the DB.
   * story_9688
   * 
   * @param {string} historyName
   */
  async function _setHistory(historyName) {
    if (!history[entityIdKey] && historyName && !wipeoutOrganization) {
      history.__newRecord = false;
      history[historyNameKey] = historyName;
      await _updateState({
        search: historyName,
        suggestions: [],
        fetchingSuggestions: false,
        entity: {},
        organization: {},
        wipeoutOrganization: false,
        history
      });
      onChange(history);
    }
  }

  async function _setEntity({
    entity,
    organization,
    isAddingNewEntity,
    isSavingEntity,
    isAutoCorrectingEntityId,
  }) {
    DEBUG_EDIT_HISTORY && console.debug('SET_ENTITY', {
      entity,
      organization,
      isAddingNewEntity,
      isSavingEntity,
      isAutoCorrectingEntityId,
    });

    if (!entity.id) {
      return Core.showError(`Unexpected error: missing ${entityModelNameLowerCase} id`);
    }

    if (isAddingNewEntity) {
      history = getHistoryModel(history);
    }
    if (isSavingEntity) {
      history.__forceAutomaticallyDerive = true;
    }
    history[entityIdKey] = entity.id;
    history[historyNameKey] = (
      history[historyNameKey] ||
      organization?.name ||
      entity.name ||
      search
    );
    search = entity.name;
    delete history.__newRecord;
    organization = (
      organization &&
      (organization.id === entity.crunchbaseOrganizationId)
    ) ? organization : {};
    await _updateState({
      search,
      history,
      entity,
      organization,
      fetchingEntityId: false,
      fetchedEntityId: isAutoCorrectingEntityId,
      wipeoutOrganization: false
    });
    onChange(history);
    if (isAddingNewEntity) {
      Core.showSuccess(
        <>
          <div>
            New {entityModelNameLowerCase} added
          </div>
          <div>
            Click on "Configure {entityModelNameLowerCase}" to configure this new {entityModelNameLowerCase}
          </div>
        </>
      );
    } else if (isSavingEntity) {
      Core.showSuccess(`${entityModelName} saved!`);
    } else {
      Core.hideMessage();
    }
  }

  async function _fetchEntity() {
    await _updateState({ fetchingEntity: true });
    entity = await getEntity({ id: history[entityIdKey] }).catch(_onError);
    if (entity) {
      candidate.__dicSignals[entity.id] = await getSignals(
        mapTenure({
          [entityIdKey]: entity.id,
          [piecesKey]: history[piecesKey]
        })
      ).catch(Core.showError);
      await reRenderEditCandidate();
      entity.signalTags = mapOrganizationSignals({
        signals: entity.signalTags || {},
        type: entityModelName
      });
      _updateState({ entity, fetchingEntity: false });
    } else {
      history[entityIdKey] = undefined;
      _updateState({ history, fetchingEntity: false });
    }
  }

  // Fetching stuff
  let __historyPiecesWithTroubles = [];
  if (open) {

    // get entity
    if (!!history[entityIdKey]) {
      if (!entity.id && !fetchingEntity && !error) {
        _fetchEntity();
      }
    }

    // auto-fixing linkage
    else if (
      !wipeoutOrganization &&
      !__newRecord &&
      history[historyNameKey] &&
      !fetchedEntityId &&
      !fetchingEntityId
    ) {
      _updateState({ fetchingEntityId: true }).then(async (state) => {
        let autolinkResponse = await autoLinkEntity({ entityName: history[historyNameKey] });
        entity = autolinkResponse.entity;
        organization = autolinkResponse.organization;
        if (entity) {
          await _setEntity({
            entity,
            organization,
            isAutoCorrectingEntityId: true,
          });
        }
        else {
          await _updateState({
            fetchingEntityId: false,
            fetchedEntityId: true,
          });
        }
      });
    }

    // get organization
    if (
      CrunchbaseLib.isEnabled() &&
      entity.crunchbaseOrganizationId &&
      !organization.id &&
      !fetchingOrganization &&
      !error
    ) {
      _updateState({ fetchingOrganization: true }).then((state) => {
        CrunchbaseLib.get({
          where: { id: entity.crunchbaseOrganizationId },
          limit: 1,
        })
          .then(async (organizations) => {
            const organization = Obj(Arr(organizations)[0]);
            const update = {};
            if (organization.id) {
              update.entity = await fixEntityName({
                entity,
                organization
              });
              update.organization = organization;
            }
            update.fetchingOrganization = false;
            _updateState(update);
          })
          .catch(_onError);
      });
    }

    __historyPiecesWithTroubles = filterHistoryPiecesWithTroubles({
      pieces: history[piecesKey]
    });

    if (!__historyPiecesWithTroubles) {
      _updateState({ attemptToClose: false });
    }

    //DEBUG_EDIT_HISTORY && 
    console.debug('DEBUG_EDIT_HISTORY:state\n', state);
  }

  let __defaultValue = entity.id || organization.id;
  let __defaultSearch = (
    wipeoutOrganization
      ? ''
      : (
        search ||
        entity.name ||
        organization.name ||
        history[historyNameKey]
      )
  );

  let dialogId = `draggable-dialog-title-edit-history`;

  return (
    <>
      <span onClick={_open} className={className} style={style}>
        {children || (
          <IconButton
            title={`Edit ${sectionKey} | Select ${entityModelNameLowerCase} to update tags`}
            className='c-inherit'
          >
            <i className='material-icons c-inherit'>edit</i>
          </IconButton>
        )}
      </span>

      <Dialog
        open={open}
        onClose={_close}
        name={dialogId}
        title={
          __newRecord ? `Add ${sectionKey}` : `Edit candidate ${sectionKey}${history[historyNameKey] ? `: ${history[historyNameKey]}` : ''}`
        }
        paperStyle={{
          width: 800,
          maxWidth: 'unset',
          minWidth: 320,
          minHeight: 512,
          maxHeight: 'calc(100vh - 10rem)',
        }}
        contentProp={{
          'data-entity-id': history[entityIdKey],
          'data-entity-name': history[historyNameKey]
        }}
        actionBar={!__newRecord && (
          <div className='mr-auto'>
            <Button flat
              title={history[historyNameKey]
                ? `Remove (${history[historyNameKey]}) from ${sectionKey}s`
                : `Remove ${sectionKey}`
              }
              onClick={_delete}
              size='small'
              className='mr-1 c-black-medium c-purple-hover tt-unset'
              startIcon={<i className='material-icons c-inherit'>delete</i>}
            >
              {`Remove ${sectionKey}`}
            </Button>
          </div>
        )}
        actions={[
          <Button
            onClick={async event => {
              if (!!__historyPiecesWithTroubles.length) {
                await _updateState({ attemptToClose: true });
              }
              else {
                await _updateState(defaultState);
              }
            }}
            primary
          >
            Close
          </Button>
        ]}
      >
        <Row column className={joinClassName([backgroundClass, 'p-2'])}>
          <Col fullWidth>

            {(entity.id) && (
              <div className='d-flex flex-align-left-top'>
                <Avatar
                  src={CrunchbaseLib.isEnabled() && organization.logo_url}
                  className='mr-1 bg-cyan'
                >
                  {Abbreviation(entity.name)}
                </Avatar>
                <div className='flex-1'>
                  <div className='d-flex flex-align-left-center'>
                    <div className='f-lg mr-1'>
                      {entity.name}
                    </div>
                    {CrunchbaseLib.isEnabled() && (
                      <>
                        {organization.id && <Avatar variant='small' src={CRUNCHBASE__FAVICON} className='icon16' />}
                        <div className='ml-auto d-flex flex-align-left-center'>
                          {history[entityIdKey] && (
                            <>
                              {organization?.cb_url && (
                                <Button flat
                                  size='small'
                                  title='Open the crunchbase URL'
                                  onClick={(event) => Core.openPopUp(organization.cb_url, 1200)}
                                  className='tt-unset c-black-medium c-purple-hover px-2'
                                  endIcon={<i className='material-icons c-inherit'>open_in_new</i>}
                                >
                                  Open in crunchbase
                                </Button>
                              )}
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                  <div className='c-black-medium f-xs'>
                    {entity.description || organization.short_description}
                  </div>
                </div>
              </div>
            )}

            <div className='d-flex flex-align-left-center mt-1'>
              <strong className='c-cyan-darker m-0 mr-1 f-lg'>
                Look up {entityModelName.toLowerCase()}
              </strong>
            </div>

            <SuggestionList
              title='Suggestions'
              placeholder='Type or select an organization'
              // DATA to be used as suggested options
              data={(() => {
                let value = escapeRegExp(search || history[historyNameKey]);
                let customSuggestions = [
                  value && {
                    id: 'add-new-entity',
                    value,
                    label: (
                      <div>
                        <div className='d-flex flex-align-left-center'>
                          <div className='mr-1 fw-600'>
                            Add new {entityModelNameLowerCase}:
                          </div>
                          <div>
                            {value}
                          </div>
                        </div>
                        <div className='c-black-medium f-sm wrap'>
                          {compileText(
                            `Please review and select a {{ENTITY_MODEL_NAME}} from the list below. If none of them is the correct {{ENTITY_MODEL_NAME}}, add {{ENTITY_NAME}} to our {{ENTITY_MODEL_NAME}} database. You can edit the {{ENTITY_MODEL_NAME}} description after {{ENTITY_NAME}} is added.`,
                            {
                              ENTITY_MODEL_NAME: entityModelNameLowerCase,
                              ENTITY_NAME: value
                            }
                          )}
                        </div>
                      </div>
                    ),
                    logo: false,
                    type: SUGGESTION_TYPE_NEW,
                    endIcon: <i className='material-icons c-purple mr-1 icon32'>add_circle</i>
                  },
                ].filter((v) => !!v);
                if (!!customSuggestions.length) {
                  customSuggestions.push({
                    label: `Select ${entityModelNameLowerCase}`,
                    type: SUGGESTION_TYPE_HEADER,
                    icon: false
                  });
                }
                return [...customSuggestions, ...suggestions];
              })()}
              // SELECTED option id
              value={__defaultValue}
              // DEFAULT search value - this input updates the search value -
              defaultSearch={__defaultSearch}
              defaultIcon={
                !!organization.id && evalSameName(organization.name, __defaultSearch)
                  ? CRUNCHBASE__FAVICON
                  : !!entity.id && evalSameName(entity.name, __defaultSearch)
                    ? 'https://go10x10.com/favicon.ico'
                    : null
              }
              // SHOW a loader if date is fetching and list is empty
              loading={fetchingSuggestions}
              // ON render option
              optionRender={(option) => (
                <div className='d-flex flex-align-center-top'>
                  {(option.logo !== false) && (
                    <div className='mt-05 mr-1'>
                      <Avatar
                        src={option.logo}
                        style={{ width: 32, height: 32 }}
                        className='f-xs bg-purple-common'
                      >
                        {String(option.value || option.label)
                          .replace(/\W/g, '')
                          .split(' ')
                          .map((w) => w[0]?.toUpperCase())
                          .slice(0, 2)
                          .join('')}
                      </Avatar>
                    </div>
                  )}
                  <div className='d-flex flex-align-left-center'>
                    <div
                      className='d-flex flex-column truncate'
                      style={{ minWidth: 512, maxWidth: 640 }}
                    >
                      <div className='d-flex flex-align-left-center'>
                        <div className='mr-1'>
                          {option.label}
                        </div>
                        {CrunchbaseLib.isEnabled() && (
                          (option.type === SUGGESTION_TYPE__CBO) ||
                          (!!option.crunchbaseOrganizationId)
                        ) && (
                            <Avatar
                              src={CRUNCHBASE__FAVICON}
                              style={{ width: 16, height: 16 }}
                              className='f-xs bg-purple-common mr-1'
                            />
                          )}
                        {!!option.meta?.alternativeNames.length && (
                          <small
                            className='c-black-medium truncate flex-1'
                            title={option.meta.alternativeNames.join(', ')}
                          >
                            Aliases: {option.meta.alternativeNames.join(', ')}
                          </small>
                        )}
                      </div>
                      <small className='c-black-medium truncate' title={option.description}>
                        {option.description}
                      </small>
                    </div>
                    {option.endIcon && (
                      <div className='d-flex flex-align-right-center'>
                        {option.endIcon}
                      </div>
                    )}
                  </div>
                </div>
              )}
              // ON (pick one option | keydown enter | blur component)
              onSelect={async ({ selected, option, search: _search }) => {
                organization = undefined;
                entity = undefined;
                search = _search;
                DEBUG_EDIT_HISTORY && console.debug(
                  'ON_SELECT',
                  { selected, option, search }
                );
                if (!search) { return; }
                let searchable = search.length >= 2;
                if (!searchable) {
                  return Core.showWarning(
                    `Type at least 2 characters to search by a ${entityModelNameLowerCase}`
                  );
                }
                if (fetchingSuggestions) {
                  return Core.showWarning(
                    'Wait until the organizations become loaded to select one'
                  );
                }

                let error, isAddingNewEntity;
                const setError = (e) => (error = e);

                Core.showLoader(`Matching ${entityModelNameLowerCase} name...`);

                // LOOK for organization on memory suggestion then on crunchbase DB
                if (selected && option) {
                  if (option.type === SUGGESTION_TYPE_NEW) {
                    Core.hideMessage();
                    return _postEntity({ name: search, lookAtCollectionFirst: false });
                  }

                  if (option.type === SUGGESTION_TYPE__CBO) {
                    if (__dicOrganizations[selected]) {
                      organization = __dicOrganizations[selected];
                    } else {
                      organization = await CrunchbaseLib.get({ where: { id: selected }, limit: 1 })
                        .then((organizations) => organizations[0])
                        .catch(setError);
                      if (!organization) {
                        setError(
                          <>
                            <strong>Something went wrong</strong>
                            <br />
                            Contact 10by10 support
                            <br />
                            Error: Missing CBO in DB
                            <br />
                            {selected}
                          </>
                        );
                      }
                    }
                  }
                  else if (option.type === entityModelName) {
                    let entity = await getEntity({ id: option.id }).catch(setError);
                    if (error) {
                      return Core.showError(error);
                    }
                    // Look for organization pair
                    if (entity.crunchbaseOrganizationId) {
                      organization = await CrunchbaseLib.get({
                        where: { id: entity.crunchbaseOrganizationId },
                      })
                        .then((organizations) => organizations[0])
                        .catch(Core.showError);
                    }
                    entity = await fixEntityName({ entity, organization });
                    return _setEntity({ entity, organization });
                  }
                }
                else {
                  organization = await CrunchbaseLib.getOrganization({
                    name: search,
                    type: entityModelName
                  }).catch(setError);
                }

                if (error) {
                  return Core.showError(error);
                }

                if (organization) {
                  // LOOK for entity pair in DB
                  let entity = await getEntity({
                    query: {
                      where: { crunchbaseOrganizationId: organization.id },
                      fields: ['id', entityNameKey],
                    }
                  }).catch(setError);
                  if (error) {
                    return Core.showError(error);
                  }

                  // IF entity doesn't exists then post a new entity in DB
                  if (!entity) {
                    Core.showMessage(`Posting a new ${entityModelNameLowerCase}`);
                    entity = await postEntity({
                      crunchbaseOrganizationId: organization.id,
                      name: organization.name,
                    }).catch(setError);
                    if (entity) {
                      isAddingNewEntity = true;
                    }
                  }
                  if (error) {
                    return Core.showError(error);
                  }

                  // SET the entity pair
                  if (entity) {
                    _setEntity({ entity, organization, isAddingNewEntity });
                  }
                } else {
                  // LOOK for entity IF organization does not found
                  let entity = await getEntity({ name: search }).catch(setError);
                  if (error) {
                    return Core.showError(error);
                  }

                  if (entity) {
                    // Look for organization pair
                    if (entity.crunchbaseOrganizationId) {
                      organization = await CrunchbaseLib.get({
                        where: { id: entity.crunchbaseOrganizationId },
                      })
                        .then((organizations) => organizations[0])
                        .catch(Core.showError);
                    }
                    entity = await fixEntityName({ entity, organization });
                    _setEntity({ entity, organization });
                  }

                  // Entity don't found
                  else {
                    Core.hideMessage();
                    _postEntity({ name: search });
                  }
                }
              }}
              onCloseList={_setHistory}
              // ON search fetch new suggestions
              onSearch={async (_search = '') => {
                search = _search
                DEBUG_EDIT_HISTORY && console.debug('ON_SEARCH', search);
                if (fetchingSuggestions) { return; }
                if (search) {
                  CrunchbaseLib.getSuggestions({
                    name: search,
                    type: entityModelName
                  }).then((suggestions) => suggestions && _updateState({ suggestions, fetchingSuggestions: false }))
                    .catch((error) => {
                      _updateState({ suggestions: [], fetchingSuggestions: false, search });
                      Core.showError(error);
                    });
                }
                // ON clear search
                else {
                  await _unsetEntity();
                  if (history[historyNameKey]) {
                    CrunchbaseLib.getSuggestions({
                      name: history[historyNameKey],
                      type: entityModelName
                    }).then((suggestions) => suggestions && _updateState({ suggestions, fetchingSuggestions: false }))
                      .catch((error) => {
                        _updateState({ suggestions: [], fetchingSuggestions: false });
                        Core.showError(error);
                      });
                  }
                }
              }}
              onVisible={async (search) => {
                DEBUG_EDIT_HISTORY && console.debug('ON_VISIBLE', search);
                search = search || history[historyNameKey];
                if (search) {
                  CrunchbaseLib.getSuggestions({
                    name: search,
                    type: entityModelName
                  })
                    .then((suggestions) => suggestions && _updateState({ suggestions, fetchingSuggestions: false }))
                    .catch((error) => {
                      _updateState({ suggestions: [], fetchingSuggestions: false });
                      Core.showError(error);
                    });
                }
              }}
              focusOnLoaded={__newRecord}
            />
          </Col>
        </Row>

        {!__newRecord && (
          (
            showDisplayName ||
            !evalSameName(history[historyNameKey], __defaultSearch)
          ) ?
            (
              <Row className='mt-3'>
                <Col className='w-100'>
                  <div className='d-flex flex-align-left-center'>
                    <strong className='c-cyan-darker m-0 mr-1 f-lg'>
                      Display as
                    </strong>
                    <div className='ml-auto d-flex nowrap'>{/** space for toolbar */}</div>
                  </div>
                  <Input
                    placeholder='Type a employer name'
                    value={history[historyNameKey]}
                    onChange={async (event) => {
                      history[historyNameKey] = event.target.value;
                      if (!history[historyNameKey]) {
                        _unsetEntity();
                      }
                      else {
                        await _updateState({ history });
                        onChange(history);
                      }
                    }}
                    onPressEnter={(event) => {
                      if (history[historyNameKey]?.length >= 2 && !history[entityIdKey]) {
                        _postEntity({ name: history[historyNameKey] });
                      }
                    }}
                  />
                </Col>
              </Row>
            ) : (
              <Button flat
                size='small'
                className='tt-unset c-black-medium c-purple-hover mx-2'
                onClick={event => {
                  _updateState({ showDisplayName: true });
                }}
                endIcon={
                  <i className='material-icons'>
                    expand_more
                  </i>
                }
              >
                {`Use a diff display name for this ${sectionKey}`}
              </Button>
            )
        )}

        {history[historyNameKey] ? (
          <>
            <Divider className='mt-3 mb-1' />
            <Row className=''>
              <Col fullWidth>
                <div className='d-flex flex-align-left-top'>
                  <div className='flex-column'>
                    <Label>
                      Candidate’s positive/negative signals
                    </Label>
                    <div className='f-sm c-black-medium d-none'>
                      From work history
                    </div>
                  </div>
                  {Core.isAdmin() && (
                    <EntityEdit
                      className='ml-auto'
                      title={`Edit tags and alternative names of ${(
                        entity?.name || `the ${entityModelNameLowerCase}`
                      )}`}
                      entityId={history[entityIdKey]}
                      candidateId={candidate.id}
                      historyName={history[historyNameKey]}
                      onSave={(entity) => {
                        _setEntity({
                          entity,
                          organization,
                          isSavingEntity: true,
                        });
                      }}
                      onDelete={_unsetEntity}
                      context={context}
                    >
                      <Button flat
                        size='small'
                        endIcon={<i className='material-icons c-inherit'>settings</i>}
                        className='tt-unset c-black-medium c-purple-hover px-2'
                      >
                        Configure {entityModelNameLowerCase}
                      </Button>
                    </EntityEdit>
                  )}
                </div>
                <SignalChips
                  {...{ [historyKey]: history }}
                  signalsHash={__dicSignals}
                  onChange={async (update) => {
                    history = { ...history, ...update };
                    await _updateState({ history });
                    onChange(history);
                  }}
                  className='pl-2'
                />
              </Col>
            </Row>
          </>
        ) : (
          !__newRecord && (
            <div className='mt-3 px-2'>
              <WarningMessage>
                {(
                  CrunchbaseLib.isEnabled()
                    ? `Save the ${entityModelNameLowerCase} or select a crunchbase organization to edit the ${sectionKey} tags`
                    : `Add as a new ${entityModelNameLowerCase} or select an existing one to edit the ${sectionKey} tags`
                )}
              </WarningMessage>
            </div>
          )
        )}

        {(!__newRecord || !!history[piecesKey].length) && (
          <>
            <Divider className='mt-3' />
            <Row style={{ flexDirection: 'column' }} className='pl-2'>
              <EditList
                name={capitalize(pieceKey)}
                heading={
                  <strong className='f-lg'>
                    {capitalize(pieceKey)}s ({history[piecesKey].length})
                  </strong>
                }
                data={history[piecesKey]}
                model={getHistoryModel()}
                nameProp={'title'}
                onChange={async (historyPieces) => {
                  history[piecesKey] = historyPieces;
                  removeMappedSignalsFlag();
                  await _updateState({ history });
                  onChange(history);
                }}
                getContext={() => ({
                  candidate,
                  [historyKey]: history,
                  attemptToClose
                })}
                renderItem={HistoryPieceEdit}
                subsection
              />
            </Row>
          </>
        )}
      </Dialog>
    </>
  );
}
