import {
  KEY__TABLE_COLLECTION__USER_AGREE_TO_TERMS
} from '../../../dictionaries/TableCollectionKeys.dic';
import Core from '../../../lib/Core';
import Store from '../../../lib/Store';

export function getUserAgreeToEngagementsTerms() {
  return Store.get(KEY__TABLE_COLLECTION__USER_AGREE_TO_TERMS);
}

export function setUserAgreeToEngagementsTerms(value) {
  Store.set(KEY__TABLE_COLLECTION__USER_AGREE_TO_TERMS, value);
  Core.updateState();
  return value;
}

export function yesUserAgreeToEngagementsTerms() {
  return Store.get(KEY__TABLE_COLLECTION__USER_AGREE_TO_TERMS) === true;
}

export function noUserAgreeToEngagementsTerms() {
  return Store.get(KEY__TABLE_COLLECTION__USER_AGREE_TO_TERMS) === false;
}

export function yesDisplayAccessToEngagements() {
  return (
    Core.isAdmin() ||
    (
      Store.get(KEY__TABLE_COLLECTION__USER_AGREE_TO_TERMS) !== false
    )
  );
}

export function unsetUserAgreeToEngagementsTerms() {
  Store.remove(KEY__TABLE_COLLECTION__USER_AGREE_TO_TERMS);
}
