import Company from './Company';
import Core from './Core';
import {
  NOT,
  capitalize
} from './GenericTools.lib';
import Http from './Http';

export const SUGGESTION_TYPE__CBO = 'CBO';
export const SUGGESTION_TYPE__COMPANY = 'Company';
export const SUGGESTION_TYPE__SCHOOL = 'School';

const CrunchbaseLib = {
  isEnabled() {
    /**
     * [2025-03-07][12196]  
     * @context  
     * BB requested to ensure that the use of  
     * Crunchbase Collections is not blocked  
     * based on the `useCrunchbaseOn` setting.  
     *  
     * @notes  
     * Since Crunchbase API calls are managed  
     * on the backend, we assume that, on the frontend,  
     * any Crunchbase reference should remain accessible  
     * regardless of the `useCrunchbaseOn` setting.  
     *  
     * @todo  
     * Once the above assumption is validated and confirmed,  
     * remove this implementation throughout the FE application.  
     */
    // return !!Definition.getTag({ categoryKey: 'settings', tagLabel: 'useCrunchbaseOn' }).id
    return true;
  },
  get: async (options = {}) => {
    if (NOT(CrunchbaseLib.isEnabled())) { return []; }
    let { where, fields, limit } = options;
    where = Object(where);
    return Http.get(
      Core.getApi('CrunchbaseOrganizations'),
      {
        filter: JSON.stringify({ where, fields, limit })
      }
    );
  },
  getSuggestionsV1: async ({ name = '', limit }) => {
    name = String(name).trim();
    return CrunchbaseLib.get({
      where: { name: limit === 1 ? { like: name, options: 'i' } : { like: `^${name}|${name}|${name.split(' ').shift()}`, options: 'i' } },
      fields: ['id', 'name', 'short_description'],
      limit: limit || 50,
    });
  },
  getSuggestionsV2: async ({ name = '', limit }) => {
    name = String(name).trim();
    if (limit === 1) {
      return CrunchbaseLib.get({
        where: {
          name: { inq: [...new Set([name].map(key => [key, capitalize(key), key.toLowerCase(), key.toUpperCase()]).flat())] }
        },
        fields: ['id', 'name', 'short_description'],
        limit: limit,
      });
    }
    else {
      let keys = [...new Set([name, ...name.split(' ').filter(v => v.length > 2)])];
      let companies = await Company.get({
        where: {
          or: [
            { name: { like: `${keys.join('|')}`, options: 'i' } },
            { alternativeNames: { like: `${keys.join('|')}`, options: 'i' } }
          ]
        },
        fields: ['id', 'name', 'alternativeNames', 'crunchbaseOrganizationId'],
        limit: limit || 50,
      }).catch(Core.showError) || [];
      return CrunchbaseLib.get({
        where: {
          or: [
            !!companies.length && {
              id: { inq: companies.map(({ crunchbaseOrganizationId }) => crunchbaseOrganizationId) }
            },
            {
              name: {
                inq: [...new Set([
                  ...(companies.map(({ name, alternativeNames }) => [name, ...(alternativeNames || [])]).flat()),
                  ...keys.map(word => [word, capitalize(word), word.toLowerCase(), word.toUpperCase()]).flat()])
                ].filter(v => !!v)
              }
            }
          ].filter(v => !!v)
        },
        fields: ['id', 'name', 'short_description'],
        limit: limit || 50,
      });
    }
  },
  getSuggestions: async ({
    name = '',
    type = SUGGESTION_TYPE__COMPANY,
    subtype = SUGGESTION_TYPE__COMPANY
  }) => {
    name = String(name).trim();
    return name && Http.get(
      Core.getApi('CrunchbaseOrganizations/getSuggestions'),
      {
        name,
        type,
        subtype
      }
    );
  },
  /**
   * This is used to avoid making direct queries by name to the CrunchbaseOrganization collection, which can hang the server.
   * 
   * @param {object} options 
   * @param {object} options.name
   * @param {object} options.exact
   * @param {object} options.type
   * @returns 
   */
  getOrganization: async ({ name, exact, type }) => {
    let organization = name && await CrunchbaseLib.getSuggestions({ name, type })
      .then(suggestions => {
        return (suggestions || []).find(suggestion => (
          (exact === true)
            ? (String(name).toLowerCase() === String(suggestion.name).toLowerCase())
            : (exact === false)
              ? !!String(suggestion.name).match(new RegExp(`${name}`, 'i'))
              : !!String(suggestion.name).match(new RegExp(`^${name}`, 'i'))
        ));
      });
    return !!organization?.id && CrunchbaseLib.get({ where: { id: organization.id } }).then(organizations => organizations[0]);
  }
};

export const CRUNCHBASE__FAVICON = 'https://www.crunchbase.com/favicon.ico?v=1.0';
export default CrunchbaseLib;
window.CrunchbaseLib = CrunchbaseLib;
