import {
  isEqual
} from 'lodash';
import {
  Component
} from "react";
import {
  NOT
} from '../../../../../../lib/Boolean.lib';
import TextField from '../../../../../Layout/Wrappers/TextField';
import Typography from '../../../../../Layout/Wrappers/Typography';
import NewCategoryDropdown from '../Dropdowns/NewCategoryDropdown';

class TextElement extends Component {

  constructor() {
    super(...arguments);
    this.state = {
      element: this.props.element
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (NOT(isEqual(prevProps.element, this.props.element))) {
      this.setState({
        element: this.props.element
      });
    }
  }

  handlerValue = (ev) => {
    const { element } = this.state;
    element.value = ev.target.value;
    this.setState({ element }, () => {
      const { onApply } = this.props;
      !!onApply && onApply(element);
    });
  }

  handlerCategory = (category) => {

    let { element } = this.state;
    element.category = category;
    this.setState({ element }, () => {
      const { onApply } = this.props;
      !!onApply && onApply(element);
    });
  }

  render() {
    let { element } = this.state;
    let { onlyView } = this.props;

    return <>
      {!onlyView && !!element.value &&
        <TextField
          value={element.value}
          onChange={(event) => this.handlerValue(event)}
        />
      }&nbsp;
      {!!onlyView &&
        <Typography
          alignLeft
          role='TextElementValue'
          style={{ minHeight: 36 }}
        >
          {element.value}
        </Typography>
      }&nbsp;
      {!!element.category &&
        <NewCategoryDropdown
          category={element.category}
          handlerCategory={this.handlerCategory}
          onlyView={onlyView}
        />
      }
    </>
  }
}

export default TextElement;
