import {
  CSS_GRAY_RGB,
  CSS_PURPLE_RGB
} from '../Constants';
import {
  joinHTML
} from '../HTML.lib';

/**
 * @param {string} CANDIDATE__FULLNAME  [plaintext] Candidate's firstname and lastname.
 * @param {string} EMPLOYER__NAME  [plaintext] Employer's name.
 * @param {string} STAGE  [plaintext] Engagement.stage
 * @param {string} STATUS  [plaintext] Engagement.status
 */
export const TEMPLATE__SUBJECT__STAGE_TRANSITION = joinHTML(`
[ 10x10 {{TYPE}} ] {{CANDIDATE__FULLNAME}} - {{EMPLOYER__NAME}}, moved to {{STAGE}} stage - {{STATUS}}
`);

/**
 * @param {string} CANDIDATE__FULLNAME  [plaintext] Candidate's firstname and lastname.
 * @param {string} EMPLOYER__NAME  [plaintext] Employer's name.
 * @param {string} ENGAGEMENT__SUBMITTED  [plaintext] Formatted engagement's submitted date.
 * @param {string} INTERVIEW_DATE  [plaintext] Formatted interview date.
 * @param {string} JOB__ROLES  [plaintext] Job's roles.
 * @param {string} JOB__TITLE  [plaintext] Job's title.
 * @param {string} PREVIOUS__STAGE__DESCRIPTION  [plaintext] Previous-stage's description.
 * @param {string} PREVIOUS__STAGE__LABEL  [plaintext] Custom previous-stage label.
 * @param {string} RECRUITER__NAME  [plaintext] Recruiter's talentAdvisor or recruiter's firstname.
 * @param {string} STAGE__DESCRIPTION  [plaintext] Stage's description.
 * @param {string} STAGE__LABEL  [plaintext] Custom stage label.
 * @param {string} STATUS__DESCRIPTION  [plaintext] Status's description.
 * @param {string} STATUS__LABEL  [plaintext] Custom status label.
 * @param {boolean} YES__FIRST_SCREEN_INTERVIEW  True, if first screen interview.
 * @param {boolean} YES__FULLDAY  True, if interview date is full day.
 * @param {boolean} YES__OFFER  True, if engagement stage is 'offer'.
 */
export const TEMPLATE__BODY__STAGE_TRANSITION = joinHTML(`
Hi {{RECRUITER__NAME}}, Please see the new updates:
<br/><br/>
Candidate: {{CANDIDATE__FULLNAME}}<br/>
Employer: {{EMPLOYER__NAME}}<br/>
Job: {{JOB__TITLE}}<br/>
Roles: {{JOB__ROLES}}<br/>
Submitted: {{ENGAGEMENT__SUBMITTED}}
<br/><br/>
Stage:&nbsp;
<b style="color: ${CSS_PURPLE_RGB};">{{STAGE__LABEL}}</b>
{{#if STAGE__DESCRIPTION}}
  &nbsp;({{STAGE__DESCRIPTION}})
{{/if}}
{{#if PREVIOUS__STAGE__LABEL}}
  ,&nbsp;was&nbsp;
  <b style="color: ${CSS_PURPLE_RGB};">
    {{PREVIOUS__STAGE__LABEL}}
  </b>
  {{#if PREVIOUS__STAGE__DESCRIPTION}}
    &nbsp;({{PREVIOUS__STAGE__DESCRIPTION}})
  {{/if}}
{{/if}}
.<br/><br/>
Status:&nbsp;
<b style="color: ${CSS_PURPLE_RGB};">{{STATUS__LABEL}}</b>
{{#if STATUS__DESCRIPTION}}
  &nbsp;({{STATUS__DESCRIPTION}})
{{/if}}
.<br/><br/>
{{#if INTERVIEW_DATE}}
  <h2>
    Interview Date:&nbsp;
    <b style="color: ${CSS_PURPLE_RGB};">
      {{moment INTERVIEW_DATE YES__FULLDAY}}
    </b>
  </h2>
  <br/><br/>
{{/if}}
---------------------------------
<br/><br/>
{{#if YES__FIRST_SCREEN_INTERVIEW}}
  <span style="color: ${CSS_GRAY_RGB};">
    Please make sure your candidate shows up to the interview well prepared and can explain why they are interested in this role.  If you have any concerns that the candidate may not be well prepared for the interview, please alert us right away if we need to give the interviewer a heads up.
  </span>
  <br/><br/>
{{/if}}
{{#if YES__OFFER}}
  <span style="color: ${CSS_GRAY_RGB};">
    This is a heads up on a potential offer that we are now tracking very closely. We don't have details on what steps are left for their offer decision yet. We will let you know when we have more details. Please do the same if you have any information from the candidate side.
  </span>
  <br/><br/>
{{/if}}
<span style="color: ${CSS_GRAY_RGB};">
  <a href="{{JOB__VIEW__URL}}" target="_blank">See job details</a> - to copy JD for your candidate, click on the copy icon near the top right corner of the job details card.
</span>
<br/><br/>
<span style="color: ${CSS_GRAY_RGB};">
  {{summary JOB}}
</span>
<br/><br/>
---------------------------------
<br/><br/>
Team 10x10
<br/><br/>
PS. This email is automatically generated by the 10x10 platform to provide you real time update
<br/><br/>
`);
