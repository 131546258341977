import {
  STAGE_ONSITE,
  STAGE_REVIEW, STAGE_SCREEN,
  STATUS_W_CANDIDATE_PERMISSION,
  STATUS_W_CANDIDATE_SCHEDULE,
  STATUS_W_CANDIDATE_SUBMIT_FORM,
  STATUS_W_CANDIDATE_SUBMIT_HOMEWORK,
  STATUS_W_EMPLOYER,
  STATUS_W_EMPLOYER_FEEDBACK,
  STATUS_W_EMPLOYER_FORM_FEEDBACK,
  STATUS_W_EMPLOYER_HOMEWORK_FEEDBACK,
  STATUS_W_EMPLOYER_SCHEDULE,
  STATUS_W_EMPLOYER_SEND_FORM,
  STATUS_W_EMPLOYER_SEND_HOMEWORK,
  STATUS_W_ONSITE,
  STATUS_W_SCREEN
} from './Engagement.dic';

export const EngagementUI = {
  homeworkAssigned: {
    label: 'HW issued',
    fullLabel: 'Homework assigned',
  },
  homeworkCompleted: {
    label: 'HW done',
    fullLabel: 'Homework completed',
  },
  closeEngagements: {
    sendEmails: {
      tooltip: 'Uncheck to perform operation without sending email'
    }
  },
  empFBText: {
    title: 'Log feedback to 10x10',
    placeholder: 'Type to edit'
  },
  toRecruiterFBText: {
    title: 'Log feedback to Recruiter',
    placeholder: 'Type to edit'
  },
  stages: {
    [STAGE_REVIEW]: {
      label: 'CV Review',
      description: 'Application submitted to the employer, awaiting feedback',
    },
    [STAGE_SCREEN]: {
      label: 'Screen Interview',
      description: 'Accepted for early-stage interviews, which may include recruiter screens, technical screens, or assignments',
    },
    [STAGE_ONSITE]: {
      label: 'Onsite Interview',
      description: 'Shortlisted for final rounds after successfully passing previous screenings',
    },
  },
  statuses: {
    [STATUS_W_CANDIDATE_SCHEDULE]: {
      description: 'Employer has sent interview invite, waiting for candidate’s response. Notify us once the candidate selects a time',
    },
    [STATUS_W_CANDIDATE_SUBMIT_FORM]: {
      description: 'Waiting for the candidate to complete and submit the form',
    },
    [STATUS_W_CANDIDATE_SUBMIT_HOMEWORK]: {
      description: 'Waiting for the candidate to complete and submit the homework',
    },
    [STATUS_W_EMPLOYER]: {
      description: 'Waiting for additional information from the employer',
    },
    [STATUS_W_EMPLOYER_FEEDBACK]: {
      description: 'Waiting for CV or interview feedback from the employer',
    },
    [STATUS_W_EMPLOYER_FORM_FEEDBACK]: {
      description: 'Waiting for employer’s feedback on the candidate’s form',
    },
    [STATUS_W_EMPLOYER_HOMEWORK_FEEDBACK]: {
      description: 'Waiting for employer’s feedback on the candidate’s homework'
    },
    [STATUS_W_EMPLOYER_SCHEDULE]: {
      description: 'Waiting for employer to send or confirm the interview time'
    },
    [STATUS_W_EMPLOYER_SEND_FORM]: {
      description: 'Waiting for the employer to send the form to the candidate'
    },
    [STATUS_W_EMPLOYER_SEND_HOMEWORK]: {
      description: 'Waiting for the employer to send the homework to the candidate'
    },
    [STATUS_W_SCREEN]: {
      description: 'Early/mid-stage interview date confirmed, waiting for the date to come'
    },
    [STATUS_W_ONSITE]: {
      description: 'Late-stage interview date confirmed'
    },
    [STATUS_W_CANDIDATE_PERMISSION]: {
      description: 'Possible match identified, waiting for recruiter to pitch and confirm if the candidate is a good fit and interested in the role and submit the candidate'
    },
  }
}
export default EngagementUI;
