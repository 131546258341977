import {
  Collapse,
  Link
} from "@mui/material";
import {
  compile
} from "handlebars";
import moment from "moment";
import {
  Component,
  Fragment
} from "react";
import {
  ENGAGEMENT__STATE_OPEN,
  STAGE_REVIEW,
  STAGE_SUBMISSION,
  STATUS_W_10X10,
  STATUS_W_EMPLOYER_FEEDBACK
} from '../../../dictionaries/Engagement.dic';
import {
  Arr,
  join
} from '../../../lib/Array.lib';
import Candidate from "../../../lib/Candidate";
import {
  DEFAULT_EXPIRATION
} from '../../../lib/Constants';
import Core from "../../../lib/Core";
import Definition, {
  ACCOUNT_ACTION__EDIT_EMPLOYER,
  ACCOUNT_ACTION__EDIT_JOB,
  ACCOUNT_ACTION__MATCH_CANDIDATE,
  ACCOUNT_ACTION__VIEW_ENGAGEMENT,
  DEFINITION_CATEGORY__ATS_TYPES,
  DEFINITION_CATEGORY__EMPLOYER_SUBMISSION_METHOD,
  EMPLOYER_SUBMISSION_METHOD__ATS,
  EMPLOYER_SUBMISSION_METHOD__EMAIL,
  EMP_MSG_TYPE__SUBMISSION_ID
} from "../../../lib/Definition";
import Engagement from "../../../lib/Engagement";
import {
  composeCopyText
} from '../../../lib/HTML.lib';
import {
  getCandidateModel
} from "../../../lib/models/candidate";
import {
  mapEngagement
} from "../../../lib/models/engagement";
import {
  Obj,
  deepMerge
} from '../../../lib/Object.lib';
import School from "../../../lib/School";
import {
  getEmployerPendingsURL
} from '../../../lib/services/Accounts/Session.lib';
import {
  combineEmailsLists,
  mapContactsToStrings,
  sendSafeEmail
} from "../../../lib/services/Email/Email.lib";
import Store from "../../../lib/Store";
import {
  trim,
  validateString
} from '../../../lib/String.lib';
import TemplateLib from "../../../lib/Template.lib";
import {
  TEMPLATE__CANDIDATE_SUBMISSION__EMAIL_BODY,
  TEMPLATE__CANDIDATE_SUBMISSION__EMAIL_BODY_CLOSING,
  TEMPLATE__CANDIDATE_SUBMISSION__EMAIL_BODY_INTRODUCTION,
  TEMPLATE__CANDIDATE_SUBMISSION__EMAIL_SUBJECT,
  TEMPLATE__CANDIDATE_SUBMISSION__SUBMISSION_NOTES
} from '../../../lib/templates/CandidateSubmission.template';
import {
  TEMPLATE__COMMON__GREEN_BUTTON
} from '../../../lib/templates/CommonSingleGreenButtonForEmails.template';
import cleanHtml from "../../../lib/tools/cleanHtml";
import copyHtml from "../../../lib/tools/copyHtml";
import copyText from "../../../lib/tools/copyText.tool";
import downloadFile from "../../../lib/tools/downloadFile";
import formatURL from "../../../lib/tools/formatURL";
import getBinary from "../../../lib/tools/getBinary";
import {
  getLocation,
  getParams,
  reloadLocation,
  setLocation
} from '../../../lib/URL.lib';
import {
  setURLQueryString
} from '../../../lib/URLQuery.lib';
import InternalSubmissionInstructions from '../../Employers/Edit/Forms/InternalSubmissionInstructions';
import EmployerUI from '../../Employers/EmployerUI.dic';
import Page from '../../Layout/Page';
import Box from '../../Layout/Wrappers/Box';
import Button from '../../Layout/Wrappers/Button';
import Checkbox from '../../Layout/Wrappers/Checkbox';
import Col from "../../Layout/Wrappers/Col";
import Divider from '../../Layout/Wrappers/Divider';
import Fieldset from '../../Layout/Wrappers/Fieldset';
import {
  Html
} from '../../Layout/Wrappers/Html';
import IconButton from '../../Layout/Wrappers/IconButton';
import Menu from '../../Layout/Wrappers/Menu';
import {
  WarningChipMessage,
  WarningMessage
} from '../../Layout/Wrappers/Message';
import Navigate from '../../Layout/Wrappers/Navigate';
import NavLink from '../../Layout/Wrappers/NavLink';
import RichTextBox from "../../Layout/Wrappers/RichTextBox";
import Row from "../../Layout/Wrappers/Row";
import Table from '../../Layout/Wrappers/Table';
import Typography from '../../Layout/Wrappers/Typography';
import MatchDrawer from "../../Match/List/MatchDrawer";
import AnswerJobsQuestions from '../../PrescreenQuestions/AnswerJobsQuestions';
import {
  fetchQuestionFromBank
} from '../../PrescreenQuestions/PrescreenQuestions.lib';
import {
  formatHtmlJobsPermittedAnswers
} from '../../PrescreenQuestions/PrescreenQuestionsSection';
import {
  LoadingImage
} from '../../Shared/Loader';
import CandidateSummaryStickyNote from '../CandidateSummaryStickyNote';
import {
  CANDIDATE_UI
} from '../CandidateUI.dic';
import DoubleContentDrawer from "../ResumePdf/DoubleContentDrawer";
import getCandidateNotes from '../SubmissionNotes.lib';
import AtsPreviewBlock from "./AtsPreviewBlock";

const DEBUG_COMPOSED_SUBMISSION_NOTES = Core.debug('DEBUG_COMPOSED_SUBMISSION_NOTES');

const CONFIG = {
  name: 'candidates__resume_submission__candidate_resume_submission',
  warnings: {
    noCandidatePermittedJob: 'Warning - candidate is not permitted for this job'
  }
}

const COPIED_ATTRS = [
  "firstName",
  "lastName",
  "email",
  "phone",
  "linkedInURL",
  "gitHubURL",
];

class CandidateResumeSubmission extends Component {

  confirm = false;

  constructor() {
    super(...arguments);

    this.state = {
      ...getCandidateModel({ extended: true }),
      includeSubmissionNotes: false,
      includeSubmissionNotesToEmployer: true,
      includeCandidateTagLine: true,
      /** controller states */
      snackBarMessage: "",
      snackBarOpen: false,
      sending: false,
      openCandidateView: true,
      openJobView: true
    };
    Store.set("path", getLocation());

    /**
     * @todo
     * Look for improvements
     * 2021-05-17 µ
     */
    Core.setKeyValue('CandidateResumeSubmissionController', this);

    const { candidateId, jobId } = getParams({ pattern: '/resume-submission/:candidateId/:jobId' });

    setTimeout((st) => {
      /** LOAD CANDIDATE DATA to the current state */
      if (candidateId) {
        Candidate.get(candidateId, candidate => {
          Candidate.getPotentialDuplicatedWithOwnerships(
            {
              id: candidate.id
            }
          ).then(
            (result) => {
              // const dupOwnership = result.find(cando=> cando.isPotentialStrongConflict && cando.)
              this.setState({ ...candidate }, (then) => {
                /** @todo I can't find where "SubmissionNotes" reference is set */
                this.SubmissionNotes && this.SubmissionNotes.setValue(candidate.submissionNotes);
                if (!!this.state.resumes.length) {
                  this.setState({
                    selectedResumeRow: 0,
                    selectedResume: this.state.resumes[0],
                  });
                }
                if (!!candidate.engagements.length) {
                  this.candidateEngagementsProcessing(jobId);
                }
              });
            }
          );
        });
      }
    });
  }
  candidateEngagementsProcessing = async (selectedJobId, cb = () => { }) => {
    const { state: candidate } = this;
    const model = candidate;
    let selectedJob = null;
    let jobs = [];
    let alertContent = '';

    const { mixins: jobEnvelope } = await TemplateLib.getEnvelope({
      messageTypes: [EMP_MSG_TYPE__SUBMISSION_ID],
      jobId: selectedJobId
    });

    let globalQuestions = Arr(await fetchQuestionFromBank());

    model.engagements.forEach((engagement) => {
      if (
        (engagement.state === ENGAGEMENT__STATE_OPEN) &&
        (engagement.stage === STAGE_SUBMISSION) &&
        new RegExp(STATUS_W_10X10, 'i').test(Obj(engagement).status)
      ) {
        // Jira VER-194 Actions after submission email
        engagement.job.engagement = mapEngagement(engagement);
        jobs.push(engagement.job);
        if (selectedJobId === engagement.job.id) {
          selectedJob = engagement.job;
        }

        const formatPrivateNotes = (selectedJob) => {
          if (!selectedJob) { return ""; }
          return (`
            <p><strong>Employer:</strong></p>
            <p>${Object(selectedJob.employer).internalNotes}</p>
            <p><strong>Job:</strong></p>
            <p>${selectedJob.privateNotes}</p>
            <p><strong>Insider Scoop:</strong></p>
            <p>${selectedJob.qualificationNotes}</p>
          `).trim();
        };

        let jobsPermittedAnswersDic = formatHtmlJobsPermittedAnswers({
          globalQuestions,
          candidate: model,
          job: selectedJob
        });

        this.setState(
          {
            [`jobSubmissionNotes_${engagement.job.id}_read_only`]: jobsPermittedAnswersDic.readOnly,
            [`jobSubmissionNotes-${engagement.job.id}`]: jobsPermittedAnswersDic.editable,
            [`jobSubmissionNotesAlerts-${engagement.job.id}`]: alertContent,
            [`includeJobSubmissionNotes-${engagement.job.id}`]: true,
            [`employerPrivateNotes-${engagement.job.id}`]: formatPrivateNotes(
              selectedJob
            ),
            jobId: selectedJobId,
            selectedJob,
            jobEnvelope,
            jobs,
          },
          () => {
            this.setEmailPreviewState();
            cb();
          }
        );
      }
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      (
        (!!prevState.schoolsLinkedIn.length || !!prevState.undergraduateSchool.length) &&
        !prevState.ranking
      ) ||
      (
        (!!this.state.schoolsLinkedIn.length || !!this.state.undergraduateSchool.length) &&
        !this.state.ranking
      )
    ) {
      const { schoolsLinkedIn, undergraduateSchool } = this.state;
      const schoolsArray = !!schoolsLinkedIn.length
        ? schoolsLinkedIn.map((school) => school.school)
        : [undergraduateSchool];
      School.get(
        {
          include: {
            relation: "schoolRating",
            scope: {
              fields: [
                "degree",
                "rating",
                "positiveSignalsTags",
                "negativeSignalsTags",
              ],
            },
          },
          where: {
            or: [
              { universityName: { inq: schoolsArray } },
              { alternativeNames: { inq: schoolsArray } },
            ],
          },
        },
        (response) => {
          this.setState({ ranking: response });
        }
      );
    }
  }


  getJobsFirstRole = () => {
    const { selectedJob } = this.state;

    let firstJobRole = "";
    if (!!selectedJob && !!selectedJob._roles)
      firstJobRole = selectedJob._roles.split(",")[0];

    return firstJobRole;
  };

  /**
   * @param {*} params
   * @param {boolean} params.toIncludeInCompose
   * @returns {string} Subject prefix
   */
  getEmailSubjectPrefix = ({ toIncludeInCompose = false } = {}) => {
    const A_PLUS_ID = (Definition.getId("platformRating", "A+") || 5);
    const A_TOP_ID = (Definition.getId("platformRating", "A - Top") || 1);
    const { state: candidate } = this;
    const { platformRating } = candidate;
    return (
      (platformRating === A_PLUS_ID)
        ? "HIGH PRIORITY CANDIDATE"
        : (platformRating === A_TOP_ID)
          ? "PRIORITY CANDIDATE"
          : toIncludeInCompose ? '' : "CANDIDATE"
    );
  }

  getComposedSubmissionNotes = () => {
    let output = cleanHtml(this.state.emailPreview || '');
    output = output.replace(/<p><\/p>|<p><br *\/><\/p>/gi, '');
    // console.debug('getComposedSubmissionNotes', output);
    return output;
  };

  getReceiverNames = () => {

    const selectedJob = this.state.selectedJob;

    let toNames = [];
    let ccNames = [];

    if (!!selectedJob) {
      const { jobEnvelope: { to = [], cc = [] } = { to: [], cc: [] } } = this.state;
      toNames = to.map(({ name }) => name);
      ccNames = cc.map(({ name }) => name);
    }

    let allNames = [...toNames, ...ccNames];
    allNames.forEach((item, index, arr) => {
      arr[index] = item.split(" ")[0];
    });
    let receiverNames = allNames.join(", ");

    return { receiverNames };

  };

  async setEmailPreviewState() {
    const selectedJob = this.state.selectedJob;
    if (selectedJob) {
      const hasResume = !!this.state.resumes.length;
      const employer = selectedJob.employer;
      let { receiverNames } = await this.getReceiverNames();
      const { state: candidate } = this;

      console.debug('S', this.state, candidate);
      const context = {
        _subjectPrefix: this.getEmailSubjectPrefix({ toIncludeInCompose: true }),
        TAG_LINE: this.state.includeCandidateTagLine && trim(this.state.tagLine),
        receiverNames,
        companiesString: `<ul>${this.state._companiesString
          .split(",")
          .map((el) => `<li>${el}</li>`)
          .join("")}</ul>`,
        includeSubmissionNotes: this.state.includeSubmissionNotes,
        SUBMISSION_NOTES_TO_EMPLOYER: (
          !!this.state.includeSubmissionNotesToEmployer &&
          trim(trim(this.state.submissionNotesToEmployer).replace(/<p><br><\/p>/gi, ''))
        ),
        job: {
          ...selectedJob,
        },
        candidate,
        hasResume,
        isEmployerAts: /ats/i.test(selectedJob.employer._submissionMethod),
        employer: {
          ...employer,
        },
        JOB_SUBMISSION_NOTES: (
          !!this.state["includeJobSubmissionNotes-" + selectedJob.id] &&
          trim(trim(this.state["jobSubmissionNotes-" + selectedJob.id]).replace(/<p><br><\/p>/gi, ''))
        ),
      };
      const compileTemplate = () => {
        return new Promise(resolve => {
          const outputHtml = compile(TEMPLATE__CANDIDATE_SUBMISSION__SUBMISSION_NOTES)(context);
          // console.debug('compileTemplate', outputHtml);
          this.setState(
            { emailPreview: outputHtml },
            () => setTimeout(resolve)
          );
        });
      }
      await compileTemplate();
      console.debug('setEmailPreviewState:A3');
      getCandidateNotes({
        candidate,
        job: selectedJob,
        onLoad: async CANDIDATE_NOTES => {
          Object.assign(context, { CANDIDATE_NOTES });
          console.debug('context', context);
          await compileTemplate();
        },
        refresh: true,
      });
    }
    else {
      this.setState(
        {
          emailPreview: "<html><head></head><body></body></html>",
        }
      );
    }
  };

  stringCapitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  openEditEmployer = (ev) => {
    Core.dialog.open({
      title: <>Edit Employer</>,
      message: (
        <iframe
          className="covered"
          title="Edit Employer"
          style={{ height: "70vh", minHeight: "375px" }}
          src={`/#/employer/edit/${this.state.selectedJob.employerId}`}
        />
      ),
      actions: [
        <Button
          variant='contained'
          color='secondary'
          className='min-w-160'
          onClick={(ev) => reloadLocation()}
        >
          Done
        </Button>,
      ],
      paperStyle: {
        width: "70vw",
        minWidth: "320px",
        maxWidth: "none",
      },
    });
  };

  openSuccessDialog = (engagement) => {
    const selectedJob = this.state.selectedJob;
    const actions = [];
    const nextJob = (this.state.jobs || []).find(
      (job) => job.id !== selectedJob.id
    );
    if (nextJob) {
      Core.isAdmin({ action: ACCOUNT_ACTION__VIEW_ENGAGEMENT }) && (
        actions.push(
          <Button outlined minW120
            title="Go to Engagement view"
            className='min-w-160'
            onClick={event => {
              Core.dialog.close();
              setLocation(`/#/engagement/view/${engagement.id}`);
            }}
          >
            Engagement
          </Button>
        )
      );

      /** @note Removed "Next job" button. */

      actions.push(
        <Button primary minW120
          title={
            <Box>
              Open represent email<br />
              <Typography sub italic className='c-yellow'>
                You will be redirected to the next job after sending the email
              </Typography>
            </Box>
          }
          className="min-w-160"
          onClick={(ev) => {
            Core.dialog.close();
            setLocation(
              `/engagement/represent/${engagement.id}/${nextJob.id}`
            );
            reloadLocation();
          }}
        >
          Done
        </Button>
      );
    }
    else {
      actions.push(
        <Button primary minW120
          title="Open represent email"
          className="min-w-160"
          onClick={(ev) => {
            Core.dialog.close();
            setLocation(
              Core.isAdmin({ action: ACCOUNT_ACTION__VIEW_ENGAGEMENT })
                ? `/engagement/represent/${engagement.id}`
                : '/'
            );
            reloadLocation();
          }}
        >
          Done
        </Button>
      );
    }
    Core.dialog.open({
      title: <>&nbsp;</>,
      message: (
        <div className="align-center">
          <i
            className="material-icons"
            style={{
              display: "block",
              color: colors.purple.common,
              fontSize: 72,
            }}
          >
            check_circle
          </i>
          <span
            style={{
              display: "block",
              color: "rgba(0,0,0,0.84)",
              fontSize: "16px",
              fontWeight: 500,
              lineHeight: "24px",
              textAlign: "center",
              height: "100px",
              padding: "24px",
            }}
          >
            Successful Submission
          </span>
        </div>
      ),
      actions,
      paperStyle: { width: 640 },
    });
  };

  /**
   * Updates Engagement
   * - stage - To "{{STAGE_REVIEW}}".
   * - status - To "{{STATUS_W_EMPLOYER_FEEDBACK}}".
   * - submitted - Today's iso string.
   * - greenhouseAtsApplicationId - Argument.
   *
   * @param {object} options
   * @param {number} options.submissionMethodId
   * @param {number} options.greenhouseAtsApplicationId
   * @returns
   */
  transitionAfterSubmission = ({
    submissionMethodId,
    greenhouseAtsApplicationId,
    atsContext
  } = {}) => {
    const { state } = this;
    let selectedJob = state.selectedJob || {};
    const { submissionMethods = [] } = selectedJob;
    const engagement = selectedJob.engagement || {};
    if (submissionMethodId && (submissionMethods.length >= 2)) {
      Core.showWarning('Skip auto transition since there are multiple job submissions methods selected');
      return Engagement.update(engagement, { submissionNote: this.getComposedSubmissionNotes() });
    }
    if (engagement.id) {
      const date = new Date();
      const update = {
        stage: STAGE_REVIEW,
        status: STATUS_W_EMPLOYER_FEEDBACK,
        submitted: date.toISOString(),
        submissionNote: this.getComposedSubmissionNotes()
      };
      if (greenhouseAtsApplicationId) {
        update.greenhouseAtsApplicationId = greenhouseAtsApplicationId;
      }
      if (atsContext) {
        update.atsContext = deepMerge(Obj(engagement.atsContext), atsContext);
      }
      return Engagement.update(engagement, update, this.openSuccessDialog);
    }
  };

  getParams = (em) => {
    const params = {};

    /** @todo to review, not in use | 2021-09-09 Thu µ */
    // const deliveryTo = [];
    // const deliveryCc = [];
    // const deliveryBcc = [Core.getResumeSubmissionBcc()];

    const selectedJob = this.state.selectedJob;

    /** µ FOR DEBUG PURPOSES * /
    console.debug('µ:CandidateResumeSubmission.getParams', { selectedJob });
    console.debug('µ:CandidateResumeSubmission.getParams', { recruiterEmailsList });
    /** */

    if (selectedJob) {

      const { jobEnvelope: { to = [], cc = [], bcc = [] } = { to: [], cc: [], bcc: [] } } = this.state;

      params.from = Core.getResumeSubmissionFromEmail();
      params.to = to;
      params.cc = cc;
      params.bcc = bcc;
      params.subject = compile(TEMPLATE__CANDIDATE_SUBMISSION__EMAIL_SUBJECT)({
        SUBJECT_PREFIX: this.getEmailSubjectPrefix(),
        CANDIDATE_FULLNAME: this.state._name,
        EMPLOYER_NAME: selectedJob.employer.name,
        JOB_TITLE: selectedJob.jobTitle
      });
      params.html = this.state.emailPreview;
      if (!!this.state.selectedResume) {
        const filenameArray = this.state.selectedResume.url.split("/");
        const filename = filenameArray[filenameArray.length - 1];
        const path = this.state.selectedResume.url;
        var attachments = [
          {
            filename: filename,
            path: path,
          },
        ];
        params["attachments"] = attachments;
      }
    }

    /** µ FOR DEBUG PURPOSES * /
    console.debug('µ:CandidateResumeSubmission.getParams', { params });
    /** */

    return params;

  };

  renderSubmissionMethodText = () => {
    if (!this.state.selectedJob) { return ''; }
    let employer = this.state.selectedJob.employer;
    let label = compile('{{EMPLOYER__NAME}} requires candidate submission via: {{SUBMISSION_METHODS}}')({
      EMPLOYER__NAME: employer.name,
      SUBMISSION_METHODS: join(
        this.state.selectedJob.submissionMethods.map(tagId => {
          let label = Definition.getLabel(
            DEFINITION_CATEGORY__EMPLOYER_SUBMISSION_METHOD, tagId
          );
          if (tagId === EMPLOYER_SUBMISSION_METHOD__ATS) {
            label += `(${Definition.getLabel(
              DEFINITION_CATEGORY__ATS_TYPES,
              employer.atsTypeId
            )})`;
          }
          return label;
        }
        ))
    });
    return (
      <WarningChipMessage className='f-sm my-1'>
        {label}
      </WarningChipMessage>
    );
  };

  render() {
    /** CandidateResumeSubmission state */
    const { state, state: candidate } = this;

    /** state *variables* */
    const {
      selectedJob,
      openCandidateView,
      openJobView,
    } = state;

    const renderParams = this.getParams();

    const {
      /* Subject: */
      subject: emailSubject,
      to,
      cc,
    } = renderParams;

    if (Core.isLoggedOut()) {
      return <Navigate to="/login" />;
    }
    if (Core.isRecruiter()) {
      return <Navigate to="/" />;
    }
    /** CANCEL BUTTON */
    const cancel = (event) => {
      Core.goBack(this.props);
    };
    /** SEND BUTTON */
    const send = async (event) => {
      this.setState({ sending: true });
      if (this.state.jobId) {
        const selectedJob = this.state.selectedJob;
        const params = {
          ...this.getParams(),
          source: "Resume Submission line 817",
        };

        const doSend = async (em) => {
          const engagement = selectedJob.engagement;
          if (!engagement.boxKey) { console.debug("It is missing the engagement box key"); }
          let contacts = combineEmailsLists(
            combineEmailsLists(
              params.to,
              params.cc
            ),
            params.bcc
          );
          params.to = '';
          params.cc = '';
          params.bcc = '';
          params.boxKey = engagement.boxKey;
          params.html = compile(TEMPLATE__CANDIDATE_SUBMISSION__EMAIL_BODY)({
            INTRODUCTION: compile(TEMPLATE__CANDIDATE_SUBMISSION__EMAIL_BODY_INTRODUCTION)({
              CANDIDATE__FULLNAME: engagement.candidate._name,
            }),
            SUBMISSION_NOTES: params.html,
            CLOSING: compile(TEMPLATE__CANDIDATE_SUBMISSION__EMAIL_BODY_CLOSING)({
              SIGNATURE: Core.getEmailSignature()
            })
          });
          if (!contacts.length) { Core.showError(`Missing contacts`); }
          let error = '';
          let responses = [];
          const setError = e => error = e;
          for (let index = 0; index < contacts.length; index++) {
            let contact = contacts[index];
            let _params = { ...params };
            _params.to = mapContactsToStrings([contact]);
            if (!!_params.to.length) {
              if (contact.accountId) {
                let employerPendingsURL = await getEmployerPendingsURL({ accountId: contact.accountId });
                let greenButtonHTMLString = compile(TEMPLATE__COMMON__GREEN_BUTTON)({
                  URL: setURLQueryString({
                    url: employerPendingsURL,
                    update: { engagementId: engagement.id },
                    exact: false
                  }),
                  NAME: 'View Profile',
                  SUBTITLE: `Link expires in ${DEFAULT_EXPIRATION}.`
                });
                _params.html = cleanHtml(`${greenButtonHTMLString}${_params.html}`);
              }
              // console.debug('SEND', _params);
              Core.showWarning(
                <>
                  Sending {index + 1} of {contacts.length} emails, wait until process done...&nbsp;
                  {LoadingImage}
                </>
              );
              await sendSafeEmail(_params).then((response) => {
                if (!!response?.threadId) {
                  responses.push(response);
                }
              }).catch(setError);
            }
          }
          Core.hideMessage();
          if (contacts.length === responses.length) {
            await this.transitionAfterSubmission({
              submissionMethodId: EMPLOYER_SUBMISSION_METHOD__EMAIL
            });
          }
          else {
            Core.showError(error || 'Unexpected error');
          }
          this.setState({
            sending: false,
            sentSuccessfully: contacts.length === responses.length
          });
        };
        if (params.attachments) {
          const attachments = [...params.attachments];
          params.attachments = [];
          const next = (em) => {
            if (!!attachments.length) {
              const attachment = attachments.pop();
              getBinary({
                url: attachment.path,
                onSuccess: (binary) => {
                  params.attachments.push({ ...attachment, ...binary });
                  next();
                },
                onError: (error) => {
                  Core.showError('Fail to get the resume');
                  next();
                },
              });
            } else {
              doSend();
            }
          };
          next();
        } else {
          doSend();
        }
      }
    };

    /**
     * COPY BUTTON
     */
    const copy = (event) => {
      copyHtml(
        compile(TEMPLATE__CANDIDATE_SUBMISSION__EMAIL_BODY)({
          INTRODUCTION: compile(TEMPLATE__CANDIDATE_SUBMISSION__EMAIL_BODY_INTRODUCTION)({
            CANDIDATE__FULLNAME: this.state._name,
          }),
          SUBMISSION_NOTES: this.state.emailPreview,
          CLOSING: compile(TEMPLATE__CANDIDATE_SUBMISSION__EMAIL_BODY_CLOSING)({
            SIGNATURE: Core.getEmailSignature()
          }),
        }))
        .then((em) => {
          Core.log("Copy email command was successful");
          Core.showMessage("Copied!");
        })
        .catch((ex) => {
          Core.log("Oops, unable to copy");
          Core.showMessage("Fail copy!");
        });
    };

    const copyAts = async (prepend) => {
      DEBUG_COMPOSED_SUBMISSION_NOTES && this.getComposedSubmissionNotes();
      prepend = validateString(prepend, true);
      const result = await copyText(
        `${!!prepend ? `${prepend}\n` : ''}${composeCopyText(this.state.emailPreview)}`
      );
      console.debug('µ:onClickCopy');
      console.debug(result?.text);
      if (!!result) {
        Core.log("Copy Ats command was successful");
        Core.showMessage('Copied ATS!');
      }
      else {
        Core.log("Oops, unable to copy");
        Core.showMessage('Fail copy!');
      }
    }

    const copyAttribute = (attribute) => (event) => {
      copyHtml(this.state[attribute])
        .then((em) => {
          Core.showMessage("Copied!");
        })
        .catch((ex) => {
          Core.showMessage("Fail copy!");
        });
    };

    const jobsCount =
      !!this.state.jobs && !!this.state.jobs.length
        ? ` (1/${this.state.jobs.length} jobs)`
        : "";

    const rawSubmissionNotes = (
      selectedJob?.id
        ? composeCopyText(this.state.emailPreview)
        : ''
    );

    window.getComposedSubmissionNotes = this.getComposedSubmissionNotes;

    const _employerJobElement = (
      !!this.state.selectedJob && (
        <>
          {(!Core.isAdmin() || Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_JOB })) && (
            <NavLink
              aria-label="Edit Job"
              className='hint--left'
              to={"/job/edit/" + this.state.selectedJob.id}
            >
              {this.state.selectedJob.employer.name + " - " + this.state.selectedJob.jobTitle}
            </NavLink>
          )}
          {Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_JOB }) && Core.isAdmin({ action: ACCOUNT_ACTION__MATCH_CANDIDATE }) && (
            <>
              &nbsp;|&nbsp;
            </>
          )}
          {Core.isAdmin({ action: ACCOUNT_ACTION__MATCH_CANDIDATE }) && (
            <NavLink
              to={`/candidate/match/${this.state.id}?selectedJob=${this.state.selectedJob.id}`}
            >
              Go to Match page
            </NavLink>
          )}
        </>
      )
    );

    // console.debug('CandidateResumeSubmission.state', this.state);

    return (
      <Page paper
        {...this.props}
        title='Resume Submission'
        contentRight={
          <>
            <CandidateSummaryStickyNote
              candidate={candidate}
              reference={self => CandidateResumeSubmission.StickyNote = self}
            />
            {
              !!selectedJob &&
              !!this.state.resumeTxtUrl &&
              <>
                <Button
                  color="primary"
                  onClick={(event) => {
                    this.matchDrawer.handleOpen()
                  }}
                >
                  Text CV/CV
                </Button>
                <MatchDrawer
                  Controller={this}
                  content={
                    <DoubleContentDrawer
                      job={selectedJob}
                      candidate={this.state}
                    />
                  }
                />
              </>
            }
          </>
        }
      >

        {/** PAPER HEADER LINE */}
        <Box className='pt-05'>
          <Fieldset
            title='For Candidate:'
            nextToTitle={
              <NavLink
                title="Edit Candidate"
                to={"/candidate/edit/" + this.state.id}
              >
                {this.state.firstName}
                &nbsp;
                {this.state.lastName}
              </NavLink>
            }
            actionBar={(
              <IconButton
                size='small'
                className="ml-auto hint--left hint--rounded"
                aria-label={openCandidateView ? "Expand Less" : "Expand More"}
                onClick={event => this.setState(({ openCandidateView }) => ({
                  openCandidateView: !openCandidateView
                }))}
              >
                {openCandidateView
                  ? <i className='material-icons'>expand_less</i>
                  : <i className='material-icons'>expand_more</i>
                }
              </IconButton>
            )}
          />
        </Box>

        <Collapse in={openCandidateView} timeout="auto" style={{ minHeight: 'unset' }}>

          <Fieldset
            title={CANDIDATE_UI.submissionNotes.title}
          >
            <Html
              name="submissionNotes"
              value={this.state.submissionNotes}
              className='dotted rounded p-2 opacity-50'
            />
          </Fieldset>

          <Fieldset
            title={CANDIDATE_UI.noteToAccountManager.title}
          >
            <Html
              name="noteToAccountManager"
              value={this.state.noteToAccountManager}
              className='dotted rounded p-2 opacity-50'
            />
          </Fieldset>

          <Fieldset
            title='Candidate Private Note'
          >
            <Html
              name="candidatePrivateNotes"
              value={this.state.privateNotes}
              className='dotted rounded p-2 opacity-50'
            />
          </Fieldset>

          <Fieldset
            title={CANDIDATE_UI.tagLine.title}
            actionBar={
              <div className='d-flex flex-align-right-center'>
                <Checkbox
                  checked={this.state.includeCandidateTagLine}
                  onChange={(event) => {
                    this.setState(
                      { includeCandidateTagLine: event.target.checked },
                      (then) => {
                        this.setEmailPreviewState();
                      }
                    );
                  }}
                  checkboxProps={{ size: 'small' }}
                >
                  Include in email
                </Checkbox>
              </div>
            }
          >
            <RichTextBox
              name="tagLine"
              value={this.state.tagLine}
              onChange={tagLine => {
                this.setState({ tagLine }, (then) => {
                  this.setEmailPreviewState();
                });
              }}
            />
          </Fieldset>

          <Fieldset
            title={CANDIDATE_UI.submissionNotesToEmployer.title}
            actionBar={
              <div className='d-flex flex-align-right-center'>
                <Checkbox
                  checked={this.state.includeSubmissionNotesToEmployer}
                  onChange={(event) => {
                    this.setState(
                      { includeSubmissionNotesToEmployer: event.target.checked },
                      (then) => {
                        this.setEmailPreviewState();
                      }
                    );
                  }}
                  checkboxProps={{ size: 'small' }}
                >
                  Include in email
                </Checkbox>
              </div>
            }
          >
            <RichTextBox
              name="submissionNotesToEmployer"
              value={this.state.submissionNotesToEmployer}
              onChange={submissionNotesToEmployer => {
                this.setState({ submissionNotesToEmployer }, (then) => {
                  this.setEmailPreviewState();
                });
              }}
            />
          </Fieldset>

        </Collapse>

        <Divider className={openCandidateView ? 'mt-3' : ''} />

        <Fieldset column
          title='Select Job to Submit:'
          nextToTitle={
            <Typography>
              {_employerJobElement}
            </Typography>
          }
          actionBar={selectedJob && (
            <>
              {Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_EMPLOYER }) && (
                <IconButton
                  size='small'
                  color='primary'
                  aria-label="Edit Employer"
                  className='hint--left'
                  onClick={this.openEditEmployer}
                >
                  <i className="material-icons c-inherit">edit</i>
                </IconButton>
              )}
              <IconButton
                size='small'
                className="ml-auto hint--left hint--rounded"
                aria-label={openJobView ? "Expand Less" : "Expand More"}
                onClick={event => this.setState(({ openJobView }) => ({
                  openJobView: !openJobView
                }))}
              >
                {openJobView
                  ? <i className='material-icons'>expand_less</i>
                  : <i className='material-icons'>expand_more</i>
                }
              </IconButton>
            </>
          )}
        >
          <WarningMessage show={!candidate.jobsPermitted.includes(this.state.selectedJob?.id)} className='mx-0'>
            {CONFIG.warnings.noCandidatePermittedJob}
          </WarningMessage>
          <WarningMessage show={!renderParams.to} className='mx-0'>
            The contact information is missing, click to edit the
            Employer
          </WarningMessage>
          <Menu dropdown
            name="jobs"
            value={this.state.jobId}
            onChange={(jobId) => {
              var selectedJob = (this.state.jobs || []).find(
                (item) => item.id === jobId
              );
              this.setState({ jobId, selectedJob }, (then) => {
                this.setEmailPreviewState();
                this.candidateEngagementsProcessing(jobId);
              });
            }}
            paperStyle={{ maxHeight: 300 }}
            options={
              Arr(this.state.jobs).map(
                (job) => ({
                  ...job,
                  label: `${Obj(job.employer).name || '[employer]'} - ${job.jobTitle || '[job_title]'}`
                })
              )
            }
          />
        </Fieldset>

        <Collapse in={openJobView} timeout="auto" style={{ minHeight: 'unset' }}>

          {selectedJob && (this.state.jobs || [])
            .filter(({ id }) => id === selectedJob.id)
            .map(job => {
              let key = `jobSubmissionNotes-${job.id}`;
              let includeKey = `includeJobSubmissionNotes-${job.id}`;
              return (
                <Fieldset column
                  key={key}
                  title={<>Employer Required Submission Information</>}
                  actionBar={
                    <div className='d-flex flex-align-right-center nowrap'>
                      <IconButton
                        size='small'
                        color={'primary'}
                        onClick={event => AnswerJobsQuestions[CONFIG.name].open()}
                        disabled={!candidate.jobsPermitted.includes(job.id)}
                        aria-label='Edit answers'
                        className='mr-1 hint--left'
                      >
                        <i className='material-icons c-inherit'>edit</i>
                      </IconButton>
                      <AnswerJobsQuestions
                        name={`${CONFIG.name}__answer_jobs_questions`}
                        reference={self => (AnswerJobsQuestions[CONFIG.name] = self)}
                        candidate={candidate}
                        jobIds={[job.id]}
                        onAcceptLabel='Save'
                        onAccept={({ jobsPermittedAnswers }) => async (event) => {
                          console.debug('onAccept', jobsPermittedAnswers);
                          this.setState({ jobsPermittedAnswers }, (then) => {
                            Candidate.update(
                              candidate.id,
                              { jobsPermittedAnswers },
                              (response) => Core.showSuccess(
                                'Saved Successfully!',
                                (then) => { reloadLocation(); }
                              )
                            );
                          });
                        }}
                      />
                      <Checkbox
                        checked={!!this.state[includeKey]}
                        onChange={(event) => {
                          this.setState(
                            { [includeKey]: event.target.checked },
                            (then) => {
                              this.setEmailPreviewState();
                            }
                          );
                        }}
                        checkboxProps={{ size: 'small' }}
                      >
                        Include in email
                      </Checkbox>
                    </div>
                  }
                  className='mt-3'
                >
                  {!!this.state[`jobSubmissionNotes_${job.id}_read_only`]?.length && (
                    <WarningMessage className='mx-0'>
                      <div dangerouslySetInnerHTML={{
                        __html: this.state[`jobSubmissionNotes_${job.id}_read_only`]
                      }} />
                    </WarningMessage>
                  )}
                  <RichTextBox
                    name={key}
                    value={this.state[key]}
                    onChange={jobSubmissionNotes => {
                      this.setState(
                        { [key]: jobSubmissionNotes },
                        (then) => {
                          this.setEmailPreviewState();
                        }
                      );
                    }}
                  />
                </Fieldset>
              );
            })}

          {!!selectedJob && (
            <Fieldset
              title='Employer Private Notes'
            >
              <Html
                name="employerPrivateNotes"
                value={this.state[`employerPrivateNotes-${selectedJob.id}`]}
                className='dotted rounded p-2 opacity-50'
              />
            </Fieldset>
          )}

        </Collapse>

        <Divider className={openJobView ? 'mt-3' : ''} />

        {selectedJob && (
          <>

            <Row className='mt-3' >
              <Col className="d-flex flex-align-left-center" fullWidth>
                <label className="m-0" style={{ width: '4rem' }}>Subject:</label>
                <span className="ml-1">
                  {emailSubject}
                </span>
              </Col>
            </Row>

            <Row className="mt-1">
              <Col className="d-flex align-left" fullWidth>
                <label className="m-0" style={{ width: '4rem' }}>To:</label>
                <span className="ml-1">
                  {to.map(({ email }) => (
                    <div key={`delivery-email-to-${email}`}>{email}</div>
                  ))}
                </span>
              </Col>
            </Row>

            <Row className="mt-1">
              <Col className="d-flex align-left" fullWidth>
                <label className="m-0" style={{ width: '4rem' }}>Cc:</label>
                <span className="ml-1">
                  {/** @todo */}
                  {cc.map(({ email }) => (
                    <div key={`delivery-email-cc-${email}`}>{email}</div>
                  ))}
                </span>
              </Col>
            </Row>

          </>
        )}

        <Row>
          <Col fullWidth>
            <div
              className='c-black-medium rounded f-sm p-1'
              dangerouslySetInnerHTML={{
                __html: compile(TEMPLATE__CANDIDATE_SUBMISSION__EMAIL_BODY_INTRODUCTION)({
                  CANDIDATE__FULLNAME: this.state._name,
                })
              }}
            />
            <RichTextBox
              name="emailPreview"
              value={this.state.emailPreview}
              onChange={emailPreview => {
                this.setState({ emailPreview });
              }}
            />
            <div
              className='c-black-medium rounded italic f-sm p-1'
              dangerouslySetInnerHTML={{
                __html: compile(TEMPLATE__CANDIDATE_SUBMISSION__EMAIL_BODY_CLOSING)({
                  SIGNATURE: Core.getEmailSignature()
                })
              }}
            />
          </Col>
        </Row>

        <Row className='mt-3'>
          <InternalSubmissionInstructions
            title={
              <>
                Employer:
                &nbsp;
                {EmployerUI.internalSubmissionInstructions.title}
              </>
            }
            entity={Obj(Obj(selectedJob).employer)}
          />
        </Row>

        <Row className='mt-3'>
          <InternalSubmissionInstructions
            title={
              <>
                Job:
                &nbsp;
                {EmployerUI.internalSubmissionInstructions.title}
              </>
            }
            entity={Obj(selectedJob)}
          />
        </Row>

        <Fieldset
          title={
            <>
              <span className='mr-1'>Job:</span>
              {_employerJobElement}
            </>
          }
        />

        <Row className='mt-3'>
          <Col style={{ textAlign: "right" }} fullWidth>
            {COPIED_ATTRS.filter((el) => !!this.state[el]).map((attr, index) => {
              return (
                <Fragment key={attr}>
                  <Link
                    component="button"
                    variant="body2"
                    onClick={copyAttribute(attr)}
                  >
                    {`Copy ${attr}`}
                  </Link>{" | "}
                </Fragment>
              );
            })}
          </Col>
        </Row>

        {selectedJob && (
          <Row className='mt-3'>
            <Col style={{ textAlign: "right" }} fullWidth>
              {this.renderSubmissionMethodText()}
              <Button
                variant='contained'
                color='secondary'
                size='small'
                onClick={copy}
                className='mr-1'
              >
                Copy Email Content
              </Button>
              <Button
                variant='contained'
                color='secondary'
                size='small'
                onClick={copyAts}
                disabled={(
                  this.state.sending ||
                  !selectedJob.submissionMethods.includes(EMPLOYER_SUBMISSION_METHOD__ATS)
                )}
              >
                Copy For ATS ({Definition.getLabel(
                  DEFINITION_CATEGORY__ATS_TYPES,
                  Obj(selectedJob.employer).atsTypeId
                ) || ''})
              </Button>
            </Col>
          </Row>
        )}

        <Row className='mt-3'>
          <Col fullWidth>
            <label>Resumes</label>
            {!!this.state.resumes.length ? (
              <Table>
                <Table.Body>
                  {!!this.state.resumes.length &&
                    this.state.resumes.map((resume, index) => (
                      <Table.Row key={`resume_submission__resumes__${index}`}>
                        <Table.Cell style={{ width: 60 }}>
                          <Checkbox
                            checked={(this.state.selectedResumeRow === index)}
                            onChange={(event) => {
                              const selectedResumeRow = !!event.target.checked ? index : null;
                              const update = {
                                selectedResumeRow,
                                selectedResume: this.state.resumes[selectedResumeRow]
                              };
                              this.setState(update);
                            }}
                          />
                        </Table.Cell>
                        <Table.Cell
                          className="v-aligns-middle"
                          style={{ paddingLeft: 0, paddingRight: 0 }}
                        >
                          <IconButton
                            onClick={(ev) =>
                              downloadFile({
                                url: resume.url,
                                mimeType: "application/pdf",
                                onError: (error) => {
                                  this.setState({
                                    snackbar: error,
                                  });
                                },
                              })
                            }
                          >
                            <i className="material-icons">file_download</i>
                          </IconButton>
                          <a href={formatURL(resume.url)} target="_blank" rel="noreferrer">
                            {decodeURIComponent(
                              String(resume.url).split("/").pop()
                            )}
                          </a>
                        </Table.Cell>
                        <Table.Cell
                          onClick={this.handleToggle}
                          style={{ paddingLeft: 0, paddingRight: 0 }}
                        >
                          {moment(resume.createAt).format("LLLL")}
                        </Table.Cell>
                      </Table.Row>
                    ))}
                </Table.Body>
              </Table>
            ) : (
              "No resumes"
            )}
          </Col>
        </Row>

        {selectedJob && (
          <>
            <div className='mt-3' />
            <AtsPreviewBlock
              candidate={state}
              selectedJob={selectedJob}
              rawSubmissionNotes={rawSubmissionNotes}
              emailParams={renderParams}
              copyAts={copyAts}
            />
          </>
        )}

        <Row className='mt-3' style={{ padding: "16px 0" }}>
          <Col fullWidth className='d-flex'>
            <Button
              variant='outlined'
              color='error'
              onClick={cancel}
              className='mr-auto min-w-160'
            >
              CANCEL
            </Button>
            {selectedJob && (
              <>
                <Button
                  variant='contained'
                  color='secondary'
                  title={`Transition to review without send email.${jobsCount}`}
                  className='min-w-160 mr-1'
                  onClick={this.transitionAfterSubmission}
                  disabled={this.state.sending || !this.state.selectedJob}
                >
                  Stage transition only
                </Button>
                <Button
                  variant='contained'
                  color='secondary'
                  title={`Send Email, then transition to review.${jobsCount}`}
                  className='min-w-160'
                  onClick={send}
                  disabled={(
                    this.state.sentSuccessfully ||
                    this.state.sending ||
                    !selectedJob.submissionMethods.includes(EMPLOYER_SUBMISSION_METHOD__EMAIL) ||
                    !renderParams.to
                  )}
                >
                  {this.state.sending ? "SENDING..." : "Submit via Email"}
                </Button>
              </>
            )}
          </Col>
        </Row>

      </Page>
    );
  }
}

/* EXPORTS ==================================== */

export default CandidateResumeSubmission;

/* ============================================ */