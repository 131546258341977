import moment from "moment";
import React from "react";
import {
  STAGE_CONFIRMATION,
  STAGE_END,
  STAGE_GUARANTEE,
  STAGE_HIRE,
  STAGE_OFFER,
  STAGE_ONSITE,
  STAGE_REVIEW,
  STAGE_SCREEN,
  STAGE_SUBMISSION,
  STATUS_W_EMPLOYER_FEEDBACK
} from '../../../dictionaries/Engagement.dic';
import {
  JobUI
} from '../../../dictionaries/JobUI.dic';
import {
  Arr,
  join
} from '../../../lib/Array.lib';
import Core from "../../../lib/Core";
import Definition from "../../../lib/Definition";
import Engagement from "../../../lib/Engagement";
import {
  NOT
} from '../../../lib/GenericTools.lib';
import {
  removeHtmlBlankLines
} from '../../../lib/HTML.lib';
import {
  getJobModel
} from "../../../lib/models/job";
import {
  Obj
} from '../../../lib/Object.lib';
import {
  htmlHasTextContent,
  joinKeyName
} from '../../../lib/String.lib';
import cleanHtml from "../../../lib/tools/cleanHtml";
import formatMoney from '../../../lib/tools/formatMoney';
import formatURL from "../../../lib/tools/formatURL";
import {
  matchLocation
} from '../../../lib/URL.lib';
import {
  getURL
} from '../../../lib/URLQuery.lib';
import EmployerUI from '../../Employers/EmployerUI.dic';
import {
  copyJobLarge
} from "../../Home/3DotsMenu/moreMenuOptionsJobs";
import Box from '../../Layout/Wrappers/Box';
import Button from '../../Layout/Wrappers/Button';
import CardContent from "../../Layout/Wrappers/CardContent";
import Col from "../../Layout/Wrappers/Col";
import Divider from '../../Layout/Wrappers/Divider';
import ExpandableCard from '../../Layout/Wrappers/ExpandableCard';
import {
  Html
} from '../../Layout/Wrappers/Html';
import OnlyAdmin from '../../Layout/Wrappers/OnlyAdmin';
import Row from "../../Layout/Wrappers/Row";
import StyledTooltip from '../../Layout/Wrappers/StyledTooltip';
import JobFeedbacks, {
  evaluateEmployerFeedback
} from '../Edit/Forms/JobFeedbacks';
import LocationSection from "./LocationSection";

/** 
 * [2024-04-19][story_10174]
 * 
 * @note
 * The following job sections are previous implementations.
 * They don't prepend the JOB_SECTION_KEY to access their Key Pair in memory.
 * Kept for retro-compatibility.
 */

// This is same section as JOB_SECTION__QUALIFICATION_NOTES.
export const JOB_SECTION__INSIDER_SCOOP = 'insider-scoop';

// This is same section as JOB_SECTION__INTERVIEW_PROCESSES.
export const JOB_SECTION__INTERVIEW_PROCESS = 'interview-process';

// This is same section as JOB_SECTION__FEEDBACK_LOG.
export const JOB_SECTION__RESUME_FEEDBACK = 'resume_feedback';

// This is same section as JOB_SECTION__FEEDBACK_LOG.
export const JOB_SECTION__INTERVIEW_FEEDBACK = 'interview_feedback';


/** 
 * [2024-04-19][story_10174]
 * 
 * @note
 * The following key is used to build the key to access each job section Key Pair in memory.
 */
export const JOB_SECTION_KEY = 'job_details_section';

/** 
 * [2024-04-19][story_10174]
 * 
 * @note
 * Following job sections prepend the JOB_SECTION_KEY to access their Key Pair in memory.
*/
export const JOB_SECTION__SUMMARY = 'job.roles__job.level__employer.teamCount__job.salaryMax__job.salaryMin__job.salaryNote__job.hiringTarget__job.desiredEmploymentTypes__employer.url__employer.stage__employer.totalFunding__employer.foundingYear__employer.proxyName__job.state';
export const JOB_SECTION__EMPLOYER__TAG_LINE = 'employer.tagline';
export const JOB_SECTION__EMPLOYER__NAME = 'employer.name';
export const JOB_SECTION__EMPLOYER__TECHNICAL_SKILLS = 'employer.technicalSkills';
export const JOB_SECTION__TECHNICAL_SKILLS = 'job.technicalSkills';
export const JOB_SECTION__MUST_HAVE_QUESTIONS = 'employer.mustHaveQuestions__job.mustHaveQuestions';
export const JOB_SECTION__QUALIFICATION_NOTES = 'job.qualificationNotes';
export const JOB_SECTION__FEEDBACK_LOG = 'job.feedbackLogResume__job.feedbackLogInterview';
export const JOB_SECTION__DESCRIPTION = 'job.jobDescription';
export const JOB_SECTION__EMPLOYER__ADDITIONAL_INFO = 'employer.additionalInfo';
export const JOB_SECTION__INTERVIEW_PROCESSES = 'job.jobInterviewProcess__employer.interviewProcess';
export const JOB_SECTION__PUBLIC_NOTES = 'job.publicNotes__employer.publicNotes';
export const JOB_SECTION__EMPLOYER__PRODUCT = 'employer.product';
export const JOB_SECTION__EMPLOYER__WHY_US = 'employer.whyUs';
export const JOB_SECTION__EMPLOYER__TEAM_STRUCTURE = 'employer.teamStructure';
export const JOB_SECTION__COMPANY_INFO = 'employer.crunchbaseUrl__employer.additionalUrl__employer.notables__employer.perks';

/** ▼ ▼ ▼ METHODS ▼ ▼ ▼ */

/** */
export function getJobDetailsSectionKey(name) {
  return `${JOB_SECTION_KEY}__${name}`.toLowerCase();
}

export function openJobView({ jobId, sectionName }) {
  return async (event) => {
    try {
      if (jobId) {
        Core.openPopUp(Core.getPath(`job/view/${jobId}?${getJobDetailsSectionKey(sectionName)}`), 1200);
      }
    }
    catch (error) {
      Core.showError(error);
    }
  };
}


/** ▼ ▼ ▼ COMPONENTS ▼ ▼ ▼ */

/** */
export function JobDetailsSectionAnchor({ name, className, children, ...props }) {
  const _key = getJobDetailsSectionKey(name);
  const _isRequested = (matchLocation(_key)) && Core.isAdmin();
  const _className = join([
    _isRequested ? 'bg-yellow-lighter' : '',
    className
  ], ' ');
  return (
    <div
      ref={anchor => Core.setKeyValue(_key, anchor)}
      data-key={_key}
      className={_className}
      {...props}
    >
      {_isRequested && NOT(children)
        ? (
          <div className='f-md c-c-black-medium p-1 italic'>
            ~Content not available~
          </div>
        ) : children
      }
    </div>
  );
}

function JobDetails(props) {
  let {
    job,
    model,
    engagements: engagementsFromProps,
  } = props;
  job = job || model || getJobModel();
  JobDetails.references = JobDetails.references || [];

  const [engagements, setEngagements] = React.useState(engagementsFromProps);

  const YES__FETCHED__ENGAGEMENTS = !!engagements;
  const NOT__FETCHED__ENGAGEMENTS = !engagements;
  const YES__JOB_BLACKLIST__LENGTH = !!job.jobBlackList.length;

  React.useEffect(() => {
    if (NOT__FETCHED__ENGAGEMENTS) {
      Engagement.getWhere({ jobId: job.id }).then(setEngagements);
    }
  }, [NOT__FETCHED__ENGAGEMENTS, job]);

  function renderAdditionalNotes() {

    const mustHaveQuestions = [
      ...Arr(Obj(job.employer).mustHaveQuestions),
      ...Arr(job.mustHaveQuestions)
    ];

    let collection = [
      {
        key: "candidateMust",
        limited: true,
        label: "Must Have",
        visible: htmlHasTextContent(job.candidateMust),
      },
      {
        key: "candidateMustNot",
        limited: true,
        label: "Must NOT Have",
        visible: htmlHasTextContent(job.candidateMustNot),
      },
      {
        key: "candidatePositives",
        limited: true,
        label: "Strongly Preferred (Positives)",
        visible: htmlHasTextContent(job.candidatePositives),
      },
      {
        key: "candidateNegatives",
        limited: true,
        label: "Unlikely to Hire (Negatives)",
        visible: htmlHasTextContent(job.candidateNegatives),
      },
    ];

    const collectionHtml = collection.map((item, index) => {
      return (
        item.visible && (
          <CardContent
            title={item.label}
            key={joinKeyName([
              'job_details',
              job.id,
              'html',
              index,
              item.key
            ])}
          >
            <Html value={job[item.key]} />
          </CardContent>
        )
      );
    });

    if ((mustHaveQuestions || []).length) {
      collectionHtml.unshift(
        <CardContent
          title={(
            <span className='c-red'>
              IMPORTANT: Must Answer Pre-screen Questions for Submission
            </span>
          )}
        >
          <ul>
            {(mustHaveQuestions || []).map((q, index) =>
              q.question || q.instruction
                ? (
                  <li
                    key={joinKeyName([
                      'job_details',
                      job.id,
                      'must_have_question',
                      index,
                      q.question
                    ])}
                  >
                    {q.question}
                    {q.question && q.instruction && <> - </>}
                    <span className='f-sm'>{q.instruction}</span>
                  </li>
                )
                : ''
            )}
          </ul>
        </CardContent>
      );
    }

    return collectionHtml;
  };

  function profiles() {
    let _engagements = engagements || [];
    let ret = {};
    ret.no = () => _engagements.filter((engagement) =>
      (engagement.stage === STAGE_REVIEW) &&
      /e -/i.test(engagement.status)
    );
    ret.yes = () => {
      let filtered = _engagements.filter((engagement) => NOT(
        [
          STAGE_CONFIRMATION,
          STAGE_SUBMISSION,
          STAGE_REVIEW
        ].includes(engagement.stage)
      ));
      let hash = {
        Screen: 1,
        Onsite: 2,
        Offer: 3,
        Guarantee: 4,
        Hire: 5,
        End: 6,
      };
      filtered.sort((a, b) => {
        return (
          new Date(b.submitted).getTime() * hash[b.stage] -
          new Date(a.submitted).getTime() * hash[a.stage]
        );
      });
      return filtered;
    };
    ret.mayBe = () => _engagements.filter((engagement) =>
      (engagement.stage === STAGE_REVIEW) &&
      (engagement.status === STATUS_W_EMPLOYER_FEEDBACK)
    );
    ret.summary = () => {
      let onsite = _engagements.filter((engagement) =>
        [
          STAGE_ONSITE,
          STAGE_OFFER,
          STAGE_GUARANTEE,
          STAGE_HIRE,
          STAGE_END
        ].includes(engagement.stage)
      );
      let screens = _engagements.filter((engagement) =>
        (engagement.stage === STAGE_SCREEN)
      );
      let reviewed = [...ret.yes(), ...ret.no()];
      let submitted = [...reviewed, ...ret.mayBe()];
      return (
        <Box className='py-05'>
          {submitted.length} submitted | {reviewed.length} reviewed |{" "}
          {screens.length} screens & after (
          {parseInt(
            ((screens.length + onsite.length) * 100) / reviewed.length
          )}
          %) | {onsite.length} onSites & after (
          {parseInt((onsite.length * 100) / reviewed.length)}%)
        </Box>
      );
    };
    const printSingle = (eng) => {
      let update = eng;
      let candidate = eng.candidate;
      let isRejected = eng.state === "Closed";
      let rejectedLabel = isRejected ? "REJECTED" : "";
      let isAliveLabel = isRejected ? "" : "*";
      let rejectionReasonDetails = [
        eng.rejectionReason,
        eng.rejectionReasonAdditionalInfo,
      ]
        .filter((el) => !!el)
        .join(" - ");
      return cleanHtml(
        "<div>" +
        isAliveLabel +
        String(eng.stage) +
        " | " +
        String(eng.status) +
        " | " +
        [
          [
            moment(update.submitted).format("MM/DD/YY"),
            `<a href="/#/candidate/edit/${candidate.id}" target="_blank">${candidate.firstName} ${candidate.lastName}</a>`,
            Definition.getLabel("platformRating", candidate.platformRating),
          ]
            .filter((e) => !!e && !!String(e).trim().length)
            .join(", "),
          Definition.getLabels(
            "positiveSignals",
            candidate.positiveSignals
          ).join(", "),
          Definition.getLabels(
            "negativeSignals",
            candidate.negativeSignals
          ).join(", "),
          candidate.tagLine,
        ]
          .filter((e) => !!e && !!String(e).trim().length)
          .join("; ") +
        "<strong> - " +
        rejectedLabel +
        "</strong>" +
        ".</div>" +
        '<div style="font-size:11px;">' +
        rejectionReasonDetails +
        "</div>"
      );
    };
    ret.print = (array) => {
      return array.map((el) => printSingle(el)).join("");
    };
    return ret;
  };
  return (
    <>
      <div className="sticky-top bg-white">
        <Row className="py-1">
          <Col fullWidth className="d-flex flex-align-left-center">
            <label>{job._name} | {job._roles}</label>
            <Button
              color="secondary"
              className="ml-auto mr-4"
              onClick={ev => copyJobLarge(job)}
            >
              Copy Job
            </Button>
          </Col>
        </Row>
        <Divider />
      </div>


      <JobDetailsSectionAnchor name={JOB_SECTION__SUMMARY}>
        <CardContent title="Summary">
          <div className="d-flex flex-wrap" >
            {!!job._roles && (<span title="Roles" className="w-col-1 mr-1 mb-1">{job._roles}</span>)}
            {!!job._level && (<span title="Level" className="w-col-1 mr-1 mb-1">{job._level}</span>)}
            {!!job.employer.teamCount && (<span title="Team Count" className="w-col-1 mr-1 mb-1">~{job.employer.teamCount}&nbsp;engineers</span>)}
            <div className="d-flex flex-column w-col-2 mr-1 mb-1">
              <div title="Salary Range" className="c-black-medium">
                {!!job.salaryMax
                  ? `Salary: $${formatMoney(job.salaryMin || 0, 0)} - $${formatMoney(job.salaryMax, 0)}`
                  : "Unknown Salary"}
              </div>
              {!!job.salaryNote && (
                <small>
                  {" "}
                  - {job.salaryNote}
                  <br />
                </small>
              )}
              <small>
                <i>
                  {JobUI.salaryNote.info}
                </i>
              </small>
            </div>

            {!!job.hiringTarget && (
              <span title="Hiring Target" className="w-col-1 mr-1 mb-1">
                Hiring Target:&nbsp;
                {job.hiringTarget}
              </span>
            )}

            {/* epic-3038(new locations)-story-3652-m4 | 2021-08-03 Tue µ */}
            {!!job._desiredEmploymentTypes.length && (
              <span title="Job Type" className="w-col-1 mr-1 mb-1">
                {Definition.getLabels("desiredEmploymentType", job.desiredEmploymentTypes).join(' | ')}
              </span>
            )}

            {!!job.employer.url && (
              <span title="Company URL" className="w-col-2 mr-1 mb-1">
                <a
                  href={formatURL(job.employer.url)}
                  target="_blank"
                  rel="noreferrer"
                >
                  {job.employer.url}
                </a>
              </span>
            )}
            {!!Definition.getLabel(
              "stage",
              job.employer.stage
            ) && (
                <span title="Employer Stage e.g. Series A" className="w-col-1 mr-1 mb-1">
                  {Definition.getLabel(
                    "stage",
                    job.employer.stage
                  )}
                  &nbsp;Company
                </span>
              )}
            {!!job.employer.totalFunding && (
              <span title="Employer Founding" className="w-col-1 mr-1 mb-1">
                {!!job.employer.totalFunding
                  ? "Funding $" + job.employer.totalFunding
                  : "Unknown Funding"}
              </span>
            )}
            {!!job.employer.foundingYear && (
              <span title="Employer Founded" className="w-col-1 mr-1 mb-1">
                Founded {job.employer.foundingYear}
              </span>
            )}
            <OnlyAdmin
              className="w-col-1 mr-1 mb-1"
              title="Proxy Name"
              render={() => (job.employer.proxyName)}
            />

            <OnlyAdmin
              className="w-col-1 mr-1 mb-1"
              title="State"
              render={() => (
                Definition.getLabel("state", job.state) || (
                  <i>&mdash;</i>
                )
              )}
            />
          </div>
        </CardContent>
      </JobDetailsSectionAnchor>

      <LocationSection job={job} />

      <JobDetailsSectionAnchor name={JOB_SECTION__EMPLOYER__TAG_LINE}>
        {!!removeHtmlBlankLines(job.employer.tagline) && (
          <CardContent title={`${job.employer.name} elevator pitch`}>
            <div
              dangerouslySetInnerHTML={{
                __html: job.employer.tagline,
              }}
            />
          </CardContent>
        )}
      </JobDetailsSectionAnchor>

      <JobDetailsSectionAnchor name={JOB_SECTION__EMPLOYER__TECHNICAL_SKILLS}>
        {!!job.employer.technicalSkills.length && (
          <CardContent title="EMPLOYER TECH STACK">
            <span>
              {job.employer.technicalSkills
                .map((id) =>
                  Definition.getLabel("technicalSkills", id)
                )
                .join(", ")}
            </span>
          </CardContent>
        )}
      </JobDetailsSectionAnchor>

      <JobDetailsSectionAnchor name={JOB_SECTION__TECHNICAL_SKILLS}>
        {!!job.technicalSkills.length && (
          <CardContent title="ACCEPTABLE TECH">
            {job.technicalSkills
              .map((id) =>
                Definition.getLabel("technicalSkills", id)
              )
              .join(", ")}
          </CardContent>
        )}
      </JobDetailsSectionAnchor>

      <JobDetailsSectionAnchor name={JOB_SECTION__MUST_HAVE_QUESTIONS}>
        {renderAdditionalNotes()}
      </JobDetailsSectionAnchor>

      <div ref={self => Core.setKeyValue(JOB_SECTION__INSIDER_SCOOP, self)} />
      <JobDetailsSectionAnchor name={JOB_SECTION__QUALIFICATION_NOTES}>
        {!!job.qualificationNotes && (
          <CardContent title="INSIDER SCOOP">
            <div
              dangerouslySetInnerHTML={{
                __html: job.qualificationNotes,
              }}
            />
          </CardContent>
        )}
      </JobDetailsSectionAnchor>

      <div ref={self => Core.setKeyValue(JOB_SECTION__RESUME_FEEDBACK, self)} />
      <div ref={self => Core.setKeyValue(JOB_SECTION__INTERVIEW_FEEDBACK, self)} />
      <JobDetailsSectionAnchor name={JOB_SECTION__FEEDBACK_LOG}>
        {evaluateEmployerFeedback({ job }) && (
          <ExpandableCard
            cardTitle="FEEDBACK LOGS"
          // icon='lock'
          // iconTooltip={AppUI.AdminOnly.tooltip}
          >
            <Box column flex1>
              <CardContent
                title={!Core.isAdmin() && (
                  <div className='d-flex flex-align-left-center'>
                    <span className='mr-1'>
                      {JobUI.feedbackSectionTitle}
                    </span>
                    <StyledTooltip
                      title={JobUI.feedbackSummaryInterview.tooltip}
                      placement='right'
                      paperStyle={{ maxWidth: 420, lineHeight: '1rem' }}
                    >
                      <i className='material-icons c-purple help icon16'>help_outline</i>
                    </StyledTooltip>
                  </div>
                )}
              >
                <JobFeedbacks
                  job={job}
                  readOnly

                  // Following condition is about the route `job/view/:id?{{section}}`,
                  // which is included on the rejection email.
                  defaultTab={getURL().split('?')[1] === JOB_SECTION__INTERVIEW_FEEDBACK ? 1 : 0}

                />
              </CardContent>
            </Box>
          </ExpandableCard>
        )}
      </JobDetailsSectionAnchor>

      <JobDetailsSectionAnchor name={JOB_SECTION__DESCRIPTION}>
        {!!job.jobDescription && (
          <CardContent
            title={
              <>
                JOB DESCRIPTION&nbsp;
                {job.jobDescriptionPublicURL ? (
                  <a
                    href={job.jobDescriptionPublicURL}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Company's job description link
                  </a>
                ) : (
                  ""
                )}
              </>
            }
          >
            <div
              dangerouslySetInnerHTML={{
                __html: job.jobDescription,
              }}
            />
          </CardContent>
        )}
      </JobDetailsSectionAnchor>

      <JobDetailsSectionAnchor name={JOB_SECTION__EMPLOYER__ADDITIONAL_INFO}>
        {!!job.employer.additionalInfo && (
          <CardContent title="HIRING PRIORITY">
            <div
              dangerouslySetInnerHTML={{
                __html: job.employer.additionalInfo,
              }}
            />
          </CardContent>
        )}
      </JobDetailsSectionAnchor>

      <div ref={self => Core.setKeyValue(JOB_SECTION__INTERVIEW_PROCESS, self)} />
      <JobDetailsSectionAnchor name={JOB_SECTION__INTERVIEW_PROCESSES}>
        {(
          !!removeHtmlBlankLines(job.jobInterviewProcess) ||
          !!removeHtmlBlankLines(job.employer.interviewProcess)
        ) && (
            <CardContent title="INTERVIEW PROCESS">
              {!!job.employer.interviewProcess && (
                <>
                  General:
                  <div
                    dangerouslySetInnerHTML={{
                      __html: job.employer.interviewProcess,
                    }}
                  />
                  <br />
                </>
              )}
              {!!job.jobInterviewProcess && (
                <>
                  Job Specific:
                  <div
                    dangerouslySetInnerHTML={{
                      __html: job.jobInterviewProcess,
                    }}
                  />
                </>
              )}
            </CardContent>
          )}
      </JobDetailsSectionAnchor>

      <JobDetailsSectionAnchor name={JOB_SECTION__PUBLIC_NOTES}>
        {(
          !!removeHtmlBlankLines(job.jobNotes) ||
          !!removeHtmlBlankLines(job.employer.publicNotes)
        ) && (
            <CardContent title="NOTES">
              {!!job.employer.publicNotes && (
                <>
                  General:
                  <div
                    dangerouslySetInnerHTML={{
                      __html: job.employer.publicNotes,
                    }}
                  />
                  <br />
                </>
              )}
              {!!job.publicNotes && (
                <>
                  Job:
                  <div
                    dangerouslySetInnerHTML={{
                      __html: job.publicNotes,
                    }}
                  />
                </>
              )}
            </CardContent>
          )}
      </JobDetailsSectionAnchor>

      <JobDetailsSectionAnchor name={JOB_SECTION__EMPLOYER__PRODUCT}>
        {!!removeHtmlBlankLines(job.employer.product) && (
          <CardContent title="COMPANY OVERVIEW">
            <div
              dangerouslySetInnerHTML={{
                __html: job.employer.product,
              }}
            />
          </CardContent>
        )}
      </JobDetailsSectionAnchor>

      <JobDetailsSectionAnchor name={JOB_SECTION__EMPLOYER__WHY_US}>
        {!!removeHtmlBlankLines(job.employer.whyUs) && (
          <CardContent title="WHY US">
            <div
              dangerouslySetInnerHTML={{
                __html: job.employer.whyUs,
              }}
            />
          </CardContent>
        )}
      </JobDetailsSectionAnchor>

      <JobDetailsSectionAnchor name={JOB_SECTION__EMPLOYER__TEAM_STRUCTURE}>
        {!!removeHtmlBlankLines(job.employer.teamStructure) && (
          <CardContent
            title={EmployerUI.teamStructure.title}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: job.employer.teamStructure,
              }}
            />
          </CardContent>
        )}
      </JobDetailsSectionAnchor>

      <JobDetailsSectionAnchor name={JOB_SECTION__COMPANY_INFO}>
        {(
          !!job.employer.crunchbaseUrl ||
          !!job.employer.additionalUrl ||
          !!job.employer.notables ||
          !!job.employer.perks
        ) && (
            <CardContent title="COMPANY INFO">
              {(!!job.employer.crunchbaseUrl ||
                !!job.employer.additionalUrl) && (
                  <>
                    Links:
                    <br />
                    <br />
                    <div>
                      <a
                        href={formatURL(
                          job.employer.crunchbaseUrl
                        )}
                      >
                        {job.employer.crunchbaseUrl}
                      </a>
                    </div>
                    {job.employer.additionalUrl &&
                      job.employer.additionalUrl
                        .split(/\n/)
                        .map((url, index) => (
                          <div
                            key={joinKeyName([
                              'job_details',
                              job.id,
                              'employer_url',
                              index,
                              url
                            ])}
                          >
                            <a href={formatURL(url)}>{url}</a>
                          </div>
                        ))}
                    <br />
                  </>
                )}
              {!!job.employer.notables && (
                <>
                  Notable:
                  <pre>{job.employer.notables}</pre>
                  <br />
                </>
              )}
              {!!job.employer.perks && (
                <>
                  Perks:
                  <div
                    dangerouslySetInnerHTML={{
                      __html: job.employer.perks,
                    }}
                  />
                </>
              )}
            </CardContent>
          )}
      </JobDetailsSectionAnchor>

      <ExpandableCard adminOnly
        acl={YES__FETCHED__ENGAGEMENTS}
        cardTitle={JobUI.JobDetails.engagementsSummary.title}
      >
        <div style={{ maxHeight: "150px", overflow: "auto" }}>
          {profiles().summary()}
        </div>
      </ExpandableCard>

      <ExpandableCard adminOnly
        acl={YES__FETCHED__ENGAGEMENTS}
        cardTitle="YES - CLIENT ACCEPTED PROFILE"
      >
        <Html value={profiles().print(profiles().yes())} />
      </ExpandableCard>

      <ExpandableCard adminOnly
        acl={YES__FETCHED__ENGAGEMENTS}
        cardTitle="MAYBE - PROFILES UNDER CLIENT REVIEW"
      >
        <Html value={profiles().print(profiles().mayBe())} />
      </ExpandableCard>

      <ExpandableCard adminOnly
        acl={YES__FETCHED__ENGAGEMENTS}
        cardTitle="NO - CLIENT REJECTED PROFILE"
      >
        <Html value={profiles().print(profiles().no())} />
      </ExpandableCard>

      <ExpandableCard adminOnly
        acl={YES__JOB_BLACKLIST__LENGTH}
        cardTitle="BLACK LIST"
      >
        {job.jobBlackList.map((item, index) => (
          <li
            key={joinKeyName([
              'job_details',
              job.id,
              'black_list',
              index,
              item.id
            ])}
          >
            {item.firstName} {item.lastName}
          </li>
        ))}
      </ExpandableCard>

    </>
  );
}

export { JobDetails as default, JobDetails };
