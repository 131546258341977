import {
  showConfirm
} from '../../../components/Dialogs/AppConfirmationDialog';
import EmailPreview from "../../../components/Dialogs/EmailPreview";
import ContactsAndATS from "../../../components/Engagements/ContactsAndATS";
import AccountLib from "../../Account";
import Candidate from "../../Candidate";
import {
  TEN_BY_TEN_ADMIN_CONTACT
} from "../../Constants";
import Core from "../../Core";
import {
  REC_MSG_TYPE__ALL_ID
} from "../../Definition";
import Employer from "../../Employer";
import Engagement from "../../Engagement";
import {
  Fun
} from '../../Function.lib';
import {
  newModel,
  validateEmail
} from "../../GenericTools.lib";
import Google from "../../Google";
import fixQuillHtml from '../../Html/fixQuillHtml.fun';
import Job from "../../Job";
import TemplateLib from "../../Template.lib";
import cleanHtml from "../../tools/cleanHtml";

const TEN_BY_TEN_RECRUITER_EMAIL_OBJECT = {
  accountType: "Admin",
  name: "10x10 Team",
  email: "recruiter@10by10.io"
};

export const TEN_BY_TEN__DEVELOPER_CONTACT = {
  accountType: "Developer",
  name: "10x10 Developer",
  email: "developer@10by10.io"
};

/**
 * Wrapper to send secure emails
 *
 * from localhost environment
 *
 * @param {object} params
 * @param {string} params.from
 * @param {string} params.to
 * @param {string} params.cc
 * @param {string} params.bcc
 * @param {string} params.subject
 * @param {string} params.html
 * @param {file[]} params.attachments
 * @param {string} params.source
 * @param {function} onSuccess
 * @param {function} onFailure
 * @returns
 */
async function sendSafeEmail(
  params = {},
  onSuccess,
  onFailure,
) {
  params.html = fixQuillHtml(params.html);
  let {
    to,
    cc,
    bcc,
    subject,
    html,
  } = params;
  const originalParams = newModel({
    to,
    cc,
    bcc,
    subject,
  });
  if (Core.isLocalHost()) {
    if (process.env.REACT_APP_DEV_EMAIL) {
      params.to = (
        process.env.REACT_APP_DEV_EMAIL
      );
      params.cc = undefined;
      params.bcc = undefined;
      params.subject = 'DEVELOPMENT TEST';
      params.html = cleanHtml(`
        <h1>DEVELOPMENT TEST</h1>
        <h2>Original Params</h2>
        <pre>
          ${JSON.stringify(originalParams, null, 2).replace(/<|>/g, '')}
        </pre>
        <hr/>
        ${html || ''}
      `);
    }
    else {
      Core.showWarning('skipping all emails because its local or development');
      return;
    }
  }
  return Google.sendEmail(params, onSuccess, onFailure, true);
}

/**
 * Update on DB the reminderSentDates field of a engagement
 *
 * @sample
    ```
    updateReminderSentDates({
      eng: {...engagement},
      next: ()=>{...},
      reminder: {
        date: new Date().toISOString(), // date - time reminder was set
        source: 'recruiter', // source of email, e.g. job, employer, recruiter, agency, null (for candidate)
      }
    });
    ```
 */
function updateReminderSentDates({ engagement, next, reminder }) {
  /** * /
  reminder: {
    email, // email address reminder was sent to
    date, // date - time reminder was set
    source, // source of email, e.g. job, employer, recruiter, agency, null (for candidate)
    type // primary or secondary
  }
  /** */
  const reminderSentDates = { ...engagement.reminderSentDates };
  reminderSentDates[reminder.source] = reminder.date;
  console.debug('µ:updateReminderSentDates', { reminder, reminderSentDates });
  return Engagement.update(engagement, { reminderSentDates }, next, next);
}

export function mapEmailsListToRecipients({ emailsList = [], primaryContact = {}, messageTypes = [], accountType = 'Contact' }) {
  const recipients = {
    from: mapContactsToStrings([TEN_BY_TEN_ADMIN_CONTACT]),
    emails: [],
    to: [],
    cc: [],
    bcc: []
  };
  if (!messageTypes.includes(REC_MSG_TYPE__ALL_ID)) {
    messageTypes.push(REC_MSG_TYPE__ALL_ID);
  }
  emailsList.forEach(contact => {
    if (validateEmail(contact.email)) {
      contact.accountType = accountType;
      recipients.emails.push(contact);
      messageTypes.forEach(messageTypeId => {
        if (contact.to?.includes(messageTypeId)) { recipients.to.push(contact); }
        if (contact.cc?.includes(messageTypeId)) { recipients.cc.push(contact); }
        if (contact.bcc?.includes(messageTypeId)) { recipients.bcc.push(contact); }
      });
    }
  });
  if (validateEmail(primaryContact.email)) {
    recipients.emails.push(primaryContact);
    if (!recipients.to.length) { recipients.to.push(primaryContact); }
  }
  return recipients;
}

export function mapAllContacts({ emailsList = [], primaryContact = {}, accountType = 'Contact' }) {
  const recipients = {
    from: mapContactsToStrings([TEN_BY_TEN_ADMIN_CONTACT]),
    emails: [],
    to: [],
    cc: [],
    bcc: []
  };
  recipients.emails.push(primaryContact);
  recipients.to.push(primaryContact);
  emailsList.forEach(contact => {
    if (validateEmail(contact.email)) {
      contact.accountType = accountType;
      recipients.emails.push(contact);
      if (contact.to.length) { recipients.to.push(contact); }
      if (contact.cc.length) { recipients.cc.push(contact); }
      if (contact.bcc.length) { recipients.bcc.push(contact); }
    }
  });
  return recipients;
}

export async function openMessageEmailPreview({
  title,
  editDisclaimer,
  previewDisclaimer,
  candidateId,
  jobId,
  employerId,
  recruiterId,
  boxKey,
  engagementId,
  toAdmin = false,
  toCandidate = false,
  snippets: toFetchSnippets = true,
  recipients: toAddRecipients = true,
  to,
  cc,
  subject,
  prefix,
  greetings,
  body,
  copyText,
  onSend = Fun,
  onSuccess = Fun,
  showFrom,
  actionBar = (<></>),
  panel,
  enablePanels,
  ...otherOptions
}) {
  let candidateRecipients;
  let recruiterRecipients;
  let employerRecipients;
  let jobRecipients;
  let candidate;
  prefix = String(prefix || '').trim();
  greetings = String(greetings || '').trim();

  if (candidateId) {
    candidate = await Candidate.get(candidateId);
    candidateRecipients = mapAllContacts({
      primaryContact: { name: candidate._name, email: candidate.email, accountType: 'Candidate Contact' }
    });
    candidateRecipients.emails = combineEmailsLists(cc || [], candidateRecipients.emails);
    console.debug('candidateRecipients', candidateRecipients);
  }

  if (recruiterId) {
    const recruiter = await AccountLib.get(recruiterId);
    recruiterRecipients = mapAllContacts({
      emailsList: recruiter.emailsList,
      primaryContact: { name: recruiter._name, email: recruiter.email, accountType: 'Recruiter Main Contact' },
      accountType: 'Recruiter Additional Contact',
      messageTypes: [REC_MSG_TYPE__ALL_ID]
    });
    const candidateRecruiterRecipients = mapAllContacts({
      emailsList: candidate ? candidate.emailsList : [],
      accountType: 'Candidate Recruiter Contact'
    });
    recruiterRecipients.emails = combineEmailsLists(candidateRecruiterRecipients.emails, recruiterRecipients.emails);
    recruiterRecipients.to = combineEmailsLists(candidateRecruiterRecipients.to, recruiterRecipients.to);
    recruiterRecipients.cc = combineEmailsLists(candidateRecruiterRecipients.cc, recruiterRecipients.cc);
    recruiterRecipients.bcc = combineEmailsLists(candidateRecruiterRecipients.bcc, recruiterRecipients.bcc);
  }

  if (employerId) {
    const employer = await Employer.get(employerId);
    employerRecipients = mapAllContacts({
      emailsList: employer.emailsList,
      primaryContact: { name: employer.name, email: employer.primaryContactEmail, accountType: 'Employer Contact' },
      accountType: 'Employer Additional Contact',
      messageTypes: [REC_MSG_TYPE__ALL_ID]
    });
  }

  if (jobId) {
    const job = await Job.get(jobId);
    jobRecipients = mapAllContacts({
      emailsList: job.emailsList,
      accountType: 'Job Contact'
    });
  }

  let engagement;
  if (engagementId) {
    engagement = await Engagement.get(engagementId).catch(Core.showError);
    if (engagement && !boxKey) { boxKey = engagement.boxKey; }
  }

  const emailPreview = (
    <EmailPreview
      ref={self => self && Core.setKeyValue('MessageEmailPreview', self)}
      {...otherOptions}
      loadSnippets={Core.isAdmin() && toFetchSnippets}
      candidateRecipients={candidateRecipients}
      recruiterRecipients={recruiterRecipients}
      employerRecipients={employerRecipients}
      jobRecipients={jobRecipients}
      adminRecipients={{
        from: Core.getResumeSubmissionFromEmail(),
        emails: [TEN_BY_TEN_ADMIN_CONTACT],
        to: [TEN_BY_TEN_ADMIN_CONTACT],
        cc: [TEN_BY_TEN_ADMIN_CONTACT],
        bcc: []
      }}
      to={(
        (
          to
        ) ? (
          to
        ) : (
          toAdmin
        ) ? (
          [TEN_BY_TEN_ADMIN_CONTACT]
        ) : (
          toCandidate
        ) ? (
          candidateRecipients.to
        ) : (
          undefined
        )
      )}
      cc={cc}
      emails={to ? combineEmailsLists(to || [], cc || []) : toAdmin ? [TEN_BY_TEN_ADMIN_CONTACT] : undefined}
      toAddRecipients={toAddRecipients}
      subject={subject}
      prefix={prefix}
      greetings={greetings}
      body={body}
      copyText={copyText}
      showFrom={showFrom}
      editDisclaimer={editDisclaimer}
      previewDisclaimer={previewDisclaimer}
      panel={panel}
      enablePanels={enablePanels}
    />
  );

  showConfirm({
    title: <>{title || 'Message'}</>,
    content: (
      engagement
        ? (
          <div className="d-flex flex-align-left-top">
            {engagement && employerRecipients && (
              <div className="d-flex flex-column p-1 mr-2 w-50 scroll-y" style={{ height: 'calc(100vh - 260px)' }}>
                <ContactsAndATS
                  engagement={engagement}
                  employerTodoReminderRecipients={employerRecipients}
                />
              </div>
            )}
            <div className="w-50">
              {emailPreview}
            </div>
          </div >
        )
        : (
          <div className='mt-1 flex-1 w-100'>
            {emailPreview}
          </div>
        )
    ),
    paperStyle:
      engagement
        ? { width: 'calc(100vw - 2rem)', maxWidth: 'unset' }
        : { width: 1024, maxWidth: 'unset' },
    onAccept: async event => {
      let params = Core.getKeyValue('MessageEmailPreview').getParams();
      sendSafeEmail({
        ...params,
        html: (
          `${!!prefix
            ? `${prefix}<hr/>`
            : ''
          }${!!greetings
            ? `<p>${greetings}</p>`
            : ``
          }${params.html || ''}`
        ),
        boxKey,
        source: 'Email.lib.js'
      }).then(response => {
        if (!!response?.threadId) {
          onSuccess({
            params,
            response
          });
        }
      });
      onSend(params);
    },
    onAcceptLabel: 'Send',
    actionBar
  });
};

function mapEmailsListToString(emailsList = []) {
  return emailsList.map(
    ({
      name,
      email
    }) => (
      `${String(name || '').trim()}${String(email || '').trim() ? ` <${email}>` : ''}`
    ).trim()
  ).filter(n => !!n).join(', ');
}

export function mapEmailsListToStringArray(emailsList = []) {
  return emailsList.filter(c => validateEmail(c.email)).map(
    contact => {
      let name = contact.name && String(contact.name).trim() ? `${contact.name}` : '';
      let email = ` <${contact.email}>`;
      return `${name}${email}`.trim();
    }
  );
}

export function combineEmailsLists(a = [], b = []) {
  const hashList = {};
  const resultedList = [];
  const pushInResults = contact => {
    if (validateEmail(contact.email) && !hashList[contact.email]) {
      hashList[contact.email] = contact;
      resultedList.push(contact);
    }
  };
  a.filter(v => !!v).forEach(pushInResults);
  b.filter(v => !!v).forEach(pushInResults);
  return resultedList;
}

export function mapContactsToStrings(contacts = []) {
  return (
    contacts
      .filter(contact => contact && validateEmail(contact.email))
      .map(contact => (contact.name ? `${contact.name} <${contact.email}>` : contact.email))
  );
}

export async function getEngagementEmailRecipients({ candidate, job, engagement }) {

  let {
    emails = [],
    to = [],
    cc = [],
    bcc = [],
    names = []
  } = {};

  try {
    const renderCandidate = await TemplateLib.getRender({
      templateName: 'candidate-message',
      candidateId: candidate.id
    });
    emails = combineEmailsLists(emails, renderCandidate.mixins.emails);
    to = combineEmailsLists(to, renderCandidate.mixins.to);
    cc = combineEmailsLists(cc, renderCandidate.mixins.cc);
    bcc = combineEmailsLists(bcc, renderCandidate.mixins.bcc);
  }
  catch { }

  if (job) {

    try {
      const renderJob = await TemplateLib.getRender({
        templateName: 'job-todo-reminder',
        jobId: job.id
      });
      emails = combineEmailsLists(emails, renderJob.mixins.emails);
      // cc = combineEmailsLists(cc, renderJob.mixins.cc);
      // bcc = combineEmailsLists(bcc, renderJob.mixins.bcc);
    }
    catch { }
    try {
      const renderEmployer = await TemplateLib.getRender({
        templateName: 'employer-todo-reminder',
        employerId: job.employerId
      });
      emails = combineEmailsLists(emails, renderEmployer.mixins.emails);
      // cc = combineEmailsLists(cc, renderEmployer.mixins.cc);
      // bcc = combineEmailsLists(bcc, renderEmployer.mixins.bcc);
    }
    catch { }

  }

  if (engagement && engagement.actionOwner.email) {
    let actionOwner = {
      accountType: 'Action Owner',
      name: engagement.actionOwner._name,
      email: engagement.actionOwner.email
    };
    emails = combineEmailsLists([actionOwner], emails);
  }

  names = emails.map(input => input.name).filter(v => !!v && !v.match(/10x10|10by10/i));

  return {
    emails,
    to,
    cc,
    bcc,
    names
  }

}

const EmailLib = {
  TEN_BY_TEN_RECRUITER_EMAIL_OBJECT,
  sendSafeEmail,
  updateReminderSentDates,
  mapEmailsListToString,
}

export {
  EmailLib as default,
  EmailLib,
  mapEmailsListToString,
  sendSafeEmail,
  TEN_BY_TEN_RECRUITER_EMAIL_OBJECT,
  updateReminderSentDates
};
