import {
  debounce
} from 'lodash';
import {
  NOT
} from '../Boolean.lib';
import Core from '../Core';
import Debug from '../Debug.lib';
import Definition from '../Definition';
import {
  fetchStreakConfig
} from '../services/Configurations/Configurations.lib';
import Streak, {
  mapStreakEngagementPipeline,
  STREAK_PIPELINE_NAME__DEVELOPMENT,
  STREAK_PIPELINE_NAME__PRODUCTION
} from '../Streak';
import {
  reloadLocation
} from '../URL.lib';
import useState from './useState.hook';

export default function useStart() {
  const [{
    busy = false,
    error = false,
    ready = false
  }, updateState] = useState();
  setTimeout(async () => (NOT(busy || error || ready)) && await _start());
  const _start = async () => {
    await updateState({ busy: true });
    try {
      const version = await Core.getVersion();
      if (Core.isOnDev()) {
        const packageVersion = document.createElement('div');
        if (Debug.isOn()) {
          packageVersion.setAttribute('class', 'package-version c-purple fixed');
        } else {
          packageVersion.setAttribute('class', 'package-version ondev fixed');
        }
        packageVersion.textContent = version;
        packageVersion.addEventListener('click', (ev) =>
          Debug.toggle().then((debug) => reloadLocation())
        );
        document.body.appendChild(packageVersion);
      }
      window['setFullStory']();
      await Definition.fetchAll();
      mapStreakEngagementPipeline(
        await fetchStreakConfig(
          Core.isOnDev()
            ? STREAK_PIPELINE_NAME__DEVELOPMENT
            : STREAK_PIPELINE_NAME__PRODUCTION
        )
      );
      Streak.fetch(Core.isOnDev() ? 'qatest' : 'engagements');
      await updateState({ ready: true });
    }
    catch (error) {
      await updateState({ error });
    }
    if (window.$crisp) {
      if (Core.isAdmin()) {
        window.$crisp.push(['do', 'chat:hide']);
      }
      else {
        window.$crisp.push(['do', 'chat:show']);
      }
    }
    await updateState({ busy: false });
  }
  Core.updateState = debounce(async (update) => {
    await updateState({ busy: true, ready: false });
    await updateState(update);
    await updateState({ busy: false, ready: true });
  });
  console.debug('useStart', { busy, error, ready });
  return { busy, error, ready };
}
