import {
  compile
} from 'handlebars';
import moment from 'moment';
import {
  ENGAGEMENT__STATE_CLOSED,
  ENGAGEMENT__STATE_OPEN,
  REJECTION_REASON__EMPLOYER__NOT_INTERESTED,
  STAGE_OFFER,
  STAGE_ONSITE,
  STAGE_REVIEW,
  STAGE_SCREEN,
  STATUS_E_EMPLOYER,
  STATUS_W_10X10,
  STATUS_W_CANDIDATE_SCHEDULE,
  STATUS_W_CANDIDATE_SUBMIT_HOMEWORK,
  STATUS_W_EMPLOYER_FEEDBACK,
  STATUS_W_EMPLOYER_HOMEWORK_FEEDBACK,
  STATUS_W_EMPLOYER_SCHEDULE,
  STATUS_W_EMPLOYER_SEND_HOMEWORK,
  STATUS_W_ONSITE,
  STATUS_W_SCREEN
} from '../../../../dictionaries/Engagement.dic';
import {
  Arr,
  join
} from '../../../../lib/Array.lib';
import {
  TIMEZONE__LA
} from '../../../../lib/Constants';
import Core from '../../../../lib/Core';
import {
  EMP_MSG_TYPE__SCHEDULE_ID,
  REC_MSG_TYPE__REJECTION_ID,
  REC_MSG_TYPE__STAGE_TRANSITION_ID
} from '../../../../lib/Definition';
import Engagement from '../../../../lib/Engagement';
import {
  getHTMLLinkString
} from '../../../../lib/HTML.lib';
import {
  prependJobFeedbackLog
} from '../../../../lib/Job';
import {
  getNewPrependedEngagementFeedback
} from '../../../../lib/models/EngagementFeedback.model';
import {
  Obj
} from '../../../../lib/Object.lib';
import {
  combineEmailsLists,
  mapContactsToStrings,
  mapEmailsListToString,
  sendSafeEmail
} from '../../../../lib/services/Email/Email.lib';
import {
  generateRejectionEmailContent,
  generateStageTransitionEmailInputs
} from '../../../../lib/services/Email/EmailRecruiter.lib';
import {
  Str,
  compileText,
  isNonEmptyString,
  trim
} from '../../../../lib/String.lib';
import TemplateLib from '../../../../lib/Template.lib';
import {
  TEMPLATE__EMPLOYER_PENDINGS__ACCEPTANCE_INTERVIEW__SUBJECT__TO_CANDIDATE,
  TEMPLATE__EMPLOYER_PENDINGS__ACCEPTANCE_INTERVIEW__TO_10X10,
  TEMPLATE__EMPLOYER_PENDINGS__ACCEPTANCE_INTERVIEW__TO_CANDIDATE,
  TEMPLATE__EMPLOYER_PENDINGS__CALIBRATION_ACCEPTANCE__TO_10X10,
  TEMPLATE__EMPLOYER_PENDINGS__CALIBRATION_REJECTION__TO_10X10,
  TEMPLATE__EMPLOYER_PENDINGS__FEEDBACK__SUBJECT,
  TEMPLATE__EMPLOYER_PENDINGS__ONLY_FEEDBACK__TO_10X10,
  TEMPLATE__EMPLOYER_PENDINGS__REJECTION_INTERVIEW__TO_10X10,
  TEMPLATE__EMPLOYER_PENDINGS__VIEW_CANDIDATE__FIRST_RENDER__BODY,
  TEMPLATE__EMPLOYER_PENDINGS__VIEW_CANDIDATE__FIRST_RENDER__SUBJECT,
  TEMPLATE__EMPLOYER_PENDINGS__VIEW_CANDIDATE__USER_VIEWING__BODY,
  TEMPLATE__EMPLOYER_PENDINGS__VIEW_CANDIDATE__USER_VIEWING__SUBJECT
} from '../../../../lib/templates/EmployerPendings/EmployerPendings.templates';
import TEMPLATE__TRANSITION__SCREEN__W_EMP_SCHEDULE__BODY, {
  TEMPLATE__TRANSITION__SCREEN__W_EMP_SCHEDULE__SUBJECT
} from '../../../../lib/templates/EmployerPendings/TransitionScreenWaitingEmployerScheduling.template';
import getEngagementFeedbackReasons from '../../../Engagements/Libraries/getEngagementFeedbackReasons.fun';
import {
  getMatchDecisionsForEmail
} from '../../../Match/Libraries/MatchDecisions.lib';
import {
  EmployerPendings_updateState
} from '../EmployerPendings';
import {
  ACTION_SOURCE__PENDING,
  ACTION_TYPE__ACCEPT,
  ACTION_TYPE__ACCEPT_STRONG,
  ACTION_TYPE__ACCEPT_WEAK,
  ACTION_TYPE__FEEDBACK_TO_10X10,
  ACTION_TYPE__FEEDBACK_TO_RECRUITER,
  ACTION_TYPE__NO_ACTION,
  ACTION_TYPE__REJECT,
  ACTION_TYPE__REJECT_STRONG,
  ACTION_TYPE__REJECT_WEAK,
  EMPLOYER__PROCESSING_MODEL_KEY__CALIBRATION_FEEDBACK
} from './EmployerPendings.dic';
import {
  NEXT_STEP_ADDITIONAL__INTERVIEWED_DECISION_TBD,
  NEXT_STEP_ADDITIONAL__INTERVIEW_BOOKED,
  NEXT_STEP_ADDITIONAL__NEED_HOMEWORK_BACK,
  NEXT_STEP_ADDITIONAL__NEED_TIME_FROM_CANDO,
  NEXT_STEP_ADDITIONAL__TO_ASK_FOR_INTERVIEW,
  NEXT_STEP_ADDITIONAL__TO_GRADE_HOMEWORK,
  NEXT_STEP_ADDITIONAL__TO_SEND_HOMEWORK,
  NEXT_STEP__CXO_INTERVIEW, NEXT_STEP__FINAL_ROUND_INTERVIEW,
  NEXT_STEP__LIVE_CODING, NEXT_STEP__ONSITE_INTERVIEW,
  NEXT_STEP__VERBAL_OFFER, NEXT_STEP__WRITTEN_OFFER,
  getAcceptAdditionalPresets,
  getAcceptPresets
} from './NextSteps.lib';
import prependLogEngagementFeedbackTo10x10 from './prependLogEngagementFeedbackTo10x10.fun';
import prependLogEngagementFeedbackToRecruiter from './prependLogEngagementFeedbackToRecruiter.fun';
import sendOnlyFeedbackToRecruiterEmail from './sendOnlyFeedbackToRecruiterEmail.fun';

export function sendFeedback(options) {

  let {
    actionType = ACTION_TYPE__FEEDBACK_TO_10X10,
    selected
  } = options;

  return async event => {
    const YES__CALIBRATION = (
      !!selected.engagementKey &&
      (selected.engagementKey === EMPLOYER__PROCESSING_MODEL_KEY__CALIBRATION_FEEDBACK)
    );
    await setLoadingFlag({ loading: true, context: options, stateUpdates: { actionType } });
    switch (actionType) {
      case ACTION_TYPE__FEEDBACK_TO_10X10:
        await sendOnlyFeedbackTo10x10Email(options);
        break;
      case ACTION_TYPE__FEEDBACK_TO_RECRUITER:
        await sendOnlyFeedbackToRecruiterEmail(options);
        break;
      case ACTION_TYPE__ACCEPT:
      case ACTION_TYPE__ACCEPT_WEAK:
      case ACTION_TYPE__ACCEPT_STRONG:
        if (YES__CALIBRATION) {
          await sendAcceptanceCalibrationEmail(options);
        }
        else {
          await sendAcceptanceInterviewEmail(options);
        }
        break;
      case ACTION_TYPE__REJECT:
      case ACTION_TYPE__REJECT_WEAK:
      case ACTION_TYPE__REJECT_STRONG:
        if (YES__CALIBRATION) {
          await sendRejectionCalibrationEmail(options);
        }
        else {
          await sendRejectionInterviewEmail(options);
        }
        break;
      default:
        break;
    }
    await setLoadingFlag({ loading: false, context: options });
  }
}

export async function setLoadingFlag({ context, loading, stateUpdates }) {
  let {
    selected,
    feedbacks,
  } = context;
  if (selected.id) {
    feedbacks[selected.id] = {
      ...(feedbacks[selected.id] || {}),
      loading
    };
    return EmployerPendings_updateState({ feedbacks, ...(stateUpdates || {}) });
  }
  return null;
}

export async function onFeedbackSent({ context, emailSent }) {
  let {
    selected,
    feedbacks,
  } = context;
  if (selected.id) {
    feedbacks[selected.id] = {
      ...(feedbacks[selected.id] || {}),
      sent: emailSent,
      pendingsCounter: selected.pendingsCounter
    };
    selected.thread = emailSent;
    EmployerPendings_updateState({ feedbacks, actionType: ACTION_TYPE__NO_ACTION });
  }
}

function getFeedbackSubject(options) {
  let { actionType, selected = {}, employer, previousStage } = options;
  actionType = String(actionType || '');
  const isForCalibration = selected.engagementKey && (
    selected.engagementKey ===
    EMPLOYER__PROCESSING_MODEL_KEY__CALIBRATION_FEEDBACK
  );
  const isAccepted = [
    ACTION_TYPE__ACCEPT,
    ACTION_TYPE__ACCEPT_WEAK,
    ACTION_TYPE__ACCEPT_STRONG
  ].includes(actionType);
  const isRejected = [
    ACTION_TYPE__REJECT,
    ACTION_TYPE__REJECT_WEAK,
    ACTION_TYPE__REJECT_STRONG
  ].includes(actionType);
  const isReview = (previousStage === STAGE_REVIEW);
  return compile(TEMPLATE__EMPLOYER_PENDINGS__FEEDBACK__SUBJECT)({
    ACTION_TYPE: (isForCalibration
      ? `Calibration: ${actionType}`
      : actionType
    ),
    CANDIDATE__FULLNAME: selected.candidate._name || '[candidate]',
    RECRUITER__FULLNAME: trim(selected.recruiterFullname),
    EMPLOYER__NAME: employer.name || '[employer]',
    JOB_TITLE: selected.job.jobTitle || '[job]',
    STAGE: selected.stage || '[stage]',
    ACTION_TYPE__REJECT: isRejected,
    ACTION_TYPE__ACCEPT: isAccepted,
    STAGE__REVIEW: isReview,
    USER__NAME: Core.getUserName() || '[user]',
    ADMIN: Core.isAdmin()
  });

}

function getViewEngagementHTML({ engagementId }) {
  return getHTMLLinkString({
    url: Core.getPath(
      `engagement/view/${engagementId}`
    ),
    name: 'Engagement View'
  });
}

function getHtmlLinkCandidateLinkedIn({ linkedInURL }) {
  linkedInURL = trim(linkedInURL);
  return trim(
    linkedInURL && getHTMLLinkString({
      url: linkedInURL,
      name: 'Candidate LinkedIn'
    })
  );
}

function getPendingsLinkHTML({ employerId, engagementId }) {
  return getHTMLLinkString({
    url: Core.getPath(
      `employer/pendings/?employerId=${employerId}&engagementId=${engagementId}`
    ),
    name: 'Employer Pendings'
  });
}

export function openEmployerPendings({ employerId, engagementId, jobId }) {
  const path = Core.getPath(join([
    `employer/pendings/?employerId=${employerId}`,
    engagementId && `&engagementId=${engagementId}`,
    jobId && `&jobId=${jobId}`
  ], ''));
  return Core.openPopUp(path, 1600);
}

export async function sendAcceptanceCalibrationEmail(options) {
  let {
    actionType = ACTION_TYPE__ACCEPT,
    selected,
    feedback = {}
  } = options;

  // UPDATING engagement for "TU" calibration
  let update = {
    state: ENGAGEMENT__STATE_OPEN,
    status: STATUS_W_10X10,
    closed: ''
  };
  await Engagement.update(selected, update);
  Object.assign(selected, update);

  // SENDING "TU" calibration email to 10x10
  let emailSent = await sendSafeEmail({
    from: Core.getNewCandidateReceiverNotiFromEmail(),
    to: Core.getNewCandidateReceiverNotiToEmail(),
    boxKey: selected.boxKey,
    subject: getFeedbackSubject(options),
    html: compile(TEMPLATE__EMPLOYER_PENDINGS__CALIBRATION_ACCEPTANCE__TO_10X10)({
      FEEDBACK: trim(feedback.text).replace(/\n/g, '<br/>'),
      MATCH_INFO: await getMatchDecisionsForEmail({
        engagement: selected,
        accepted: true
      }),
      RECRUITER__FULLNAME: selected.recruiterFullname,
      RECRUITER__EMAIL: selected.recruiterEmail,
      CANDIDATE__LINKEDIN: getHtmlLinkCandidateLinkedIn(selected.candidate),
      ENGAGEMENT__LINK: getViewEngagementHTML({
        engagementId: selected.id
      }),
      PENDINGS__LINK: getPendingsLinkHTML({
        employerId: selected.employerId,
        engagementId: selected.id
      })
    })
  });
  if (!!emailSent) {
    emailSent.actionType = actionType;
    onFeedbackSent({ context: options, emailSent });
  }

  updateFeedbackLog({ selected, feedback, actionType, stage: selected.stage });

}

async function sendRejectionCalibrationEmail(options) {
  let {
    actionType = ACTION_TYPE__REJECT,
    selected,
    feedback
  } = options;

  const fullPresets = await getEngagementFeedbackReasons({
    jobId: selected.jobId
  });
  let presets = [...(feedback.presets || [])].filter(v => !!fullPresets.includes(v));

  // UPDATING engagement for "TD" calibration
  let update = {
    state: ENGAGEMENT__STATE_OPEN,
    status: STATUS_W_10X10,
    closed: ''
  };
  await Engagement.update(selected, update);
  Object.assign(selected, update);

  // SENDING "TD" calibration email to 10x10
  let emailSent = await sendSafeEmail({
    from: Core.getNewCandidateReceiverNotiFromEmail(),
    to: Core.getNewCandidateReceiverNotiToEmail(),
    boxKey: selected.boxKey,
    subject: getFeedbackSubject(options),
    html: compile(TEMPLATE__EMPLOYER_PENDINGS__CALIBRATION_REJECTION__TO_10X10)({
      REJECTION_REASONS: join(presets) || REJECTION_REASON__EMPLOYER__NOT_INTERESTED,
      FEEDBACK: trim(feedback.text).replace(/\n/g, '<br/>'),
      MATCH_INFO: await getMatchDecisionsForEmail({
        engagement: selected,
        rejected: true
      }),
      RECRUITER__FULLNAME: selected.recruiterFullname,
      RECRUITER__EMAIL: selected.recruiterEmail,
      CANDIDATE__LINKEDIN: getHtmlLinkCandidateLinkedIn(selected.candidate),
      ENGAGEMENT__LINK: getViewEngagementHTML({
        engagementId: selected.id
      }),
      PENDINGS__LINK: getPendingsLinkHTML({
        employerId: selected.employerId,
        engagementId: selected.id
      })
    })
  });
  if (!!emailSent) {
    emailSent.actionType = actionType;
    onFeedbackSent({ context: options, emailSent });
  }

  updateFeedbackLog({ selected, feedback, actionType, stage: selected.stage });

}

async function sendOnlyFeedbackTo10x10Email(options) {
  let {
    actionType = ACTION_TYPE__FEEDBACK_TO_10X10,
    selected,
    feedback,
  } = options;
  if (!feedback.text) {
    return Core.showWarning('Type some feedback to share with us');
  }
  let { stage } = selected;
  let context = {
    ...options,
    // we need to preserve current selected in context since user can go to select a diff one before process done
    selected
  };

  // TO 10BY10 "UPDATE"
  let emailSent = await sendSafeEmail({
    from: Core.getNewCandidateReceiverNotiFromEmail(),
    to: Core.getNewCandidateReceiverNotiToEmail(),
    boxKey: selected.boxKey,
    subject: getFeedbackSubject(options),
    html: compile(TEMPLATE__EMPLOYER_PENDINGS__ONLY_FEEDBACK__TO_10X10)({
      FEEDBACK: trim(feedback.text).replace(/\n/g, '<br/>'),
      MATCH_INFO: await getMatchDecisionsForEmail({
        engagement: selected
      }),
      RECRUITER__FULLNAME: selected.recruiterFullname,
      RECRUITER__EMAIL: selected.recruiterEmail,
      CANDIDATE__LINKEDIN: getHtmlLinkCandidateLinkedIn(selected.candidate),
      ENGAGEMENT__LINK: getViewEngagementHTML({
        engagementId: selected.id
      }),
      PENDINGS__LINK: getPendingsLinkHTML({
        employerId: selected.employerId,
        engagementId: selected.id
      })
    })
  });
  if (!!emailSent) {
    emailSent.actionType = actionType;
    onFeedbackSent({ context, emailSent });
  }
  updateFeedbackLog({ selected, feedback, actionType, stage });
}

async function sendRejectionInterviewEmail(options) {
  let {
    actionType = ACTION_TYPE__REJECT,
    selected,
    feedback
  } = options;
  const { stage } = selected;
  const emailContent = Obj(
    await generateRejectionEmailContent({ engagement: selected })
  );
  const fullPresets = await getEngagementFeedbackReasons({
    jobId: selected.jobId
  });
  let presets = [...(feedback.presets || [])].filter(v => !!fullPresets.includes(v));

  let rejectionReason = presets[0] || REJECTION_REASON__EMPLOYER__NOT_INTERESTED;
  let now = moment().tz(TIMEZONE__LA).toISOString();

  let update = {
    state: ENGAGEMENT__STATE_CLOSED,
    status: STATUS_E_EMPLOYER,
    rejectionReason,
    rejectionReasonAdditionalInfo: trim(feedback.text),
    feedback: getNewPrependedEngagementFeedback({
      engagement: selected,
      labels: presets
    }),
    closed: now
  };
  if (stage === STAGE_REVIEW) { update.reviewed = now; }
  else if (stage === STAGE_SCREEN) { update.screened = now; }
  else if (stage === STAGE_ONSITE) { update.onsite = now; }

  await Engagement.update(selected, update);
  Object.assign(selected, update);

  const rejectionReasons = trim(
    join(presets) || REJECTION_REASON__EMPLOYER__NOT_INTERESTED
  );

  // TO 10BY10 "TD"
  const emailSent = await sendSafeEmail({
    from: Core.getNewCandidateReceiverNotiFromEmail(),
    to: Core.getNewCandidateReceiverNotiToEmail(),
    boxKey: selected.boxKey,
    subject: getFeedbackSubject(options),
    html: compile(TEMPLATE__EMPLOYER_PENDINGS__REJECTION_INTERVIEW__TO_10X10)({
      REJECTION_REASONS: rejectionReasons,
      FEEDBACK: trim(feedback.text).replace(/\n/g, '<br/>'),
      MATCH_INFO: await getMatchDecisionsForEmail({
        engagement: selected,
        rejected: true
      }),
      RECRUITER__FULLNAME: selected.recruiterFullname,
      RECRUITER__EMAIL: selected.recruiterEmail,
      CANDIDATE__LINKEDIN: getHtmlLinkCandidateLinkedIn(selected.candidate),
      ENGAGEMENT__LINK: getViewEngagementHTML({
        engagementId: selected.id
      }),
      PENDINGS__LINK: getPendingsLinkHTML({
        employerId: selected.employerId,
        engagementId: selected.id
      }),
    })
  });
  if (!!emailSent) {
    emailSent.actionType = actionType;
    onFeedbackSent({ context: options, emailSent });
  }

  // TO CANDIDATE
  sendEmailToCandidate({
    candidateId: selected.candidateId,
    subject: emailContent.subject,
    body: emailContent.body,
    messageTypes: [REC_MSG_TYPE__REJECTION_ID]
  });

  // Update JOB {{log}} and ENGAGEMENT empFBText
  updateFeedbackLog({ selected, feedback, actionType, stage });

}

export async function sendAcceptanceInterviewEmail(options) {
  let {
    actionType = ACTION_TYPE__ACCEPT,
    selected,
    employer,
    feedback = {}
  } = options;
  let { stage: currentStage, nextScreenLabel, nextOnsiteLabel, employerId } = selected;
  let { presets = [], additionalPresets = [] } = feedback;
  let fullPresets = getAcceptPresets().flat();
  presets = [...(feedback.presets || [])].filter(v => !!fullPresets.includes(v));
  let prev = {
    engagementId: selected.id + '',
    eventType: String(selected.stage || '').toLowerCase(),
    previousStage: selected.stage + '',
    previousStatus: selected.status + ''
  };

  let now = moment().tz(TIMEZONE__LA).toISOString();
  let update = {
    state: ENGAGEMENT__STATE_OPEN,
    status: STATUS_W_EMPLOYER_SCHEDULE,
    closed: ''
  };

  function _mapStage() {
    if (
      presets.includes(NEXT_STEP__ONSITE_INTERVIEW) ||
      presets.includes(NEXT_STEP__FINAL_ROUND_INTERVIEW)
    ) {
      update.stage = STAGE_ONSITE;
      update.reviewed = update.reviewed || now;
      update.screened = update.screened || now;
    }
    if (
      !!presets.find(v => [
        NEXT_STEP__VERBAL_OFFER,
        NEXT_STEP__WRITTEN_OFFER
      ].includes(v))
    ) {
      update.stage = STAGE_OFFER;
      update.reviewed = update.reviewed || now;
      update.screened = update.screened || now;
      update.onsite = update.onsite || now;
    }
  }

  function _mapStatus() {
    if (additionalPresets.includes(NEXT_STEP_ADDITIONAL__TO_ASK_FOR_INTERVIEW)) {
      update.status = STATUS_W_EMPLOYER_SCHEDULE;
    }
    else if (additionalPresets.includes(NEXT_STEP_ADDITIONAL__INTERVIEW_BOOKED)) {
      update.status = (
        (update.stage === STAGE_SCREEN)
          ? STATUS_W_SCREEN
          : (update.stage === STAGE_ONSITE)
            ? STATUS_W_ONSITE
            : update.status
      );
    }
    else if (additionalPresets.includes(NEXT_STEP_ADDITIONAL__NEED_TIME_FROM_CANDO)) {
      update.status = STATUS_W_CANDIDATE_SCHEDULE;
    }
    else if (additionalPresets.includes(NEXT_STEP_ADDITIONAL__INTERVIEWED_DECISION_TBD)) {
      update.status = STATUS_W_EMPLOYER_FEEDBACK;
      if ((currentStage === STAGE_SCREEN) && isNonEmptyString(nextScreenLabel)) {
        update[nextScreenLabel] = moment().tz(TIMEZONE__LA).toISOString();
      }
      else if ((currentStage === STAGE_ONSITE) && isNonEmptyString(nextOnsiteLabel)) {
        update[nextOnsiteLabel] = moment().tz(TIMEZONE__LA).toISOString();
      }
    }
    else if (additionalPresets.includes(NEXT_STEP_ADDITIONAL__TO_SEND_HOMEWORK)) {
      update.status = STATUS_W_EMPLOYER_SEND_HOMEWORK;
    }
    else if (
      additionalPresets.includes(NEXT_STEP_ADDITIONAL__NEED_HOMEWORK_BACK)
    ) {
      update.status = STATUS_W_CANDIDATE_SUBMIT_HOMEWORK;
    }
    else if (
      additionalPresets.includes(NEXT_STEP_ADDITIONAL__TO_GRADE_HOMEWORK)
    ) {
      update.status = STATUS_W_EMPLOYER_HOMEWORK_FEEDBACK;
    }
  }

  // CASE stage review
  if (currentStage === STAGE_REVIEW) {

    update.stage = STAGE_SCREEN;
    update.status = STATUS_W_EMPLOYER_SCHEDULE;
    update.reviewed = now;
    update.screened = '';
    update.onsite = '';

    prev.eventType = String(STAGE_SCREEN).toLowerCase();

  }
  else if ((currentStage === STAGE_SCREEN) || presets.includes(NEXT_STEP__LIVE_CODING)) {
    // update.status = STATUS_W_SCREEN;
    update.screened = '';
    update.onsite = '';
  }
  else if (
    (currentStage === STAGE_ONSITE) ||
    presets.includes(NEXT_STEP__CXO_INTERVIEW) ||
    presets.includes(NEXT_STEP__FINAL_ROUND_INTERVIEW)
  ) {
    // update.status = STATUS_W_ONSITE;
    update.onsite = '';
  }

  _mapStage();
  _mapStatus();

  await Engagement.update(selected, update);
  Object.assign(selected, update);

  let emailContent = await generateStageTransitionEmailInputs(prev);

  // TO 10BY10 "TU"
  let emailSent = await sendSafeEmail({
    from: Core.getNewCandidateReceiverNotiFromEmail(),
    to: Core.getNewCandidateReceiverNotiToEmail(),
    boxKey: selected.boxKey,
    subject: getFeedbackSubject({ ...prev, ...options }),
    html: compile(TEMPLATE__EMPLOYER_PENDINGS__ACCEPTANCE_INTERVIEW__TO_10X10)({
      NEXT_STEPS: join(presets),
      ADDITIONAL_STEPS: join(feedback.additionalPresets),
      FEEDBACK: trim(feedback.text),
      MATCH_INFO: await getMatchDecisionsForEmail({
        engagement: selected,
        accepted: true
      }),
      RECRUITER__FULLNAME: selected.recruiterFullname,
      RECRUITER__EMAIL: selected.recruiterEmail,
      CANDIDATE__LINKEDIN: getHtmlLinkCandidateLinkedIn(selected.candidate),
      ENGAGEMENT__LINK: getViewEngagementHTML({ engagementId: selected.id }),
      PENDINGS__LINK: getPendingsLinkHTML({ employerId, engagementId: selected.id }),
    })
  });
  if (!!emailSent) {
    emailSent.actionType = actionType;
    onFeedbackSent({ context: options, emailSent });
  }

  // TO CANDIDATE
  sendEmailToCandidate({
    candidateId: selected.candidateId,
    subject:
      compileText(
        TEMPLATE__EMPLOYER_PENDINGS__ACCEPTANCE_INTERVIEW__SUBJECT__TO_CANDIDATE,
        {
          CANDIDATE__NAME: selected.candidateName || '[candidate]',
          EMPLOYER__NAME: employer.name || '[employer]',
          JOB_TITLE: selected.job.jobTitle ? ` (${selected.job.jobTitle})` : ``,
          NEXT_STEP: presets[0] ? ` (Next step: ${presets[0]})` : ''
        }
      ),
    body: compile(TEMPLATE__EMPLOYER_PENDINGS__ACCEPTANCE_INTERVIEW__TO_CANDIDATE)({
      BODY: emailContent.body,
      NEXT_STEPS: join(presets),
      EMPLOYER__NAME: employer.name || '[employer]',
      YES__STRONG_YES: actionType === ACTION_TYPE__ACCEPT_STRONG,
    }),
    messageTypes: [REC_MSG_TYPE__STAGE_TRANSITION_ID]
  });

  // TO EMPLOYER
  if (
    (currentStage === STAGE_REVIEW) &&
    (update.status === STATUS_W_EMPLOYER_SCHEDULE)
  ) {
    const mixins = Obj(
      Obj(
        await TemplateLib.getRender({
          templateName: 'job-todo-reminder',
          jobId: selected.jobId,
          messageTypes: [
            EMP_MSG_TYPE__SCHEDULE_ID
          ]
        })
      ).mixins
    );
    await sendSafeEmail({
      from: Core.getNewCandidateReceiverNotiFromEmail(),
      to: mapContactsToStrings(mixins.to),
      cc: [
        ...mapContactsToStrings(mixins.cc),
        Core.getNewCandidateReceiverNotiFromEmail()
      ],
      bcc: mapContactsToStrings(mixins.bcc),
      subject: compileText(
        TEMPLATE__TRANSITION__SCREEN__W_EMP_SCHEDULE__SUBJECT,
        { CANDIDATE: selected.candidateName }
      ),
      html: compile(
        TEMPLATE__TRANSITION__SCREEN__W_EMP_SCHEDULE__BODY
      )({
        CANDIDATE: selected.candidateName,
        JOB: selected.job.jobTitle,
        APPROVER: Core.getUserName(),
        DATE: moment.tz(TIMEZONE__LA).format('MM/DD')
      })
    });
  }

  updateFeedbackLog({ selected, feedback, actionType, stage: currentStage });

}

export async function sendViewCandidateEmail(options) {
  let {
    selected = {},
    employer,
    firstRender
  } = options;
  const subjectTemplate10x10 = (firstRender
    ? TEMPLATE__EMPLOYER_PENDINGS__VIEW_CANDIDATE__FIRST_RENDER__SUBJECT
    : TEMPLATE__EMPLOYER_PENDINGS__VIEW_CANDIDATE__USER_VIEWING__SUBJECT
  );
  const bodyTemplate = (firstRender
    ? TEMPLATE__EMPLOYER_PENDINGS__VIEW_CANDIDATE__FIRST_RENDER__BODY
    : TEMPLATE__EMPLOYER_PENDINGS__VIEW_CANDIDATE__USER_VIEWING__BODY
  )
  let now = moment().tz(TIMEZONE__LA).format('HH:mm (z)');
  let candidate = selected.candidate;
  const employerName = employer.name || '[employer]';
  const candidateName = candidate._name || `[candidate]`;
  const userName = Core.getUserName() || `[user]`;
  if (subjectTemplate10x10) {
    await sendSafeEmail({
      from: Core.getNewCandidateReceiverNotiFromEmail(),
      to: Core.getNewCandidateReceiverNotiToEmail(),
      subject: compile(subjectTemplate10x10)({
        EMPLOYER__NAME: employerName,
        USER__NAME: userName,
        CANDIDATE__FULLNAME: candidateName,
        RECRUITER__FULLNAME: trim(selected.recruiterFullname),
        NOW: now
      }),
      html: compile(bodyTemplate)({
        ENGAGEMENT__LINK: getViewEngagementHTML({
          engagementId: selected.id
        }),
        PENDINGS__LINK: getPendingsLinkHTML({
          employerId: selected.employerId,
          engagementId: selected.id
        }),
        USER__NAME: userName,
        EMPLOYER__NAME: employerName,
        CANDIDATE__FULLNAME: candidateName
      })
    });
  }
}

async function sendEmailToCandidate({ candidateId, subject, body, messageTypes = [] }) {
  if (!candidateId || !Array.isArray(messageTypes) || !messageTypes.length) { return; }
  let {
    from = '',
    to = [],
    cc = [],
    bcc = []
  } = {};
  const renderCandidate = await TemplateLib.getRender({
    templateName: 'candidate-message',
    candidateId,
    messageTypes
  });
  if (renderCandidate?.mixins) {
    from = renderCandidate.mixins.from;
    to = combineEmailsLists(to, renderCandidate.mixins.to || []);
    cc = combineEmailsLists(cc, renderCandidate.mixins.cc || []);
    bcc = combineEmailsLists(bcc, renderCandidate.mixins.bcc || []);
    // console.debug(mapEmailsListToString(to), { from, to, cc, bcc });
  }
  return sendSafeEmail(
    {
      from,
      to: mapEmailsListToString(to),
      cc: mapEmailsListToString(cc),
      bcc: mapEmailsListToString(bcc),
      subject,
      html: body
    }
  );
}

/**
 * 
 * @param {object} options 
 * @param {object} options.selected pending page selected object | partial engagement object
 * @param {object} options.feedback {presets,additionalPresets,text,pendingsCounter,loading,sent}
 * @param {string} options.actionType
 * @param {string} options.stage this may be diff from selected.stage, for that reason it is a separated param.
 * @returns {void}
 */
async function updateFeedbackLog(options) {
  try {
    let { selected = {}, feedback = {}, actionType: ACTION, actionType, stage } = options;
    actionType = Str(actionType);
    const fullPresets = await getEngagementFeedbackReasons({
      jobId: selected.jobId
    });
    const { id, jobId = '' } = selected;
    const YES__ACTION_ACCEPT = [
      ACTION_TYPE__ACCEPT,
      ACTION_TYPE__ACCEPT_WEAK,
      ACTION_TYPE__ACCEPT_STRONG
    ].includes(actionType);
    const YES__ACTION_REJECT = [
      ACTION_TYPE__REJECT,
      ACTION_TYPE__REJECT_WEAK,
      ACTION_TYPE__REJECT_STRONG
    ].includes(actionType);
    const USER = Core.getUserName();
    const PRESETS = join(
      [...Arr(feedback.presets), ...Arr(feedback.additionalPresets)].filter((preset) => (
        YES__ACTION_ACCEPT ? [
          ...getAcceptPresets().flat(),
          ...getAcceptAdditionalPresets({
            preset: Arr(feedback.presets)[0]
          })
        ].includes(preset)
          : YES__ACTION_REJECT
            ? fullPresets.includes(preset)
            : false
      ))
    );
    const NOTE = Str(feedback.text).replace(/\n/g, ' ').trim();
    await prependJobFeedbackLog({
      jobId,
      context: {
        ACTION,
        SOURCE: ACTION_SOURCE__PENDING,
        CANDIDATE__NAME: selected.candidateName,
        USER,
        PRESETS,
        NOTE
      },
      stage
    });

    // TO 10x10 PREPENDING LOG
    selected.empFBText = Obj(
      await prependLogEngagementFeedbackTo10x10({
        engagementId: id,
        context: {
          ACTION,
          USER,
          NOTE
        },
      })
    )['empFBText'];

    const YES__CALIBRATION = (
      selected.engagementKey === EMPLOYER__PROCESSING_MODEL_KEY__CALIBRATION_FEEDBACK
    );
    const NOT__CALIBRATION = !YES__CALIBRATION;

    if (
      NOT__CALIBRATION &&
      (
        YES__ACTION_ACCEPT ||
        YES__ACTION_REJECT
      )
    ) {
      // TO RECRUITER PREPENDING LOG
      selected.toRecruiterFBText = Obj(
        await prependLogEngagementFeedbackToRecruiter({
          engagementId: selected.id,
          context: {
            ACTION
          },
        })
      ).toRecruiterFBText;
    }

    EmployerPendings_updateState({ selected });
  }
  catch (error) {
    Core.showError(error);
  }
}

export function removeContactInfoFromSubmissionNote(submissionNote) {
  submissionNote = trim(submissionNote);
  submissionNote = submissionNote.replace(/<ul>/gi, '<ul>\n');
  submissionNote = submissionNote.replace(/<\/li>/gi, '</li>\n');
  submissionNote = join(
    submissionNote.split('\n').filter(line => !!trim(line) && !Str(line).match(/phone|email|calendar link/i)),
    ''
  );
  return submissionNote;
}
