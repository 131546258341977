import {
  camelCase
} from 'lodash';
import {
  EMPLOYER_MANAGE_TEMPLATES_TOKEN__CANDIDATE__FIRSTNAME,
  EMPLOYER_MANAGE_TEMPLATES_TOKEN__MY_FIRSTNAME
} from '../../components/Employers/EmployerManageTemplates/EmployerManageTemplatesContent';
import Core from '../Core';
import {
  joinHTML
} from '../HTML.lib';

export function getNewEmployerEmailTemplateModel({
  templateName = new Date().toISOString(),
  subject = 'Interview invite from {{EMPLOYER__NAME}}',
  greetings = `Hi {{${EMPLOYER_MANAGE_TEMPLATES_TOKEN__CANDIDATE__FIRSTNAME}}},`,
  body = joinHTML(`
    10x10 shared your profile with us and we are impressed by your background. We're excited to invite you to interview with us so we can learn more about your skills, expertise and interests. Your first interview will be with me.
    <br/><br/>
    I'd love to find a time to chat. I have included my calendar booking link here.
    <br/><br/>
    or
    <br/><br/>
    If you can provide me with 3-5 windows of time that work for you in the next 5 days, we can get a zoom call set up in the near future.
    <br/><br/>
    Please reply all, so 10x10 can help keep track of the interview (re/schedule). We look forward to speaking with you soon!
    <br/><br/>
    Regards,<br/>
    {{${EMPLOYER_MANAGE_TEMPLATES_TOKEN__MY_FIRSTNAME}}}
  `)
} = {}) {
  return ({
    accountId: Core.getUserId(),
    templateKey: camelCase(templateName),
    templateName,
    greetings,
    subject,
    body,
  });
}

export function getNewEmployerEmailTemplateStructure() {
  return { enable: true, list: [getNewEmployerEmailTemplateModel()] };
}
