import {
  joinHTML
} from '../HTML.lib';

/**
 * Signature for outgoing emails from the frontend.
 *
 * @param TEAM_NAME - [plaintext] The team's name.
 * @param TEAM_PHONE - [plaintext] The team's contact phone number.
 * @param TEAM_EMAIL - [plaintext] The team's contact email address.
 * @param TEAM_SLOGAN - [plaintext] The team's slogan.
 */
export const TEMPLATE__EMAIL_SIGNATURE = joinHTML(`
<b>{{TEAM_NAME}}</b><br>
<a href="tel:{{TEAM_PHONE}}">
  {{TEAM_PHONE}}
</a>&nbsp;|&nbsp;
<a href="mailto:{{TEAM_EMAIL}}">
  {{TEAM_EMAIL}}
</a>&nbsp;|&nbsp;{{TEAM_SLOGAN}}
`);
