import {
  joinHTML
} from '../HTML.lib';

/**
 * Subject template for  a 10x10 notification,
 * when a user agrees to terms to see a job or candidate engagements.
 * 
 * @param {string} USER__NAME - [plaintext] the name of the user
 */
export const TEMPLATE__SUBJECT__COLLECTION_USER_AGREE_TO_TERMS = `[System Notification] {{USER__NAME}} agreed to terms to see engagements.`;

/**
 * Body template for  a 10x10 notification,
 * when a user agrees to terms to see a job or candidate engagements.
 * 
 * @param {string} USER__NAME - [plaintext] the name of the user
 * @param {string} USER__EMAIL - [plaintext] the email of the user
 */
export const TEMPLATE__BODY__COLLECTION_USER_AGREE_TO_TERMS = joinHTML(`
  {{USER__NAME}} ({{USER__EMAIL}}) has agreed to terms to see engagements.
`);