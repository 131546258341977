import {
  compile
} from 'handlebars';
import {
  isEqual
} from 'lodash';
import {
  Arr,
  join
} from '../../../lib/Array.lib';
import {
  CSS_GRAY_RGB
} from '../../../lib/Constants';
import Core from '../../../lib/Core';
import Employer from '../../../lib/Employer';
import globalErrorHandler from '../../../lib/Error/globalErrorHandler.fun';
import {
  Fun
} from '../../../lib/Function.lib';
import {
  NOT
} from '../../../lib/GenericTools.lib';
import {
  joinHTML,
  trimHtml
} from '../../../lib/HTML.lib';
import {
  Obj
} from '../../../lib/Object.lib';
import {
  FirstWord,
  compileText,
  trim
} from '../../../lib/String.lib';
import useState from '../../../lib/hooks/useState.hook';
import {
  getNewEmployerEmailTemplateStructure
} from '../../../lib/models/employerEmailTemplate.model';
import {
  TEN_BY_TEN_RECRUITER_EMAIL_OBJECT,
  sendSafeEmail
} from '../../../lib/services/Email/Email.lib';
import EmailPreview, {
  EMAIL_DIALOG_PANEL__EDIT
} from '../../Dialogs/EmailPreview';
import Button from '../../Layout/Wrappers/Button';
import Loader from '../../Shared/Loader';
import {
  EMPLOYER_MANAGE_TEMPLATES_TOKEN__CANDIDATE__FIRSTNAME,
  EMPLOYER_MANAGE_TEMPLATES_TOKEN__CANDIDATE__LASTNAME,
  EMPLOYER_MANAGE_TEMPLATES_TOKEN__CANDIDATE__NICKNAME,
  EMPLOYER_MANAGE_TEMPLATES_TOKEN__EMPLOYER_NAME,
  EMPLOYER_MANAGE_TEMPLATES_TOKEN__MY_FIRSTNAME
} from '../EmployerManageTemplates/EmployerManageTemplatesContent';
import {
  EmployerTemplateBar
} from '../EmployerManageTemplates/EmployerTemplateBar';
import {
  EMPLOYER__PROCESSING_MODEL_KEY__CALIBRATION_FEEDBACK
} from './Libraries/EmployerPendings.dic';

export function MessageEmployerToCandidate({
  context,
  appendText
}) {
  const [state, updateState] = useState({});
  const {
    selected,
    employer
  } = context;
  if (!employer?.id) { return; }
  if (
    NOT(
      trimHtml(
        Arr(
          employer.emailTemplate?.list
        )[0]?.body
      )
    )
  ) {
    employer.emailTemplate = getNewEmployerEmailTemplateStructure();
  }

  /** 
   * Get the selected email template record,  
   * or the first one from the employer.  
   * `selected.emailTemplateRecord` is set  
   * when the user edits it, ensuring edits  
   * are preserved in memory, even when  
   * switching tabs or preview modes.  
   */
  const emailTemplateRecord = context.selected.emailTemplateRecord || employer.emailTemplate.list[0];

  async function _onSend(params) {
    try {
      Object.assign(emailTemplateRecord, {
        subject: params.subject,
        greetings: params.greetings,
        body: params.html
      });
      return Employer.update(employer.id, {
        emailTemplate: { ...employer.emailTemplate, list: [emailTemplateRecord] }
      });
    }
    catch (error) {
      globalErrorHandler(error);
    }
  }

  const _onUserEdit = async () => {
    const currentEmail = Obj(Fun(getControllerMessageEmployerToCandidateEmailPreview().getParams)());
    Object.assign(emailTemplateRecord, {
      subject: currentEmail.subject,
      greetings: currentEmail.greetings,
      body: currentEmail.html
    });
    Object.assign(context.selected, { emailTemplateRecord });
    await updateState();
  };

  const userFirstname = FirstWord(Core.getUserName());
  const greetings = '';
  const prefix = compileText(trim(`
    <i style="color: {{COLOR}}">
      Sent by 10 By 10 on behalf of {{USER__NAME}} from {{EMPLOYER__NAME}}.<br/>
      Please reply all to make sure you respond to both the employer and 10 By 10
    </i>
  `), {
    COLOR: CSS_GRAY_RGB,
    USER__NAME: Core.getUserName(),
    EMPLOYER__NAME: employer.name
  });

  const emailSent = Obj(selected.emailSent);
  const currentEmail = Obj(Fun(getControllerMessageEmployerToCandidateEmailPreview().getParams)());

  const YES__CALIBRATION = (
    selected.engagementKey === EMPLOYER__PROCESSING_MODEL_KEY__CALIBRATION_FEEDBACK
  );
  const YES__BUSY = !!state.busy;
  const YES__DISABLED__BUTTON__SEND = (
    YES__BUSY ||
    YES__CALIBRATION ||
    isEqual(emailSent, currentEmail)
  );
  const YES__EMAIL_SENT = !!selected.emailSent;

  console.debug(
    'MessageEmployerToCandidate',
    YES__DISABLED__BUTTON__SEND,
    '\n', emailSent,
    '\n', currentEmail
  );

  return (
    <>
      <EmailPreview
        ref={setControllerMessageEmployerToCandidateEmailPreview}
        {...({
          editDisclaimer: (
            <div className='f-md f-italic c-black-medium mb-2'>
              Edit email to candidate and manage shared templates.
            </div>
          ),
          previewDisclaimer: (
            <div className='f-md f-italic c-black-medium mb-2'>
              Preview email to candidate.
            </div>
          ),
          to: YES__CALIBRATION ? [] : [
            {
              accountType: 'Candidate Contact',
              name: selected.candidate._name,
              email: selected.candidate.email
            }
          ],
          cc: YES__CALIBRATION ? [] : [
            {
              accountType: 'User',
              name: Core.getUserName(),
              email: Core.getSessionEmail()
            },
            TEN_BY_TEN_RECRUITER_EMAIL_OBJECT
          ],
          templateBar: (
            <EmployerTemplateBar
              employerId={employer.id}
              templateId={emailTemplateRecord.templateId}
              onChange={async template => {
                getControllerMessageEmployerToCandidateEmailPreview().setTemplate(template);
                Object.assign(emailTemplateRecord, {
                  templateId: template.id,
                  subject: template.subject,
                  body: template.body
                });
                await updateState();
              }}
              macros={{
                [EMPLOYER_MANAGE_TEMPLATES_TOKEN__MY_FIRSTNAME]: userFirstname,
                [EMPLOYER_MANAGE_TEMPLATES_TOKEN__CANDIDATE__NICKNAME]: selected.candidate.nickName,
                [EMPLOYER_MANAGE_TEMPLATES_TOKEN__CANDIDATE__FIRSTNAME]: selected.candidate.firstName,
                [EMPLOYER_MANAGE_TEMPLATES_TOKEN__CANDIDATE__LASTNAME]: selected.candidate.lastName,
                [EMPLOYER_MANAGE_TEMPLATES_TOKEN__EMPLOYER_NAME]: employer.name,
              }}
            />
          ),
          subject: compileText(
            trim(emailTemplateRecord.subject),
            { EMPLOYER__NAME: employer.name || '[ employer_name ]' }
          ),
          prefix,
          // greetings,
          body: compile(
            join([
              patchOldParagraphFormat(emailTemplateRecord.body),
              patchOldParagraphFormat(appendText)
            ], '')
          )({
            USER__FIRSTNAME: userFirstname || '[ your name ]'
          }),
          copyText: `$to $from $subject $body`,
          showFrom: true,
          panel: EMAIL_DIALOG_PANEL__EDIT,
          enablePanels: true,
          onUserEdit: _onUserEdit
        })}
      />
      <div className='bg-white sticky-bottom p-1'>
        <Button small
          className='min-w-160'
          disabled={YES__DISABLED__BUTTON__SEND}
          onClick={async event => {
            await updateState({ busy: true });

            const YES__EMAIL__EDITED = !!selected.emailTemplateRecord;
            const OBJ__EMAIL__EDITED = Obj(selected.emailTemplateRecord);
            const OBJ__EMAIL__CURRENT = Obj(Fun(getControllerMessageEmployerToCandidateEmailPreview().getParams)());

            const YES__ERROR__DETECTED = (
              YES__EMAIL__EDITED &&
              (
                OBJ__EMAIL__EDITED.body !== OBJ__EMAIL__CURRENT.html
              )
            );

            if (YES__ERROR__DETECTED) {
              const error = 'Memory error. Please edit the email and try again.';
              Core.failure({
                source: 'MessageEmployerToCandidate',
                exception: error,
                omitUIMessage: true
              });
              Core.showError(error);
              await updateState({ busy: false });
              return;
            }

            await sendSafeEmail({
              ...OBJ__EMAIL__CURRENT,
              html: join([
                !!prefix && `${prefix}<hr/>`,
                !!greetings && `<div>${greetings}</div>`,
                OBJ__EMAIL__CURRENT.html
              ], ''),
              boxKey: selected.boxKey,
              source: 'Email.lib.js'
            });
            await _onSend(OBJ__EMAIL__CURRENT);
            selected.emailSent = OBJ__EMAIL__CURRENT;
            await updateState({ busy: false });
          }}
        >
          {
            (
              YES__BUSY
            ) ? (
              <Loader
                className='c-white'
              >
                Sending...
              </Loader>
            ) : (
              YES__EMAIL_SENT &&
              YES__DISABLED__BUTTON__SEND
            ) ? (
              'Email sent'
            ) : (
              'Send'
            )
          }
        </Button>
      </div>
    </>
  );
}

export function getControllerMessageEmployerToCandidateEmailPreview() {
  return Obj(Core.getKeyValue('MessageEmployerToCandidate_EmailPreview'));
}

export function setControllerMessageEmployerToCandidateEmailPreview(controller) {
  Core.setKeyValue('MessageEmployerToCandidate_EmailPreview', controller);
}

export function patchOldParagraphFormat(html) {
  return joinHTML(html)
    .replace(/<p>/gi, '')
    .replace(/,<\/p>/gi, ',<br/>')
    .replace(/<\/p>/gi, '<br/><br/>');
}
