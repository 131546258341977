import moment from 'moment';
import AppUI from '../../dictionaries/AppUI.dic';
import {
  STAGE_ONSITE,
  STAGE_REVIEW,
  STAGE_SCREEN,
  STATUS_W_CANDIDATE_SUBMIT_FORM,
  STATUS_W_ONSITE, STATUS_W_SCREEN
} from '../../dictionaries/Engagement.dic';
import Core from '../Core';
import getInterviewLabel from '../Engagement/getInterviewLabel.fun';
import globalErrorHandler from '../Error/globalErrorHandler.fun';
import {
  COLLECTION__ENGAGEMENTS,
  readLoopbackRecord
} from '../services/BE/loopback.api';
import {
  openMessageEmailPreview
} from '../services/Email/Email.lib';
import addStreakNote from '../Streak/addStreakNote.fun';
import {
  compileText,
  Str
} from '../String.lib';

/**
 * @description
 * Opens a dialog to send a tip to a candidate.
 * The dialog will be opened only if the engagement is in a valid state.
 * The valid states are:
 * - The engagement is in a review or interview stage
 * - The engagement status is either:
 *   - The candidate has submitted a homework
 *   - The candidate is scheduled for an interview
 *   - The candidate has an onsite interview
 * @param {Object} [options]
 * @param {Object} [options.engagement={}] - The engagement record
 * @param {boolean} [options.forceOpen=false] - If true, the dialog will be opened even if the engagement is not in a valid state
 */
export default async function openCandidateTipEmailPreview({
  engagement = {},
  forceOpen = false
}) {
  try {
    Object.assign(
      engagement,
      await readLoopbackRecord({
        collection: COLLECTION__ENGAGEMENTS,
        where: { id: engagement.id },
        fields: [
          'id',
          'stage',
          'status',
          'boxKey',
          'screen1',
          'screen2',
          'screen3',
          'onsite1',
          'onsite2',
        ],
        limit: 1
      })
    );
    const YES__STAGE__REVIEW_OR_INTERVIEW = [
      STAGE_REVIEW,
      STAGE_SCREEN,
      STAGE_ONSITE
    ].includes(engagement.stage);
    const YES__STATUS__VALID = [
      STATUS_W_CANDIDATE_SUBMIT_FORM,
      STATUS_W_SCREEN,
      STATUS_W_ONSITE
    ].includes(engagement.status)
    if (
      forceOpen || (
        YES__STAGE__REVIEW_OR_INTERVIEW &&
        YES__STATUS__VALID
      )
    ) {
      const interviewLabel = Str(getInterviewLabel({ engagement }));
      const type = {
        [STATUS_W_CANDIDATE_SUBMIT_FORM]: 'Homework',
        [STATUS_W_SCREEN]: interviewLabel,
        [STATUS_W_ONSITE]: interviewLabel,
      }[engagement.status] || 'Interview';
      const context = {
        DATE: moment().format('MM/DD'),
        TYPE: type,
        TYPE__LOWERCASE: Str(type).toLowerCase()
      };
      Core.hideMessage();
      openMessageEmailPreview({
        title: compileText('Send {{TYPE__LOWERCASE}} tip', context),
        ...engagement,
        recruiterId: engagement.candidate.accountId,
        employerId: engagement.job.employerId,
        engagementId: engagement.id,
        toCandidate: true,
        filterSnippets: 'tip',
        body: `Hi ${engagement.candidate.nickName || engagement.candidate.firstName}<br/><br/>`,
        onSuccess: async ({ response }) => {
          try{
            if (
              forceOpen ||
              YES__STATUS__VALID
            ) {
              await addStreakNote({
                boxKey: engagement.boxKey,
                note: compileText('{{DATE}} - {{TYPE}} tip sent', context)
              });
            }
            Core.showSuccess(AppUI.success.email);
          }
          catch (error) {
            globalErrorHandler(error);
          }
        }
      });
    }
  }
  catch (error) {
    globalErrorHandler(error);
  }
}
