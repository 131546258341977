// CAVE

import {
  joinHTML
} from '../../HTML.lib';

/**
 * Template for the subject line of a candidate representation email.
 *
 * @param {string} CANDIDATE__FULLNAME  [plaintext] The full name of the candidate.
 * @param {string} EMPLOYER__NAME  [plaintext] The name of the employer the candidate is interested in.
 */
export const TEMPLATE__CANDIDATE__CAVE__SUBJECT = `{{CANDIDATE__FULLNAME}} , thank you for your interest in {{EMPLOYER__NAME}}`;

/**
 * HTML template for an email to the candidate when a recruiter is interested in their application.
 *
 * @param CANDIDATE__NAME  [plaintext] The first name of the candidate.
 * @param CANDIDATE__EMAIL  [plaintext] The email address of the candidate.
 * @param CANDIDATE__PHONE  [plaintext] The phone number of the candidate.
 * @param RECRUITER__NAME  [plaintext] The name of the recruiter.
 * @param EMPLOYER__NAME  [plaintext] The name of the employer.
 * @param EMPLOYER__LINK  [html/text] Clickable link to the employer's website (just the name).
 * @param JOB__LINK  [html/text] Clickable link to the job description (just the title).
 * @param JOB__TITLE  [plaintext] The title of the job.
 * @param SIGNATURE  [html/text] The signature of the email sender (10 BY 10 team).
 */
export const TEMPLATE__CANDIDATE__CAVE__BODY = joinHTML(`
Hi {{CANDIDATE__NAME}},
<br/><br/>
Great to connect! I'm Taylor from 10by10. {{RECRUITER__NAME}} connected us, and I was really impressed with your background and have expedited your profile to the hiring team. Just confirming:
<br/><br/>
Role: {{{JOB__LINK}}} at {{{EMPLOYER__LINK}}}<br/>
Email: {{CANDIDATE__EMAIL}}<br/>
Phone: {{CANDIDATE__PHONE}}
<br/><br/>
Our partnership combines {{RECRUITER__NAME}}’s candidate expertise with 10by10’s employer network to bring you additional great opportunities.
<br/><br/>
I’m representing you to {{EMPLOYER__NAME}}. {{RECRUITER__NAME}} is your main contact for interview prep and updates. We are both here to help you succeed in your interviews.
<br/><br/>
{{EMPLOYER__NAME}} may contact you directly for interviews. If you hear the good news first, please let us know so we can provide you interview tips!
<br/><br/>
First tip - Research the role or let us know your questions. {{EMPLOYER__NAME}} will focus the interview on your experience. Candidates who show genuine interest and are well-prepared tend to stand out and advance further.
<br/><br/>
Lastly, if asked about your recruiter, please refer to Taylor at 10by10 as your representative.
<br/><br/>
Looking forward to supporting your success!
<br/><br/>
Best regards,
<br/><br/>
{{{SIGNATURE}}}
`);
