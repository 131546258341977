import {
  TextField
} from '@mui/material';
import {
  NOT
} from '../../../lib/Boolean.lib';
import {
  ENGAGEMENT__MATCH_OPTIONS,
  STAGE_SUBMISSION
} from '../../../dictionaries/Engagement.dic';
import Core from '../../../lib/Core';
import Definition, {
  DEFINITION_CATEGORY__ENGAGEMENT_MATCH_STRENGTH
} from '../../../lib/Definition';
import {
  Fun
} from '../../../lib/Function.lib';
import useState from '../../../lib/hooks/useState.hook';
import {
  Obj
} from '../../../lib/Object.lib';
import {
  joinKeyName, Str
} from '../../../lib/String.lib';
import onReady from '../../../lib/tools/onReady';
import {
  matchLocation
} from '../../../lib/URL.lib';
import Fieldset from '../../Layout/Wrappers/Fieldset';
import Menu from '../../Layout/Wrappers/Menu';
import AnswerJobsQuestions from '../../PrescreenQuestions/AnswerJobsQuestions';
import {
  getMatches,
  KEY__CON__SINGLE_CARD,
  onMatch
} from '../Libraries/MatchList.lib';
import notFoundInPermittedJobWithOwnership from '../Libraries/Tools/notFoundInPermittedJobWithOwnership.tool';
import {
  saveCandoPrivateNoteAddition
} from './ListHeader';
import {
  showErrorUnexpectedMatchStrength
} from './ThumbsBar';

export const KEY__MATCH_OPTIONS_MENU = 'match_options_menu';
export const KEY__ANSWER_JOB_QUESTIONS_DIALOG = joinKeyName(
  [
    KEY__MATCH_OPTIONS_MENU,
    'answer_job_questions_dialog'
  ]
);


export default function MatchOptionsMenu({
  matchOptionsMenu = {}
}) {

  const _defaultState = {
    open: false,
    anchorEl: null
  };

  const [{
    open = false,
    anchorEl = null,
    // ListHeader | SingleJobCard | SingleCandidateCard
    Controller = {},
    candoPrivateNoteText = '',
    handlerNextAnswers = () => null,
    matchStrength = -1,
  }, _updateState] = useState(_defaultState);

  const {
    profile = {},
    match = {},
  } = Obj(Controller.props);

  const {
    engagement = {},
    isAlreadyOwnIt = false,
  } = Obj(Controller.state);

  Core.setKeyValue(KEY__MATCH_OPTIONS_MENU,
    Object.assign(matchOptionsMenu,
      {
        async open(event, options) {
          await _updateState({
            open: true,
            anchorEl: event.currenTarget || event.target,
            ...options
          });
        },
        async close(event) {
          await _updateState(
            _defaultState
          );
        }
      }
    )
  );


  let options = [...ENGAGEMENT__MATCH_OPTIONS];
  if (NOT(isAlreadyOwnIt)) {
    options = options.filter(
      (option) => NOT(Str(option).includes(STAGE_SUBMISSION))
    );
  }

  const _mapOption = (option) => (
    {
      id: option,
      label: option,
      onClick: async (event) => {
        const matchStrengthTag = Definition.getTag({
          categoryKey: DEFINITION_CATEGORY__ENGAGEMENT_MATCH_STRENGTH,
          tagId: matchStrength
        });
        if (NOT(matchStrengthTag.id)) {
          return showErrorUnexpectedMatchStrength(
            matchStrength,
            matchStrengthTag.id
          );
        }
        await matchOptionsMenu.close(event);
        const newEngagement = {
          stage: option.split(',')[0].trim(),
          status: option.split(',')[1].trim(),
          matchStrength: matchStrengthTag.id,
          matchStrengthLabel: matchStrengthTag.label,
          [KEY__CON__SINGLE_CARD]: match[KEY__CON__SINGLE_CARD]
        };
        const validationMsg = notFoundInPermittedJobWithOwnership({
          profile,
          match,
          newEngagement
        });
        if (validationMsg) {
          Core.showWarning(validationMsg);
        }
        else if (matchLocation('/candidate/match')) {
          await _updateState({
            handlerNextAnswers: () => onMatch(newEngagement)
          })
          await onReady(matchOptionsMenu, KEY__ANSWER_JOB_QUESTIONS_DIALOG);
          Fun(matchOptionsMenu[KEY__ANSWER_JOB_QUESTIONS_DIALOG].open)(event);
        }
        else {
          onMatch(newEngagement);
        }
      }
    }
  )

  return (
    <>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={matchOptionsMenu.close}
        options={options.map(_mapOption)}
      />
      <AnswerJobsQuestions
        name={KEY__ANSWER_JOB_QUESTIONS_DIALOG}
        reference={
          (self) => (
            Object.assign(matchOptionsMenu,
              {
                [KEY__ANSWER_JOB_QUESTIONS_DIALOG]: self
              }
            )
          )
        }
        candidate={profile}
        jobIds={getMatches().map((match) => match.id)}
        onAcceptLabel='Proceed'
        onAccept={({
          jobsPermittedAnswers
        }) => async (event) => {
          await _updateState({ jobsPermittedAnswers });
          await saveCandoPrivateNoteAddition({
            candidate: profile,
            candoPrivateNoteText,
            jobsPermittedAnswers,
            engagement
          });
          handlerNextAnswers(jobsPermittedAnswers);
        }}
        onCancelLabel='Cancel'
        onCancel={(update) => async (event) => await _updateState(update)}
        extraComponentStructure={
          <Fieldset row
            title="Text to be added in Candidate's private note"
          >
            <TextField
              placeholder="Add your free comments"
              onChange={
                async (event) => await _updateState(
                  {
                    candoPrivateNoteText: event.target.value
                  }
                )
              }
              value={candoPrivateNoteText}
              fullWidth
            />
          </Fieldset>
        }
      />
    </>
  );
}

export function MatchOptionsMenuController() {
  return Obj(Core.getKeyValue(KEY__MATCH_OPTIONS_MENU));
}
