import {
  CircularProgress
} from '@mui/material';
import {
  compile
} from 'handlebars';
import {
  debounce,
  delay
} from 'lodash';
import {
  KEY__TABLE_COLLECTION__VIEW_TYPE__CANDIDATE,
  KEY__TABLE_COLLECTION__VIEW_TYPE__COMMON,
  KEY__TABLE_COLLECTION__VIEW_TYPE__EMPLOYER,
  KEY__TABLE_COLLECTION__VIEW_TYPE__JOB,
  KEY__TABLE_COLLECTION__VIEW_TYPE__RECRUITER
} from '../../../dictionaries/TableCollectionKeys.dic';
import {
  Arr,
  join
} from '../../../lib/Array.lib';
import {
  NOT
} from '../../../lib/Boolean.lib';
import {
  MDASH
} from '../../../lib/Constants';
import Core from '../../../lib/Core';
import {
  formatDate
} from '../../../lib/Date.lib';
import Definition, {
  ACCOUNT_ACTION__EDIT_ACCOUNT,
  ACCOUNT_ACTION__EDIT_CANDIDATE,
  ACCOUNT_ACTION__EDIT_EMPLOYER,
  ACCOUNT_ACTION__LIST_JOBS,
  DEFINITION_CATEGORY__NEGATIVE_SIGNALS,
  DEFINITION_CATEGORY__POSITIVE_SIGNALS
} from '../../../lib/Definition';
import globalErrorHandler from '../../../lib/Error/globalErrorHandler.fun';
import {
  copyString
} from '../../../lib/GenericTools.lib';
import useStateObserved from '../../../lib/hooks/useStateObserved.hook';
import {
  getLabelsLatestEngagementFeedbackReasons
} from '../../../lib/models/EngagementFeedback.model';
import {
  getPersonName
} from '../../../lib/Object.lib';
import QUERY__REPORTS__ENGAGEMENTS from '../../../lib/queries/ReportsEngagements.query';
import {
  COLLECTION__ENGAGEMENTS,
  readLoopbackRecord
} from '../../../lib/services/BE/loopback.api';
import {
  openMessageEmailPreview,
  sendSafeEmail
} from '../../../lib/services/Email/Email.lib';
import {
  compileText,
  joinKeyName,
  trim
} from '../../../lib/String.lib';
import {
  TEMPLATE__BODY__COLLECTION_USER_AGREE_TO_TERMS,
  TEMPLATE__SUBJECT__COLLECTION_USER_AGREE_TO_TERMS
} from '../../../lib/templates/CollectionUserAgreeToTerms.template';
import {
  getVariableFromURLQuery
} from '../../../lib/URLQuery.lib';
import {
  joinClassName
} from '../Libraries/Theme.lib';
import {
  noUserAgreeToEngagementsTerms,
  setUserAgreeToEngagementsTerms,
  unsetUserAgreeToEngagementsTerms,
  yesUserAgreeToEngagementsTerms
} from '../Libraries/UserAgreeEngagementsTerms.lib';
import Box from './Box';
import Button from './Button';
import Card from './Card';
import Checkbox from './Checkbox';
import Chip from './Chip';
import Fieldset from './Fieldset';
import Icon from './Icon';
import IconButton from './IconButton';
import {
  PLACEMENT__TOP_START
} from './StyledTooltip';
import Switch from './Switch';
import Table from './Table';
import TextField from './TextField';
import Typography, {
  Label
} from './Typography';



const COL__COMMON__CLASSNAME = 'p-05 px-1 border-bottom';
const COL__HEADER__CLASSNAME = joinClassName([
  COL__COMMON__CLASSNAME,
  'sticky-top bg-cyan-common c-white-all',
]);


/**
 *
 * @param {Object} props
 * @param {string} [props.title] title of the table
 * @param {Object} [props.actionBar] action bar component
 * @param {Object} [props.context]
 * Used set a reference outside component reference with 
 * the current instance of the exports object. 
 * ex. context.TableCollection = TableCollection;
 * then you can call context.TableCollection.updateState({});
 * @param {Object} [props.wrapperProps] wrapper properties
 *
 * @param {Object} props.defaultQuery default query
 * @param {string} [props.collection=COLLECTION__ENGAGEMENTS] collection name
 * @param {Object} [props.where={}] where filter
 *
 * @param {Function} [props.mapper] data mapper function
 * @param {Function} [props.filter] data filter function
 * @param {Function} [props.filterColumns] filter columns function
 *
 * @param {boolean} [props.isDrawer=false] is drawer flag
 *
 * @returns {JSX.Element} TableCollection component
 * 
 * @todo Make this component generic and move Engagements features to a separate component.
 */
export default function TableCollection({
  /** CONFIGURATION QUERY */
  defaultQuery = QUERY__REPORTS__ENGAGEMENTS, // ex. { fields: ['id'],include: [{ relation: 'candidate', scope: { fields: ['id'] } }] }
  collection = COLLECTION__ENGAGEMENTS,
  where: propsWhere = {},

  /** LOCAL DATA CUSTOMIZATION */
  mapper = (engagements) => Arr(engagements).map(
    (engagement) => {
      return {
        ...engagement,
        ID__ENGAGEMENT: engagement.id,
        ID__RECRUITER: engagement.candidate?.accountId,
        ID__EMPLOYER: engagement.job?.employerId,
        ID__JOB: engagement.jobId,
        NAME__CANDIDATE: getPersonName(engagement.candidate),
        NAME__EMPLOYER: engagement.job?.employer?.name,
        NAME__JOB: engagement.job?.jobTitle,
        NAME__RECRUITER: getPersonName(engagement.candidate?.account),
        VALUE__STAGE: engagement.stage,
        VALUE__STATUS: engagement.status,
        VALUE__STATE: engagement.state,
        VALUE__REJECTION_REASON: engagement.rejectionReason,
        VALUE__REJECTION_REASON__ADDITIONAL_INFO: engagement.rejectionReasonAdditionalInfo,
        VALUE__FEEDBACK_TO_10X10: engagement.empFBText,
        VALUE__FEEDBACK_TO_RECRUITER: engagement.toRecruiterFBText,
        VALUES__STRING__FEEDBACK: getLabelsLatestEngagementFeedbackReasons(engagement),
        VALUES__POSITIVE_SIGNALS: Definition.getLabels(
          DEFINITION_CATEGORY__POSITIVE_SIGNALS,
          engagement.candidate?.positiveSignals
        ),
        VALUES__NEGATIVE_SIGNALS: Definition.getLabels(
          DEFINITION_CATEGORY__NEGATIVE_SIGNALS,
          engagement.candidate?.negativeSignals
        ),
        DATE__SHORT__INTRODUCED: formatDate(engagement.introduced),
        DATE__SHORT__MATCHED: formatDate(engagement.matched),
        DATE__SHORT__SUBMITTED: formatDate(engagement.submitted),
        DATE__SHORT__REVIEWED: formatDate(engagement.reviewed),
        DATE__SHORT__SCREENED: formatDate(engagement.screened),
        DATE__SHORT__SCREEN_1: formatDate(engagement.screen1),
        DATE__SHORT__SCREEN_2: formatDate(engagement.screen2),
        DATE__SHORT__SCREEN_3: formatDate(engagement.screen3),
        DATE__SHORT__ONSITE: formatDate(engagement.onsite),
        DATE__SHORT__ONSITE_1: formatDate(engagement.onsite1),
        DATE__SHORT__ONSITE_2: formatDate(engagement.onsite2),
        DATE__SHORT__OFFERED: formatDate(engagement.offered),
        URL__CANDIDATE__EDIT: `/candidate/edit/${engagement.candidateId}`,
        URL__ENGAGEMENT__VIEW: `/engagement/view/${engagement.id}`,
        URL__EMPLOYER__EDIT: `/employer/edit/${engagement.job?.employerId}`,
        URL__JOB__VIEW: `/job/view/${engagement.jobId}`,
        URL__RECRUITER__EDIT: `/account/edit/${engagement.candidate?.accountId}`,
      }
    }
  ),
  filter: propsFilter = (data) => Arr(data), // ex. data.filter(context => context.ID__RECRUITER === Core.getAccountId()),

  /** @todo */
  filterColumns = (row) => ({}),

  /** CONFIGURATION */
  viewType: propsViewType = KEY__TABLE_COLLECTION__VIEW_TYPE__COMMON,

  /** FLAGS */
  isDrawer = false,

  /** WRAPPER PROPS */
  title: propsTitle = null,
  actionBarBottomRight = null,
  context = {},
  wrapperProps = {},
  ...props
}) {

  // STATE LOGIC
  // set "ready" to false when "where" is updated from props.
  const [{
    filter = propsFilter,
    ...state
  }, updateState] = useStateObserved({
    observed: { where: propsWhere },
    state: {
      flagShowDates: false,
      viewType: propsViewType,
      title: propsTitle,
    },
    onChange: () => updateState({ ready: false })
  });

  // DATA SOURCE LOGIC
  debounce(async () => {
    if (NOT(state.busy || state.error || state.ready) && !!Object.keys(state.where).length) {
      await updateState({ busy: true });
      try {
        await updateState({
          data: Arr(
            await readLoopbackRecord({
              ...defaultQuery,
              collection,
              where: state.where,
              filter,
              mapper,
            })
          ),
        });
        await updateState({ ready: true });
      }
      catch (error) {
        globalErrorHandler(error);
        await updateState({ error });
      }
      await updateState({ busy: false });
    }
  })();

  // EXPORTS (state, methods, etc)
  Object.assign(
    context,
    {
      TableCollection: {
        state,
        updateState
      }
    }
  );

  const _generateSummary = () => Arr(state.data).map(
    (model) => '- ' + join([
      model.NAME__CANDIDATE,
      model.NAME__EMPLOYER,
      model.NAME__JOB,
      model.VALUE__STAGE,
      model.VALUE__STATUS,
      model.VALUE__REJECTION_REASON,
      model.VALUE__REJECTION_REASON__ADDITIONAL_INFO,
      join(model.VALUES__NEGATIVE_SIGNALS),
      trim(model.VALUE__FEEDBACK_TO_RECRUITER).replace(/[\n\r]/g, ' ')
    ], ' | ')
  ).join('\n\n');

  // FLAGS – YES
  const YES__BUSY = !!state.busy;
  const YES__EMPTY = !!state.ready && NOT(Arr(state.data).length);
  const YES__VIEW_TYPE__CANDIDATE = state.viewType === KEY__TABLE_COLLECTION__VIEW_TYPE__CANDIDATE;
  const YES__VIEW_TYPE__RECRUITER = state.viewType === KEY__TABLE_COLLECTION__VIEW_TYPE__RECRUITER;
  const YES__VIEW_TYPE__JOB = state.viewType === KEY__TABLE_COLLECTION__VIEW_TYPE__JOB;
  const YES__VIEW_TYPE__EMPLOYER = state.viewType === KEY__TABLE_COLLECTION__VIEW_TYPE__EMPLOYER;
  const YES__SHOW__RECRUITER_SUMMARY = !!state.showRecruiterSummary;
  const YES__ADMIN_USER = Core.isAdmin();
  const YES__SHOW__COLUMN__DATES = !!state.flagShowDates;
  const YES__USER_AGREE_TO_TERMS = yesUserAgreeToEngagementsTerms();

  // FLAGS – NOT
  const NOT__VIEW_TYPE__CANDIDATE = NOT(YES__VIEW_TYPE__CANDIDATE);
  const NOT__VIEW_TYPE__RECRUITER = NOT(YES__VIEW_TYPE__RECRUITER);
  const NOT__VIEW_TYPE__JOB = NOT(YES__VIEW_TYPE__JOB);
  const NOT__VIEW_TYPE__EMPLOYER = NOT(YES__VIEW_TYPE__EMPLOYER);
  const NOT__USER_AGREE_TO_TERMS = noUserAgreeToEngagementsTerms();

  // FLAGS – YES (conditional cases)
  const YES__SHOW__COLUMN__RECRUITER = NOT__VIEW_TYPE__RECRUITER && YES__ADMIN_USER;
  const YES__DISPLAY__TABLE = Core.isAdmin() || YES__USER_AGREE_TO_TERMS;

  const engagementId = getVariableFromURLQuery('engagementId');
  if (YES__VIEW_TYPE__JOB && engagementId) {
    const _getKey = (model) => Number(model.ID__ENGAGEMENT === engagementId);
    state.data = Arr(state.data).sort((a, b) => (_getKey(b) - _getKey(a)));
  }

  // VALUES
  const VALUE__RECRUITER_SUMMARY = (
    (state.recruiterSummary !== undefined)
      ? state.recruiterSummary
      : _generateSummary()
  );

  const ELEMENT__TOP_BAR__RIGHT_SIDE = YES__DISPLAY__TABLE && (
    <Box row noWrap scroll
      style={{ maxHeight: 'calc(100vh - 10rem)' }}
    >
      <Switch small
        checked={YES__SHOW__COLUMN__DATES}
        onChange={(event) => updateState({
          flagShowDates: !YES__SHOW__COLUMN__DATES
        })}
        label={
          YES__SHOW__COLUMN__DATES
            ? 'Hide dates'
            : 'Show dates'
        }
        className='mr-1'
      />
      <IconButton
        acl={isDrawer}
        icon='arrow_forward_ios'
        onClick={(event) => Core.closeDrawer()}
      />
    </Box>
  );

  const ELEMENT__TOP_BAR = (
    <Box row flex1
      className='sticky-top bg-white border-bottom space-between p-1'
      style={{ minHeight: '3rem', zIndex: 3 }}
    >
      {/** TITLE */}
      <Box noWrap scroll flex1>
        <Typography className='f-xxxl c-purple flex-align-left-center px-1'>
          {state.title}
        </Typography>
      </Box>

      {/** ACTIONS BAR - TOP-RIGHT */}
      {ELEMENT__TOP_BAR__RIGHT_SIDE}

    </Box>
  );

  // RENDER
  return (
    (
      YES__DISPLAY__TABLE
    ) ? (
      <Box column flex1>

        {/** TOP BAR */}
        {ELEMENT__TOP_BAR}

        {/** TABLE */}
        <Box row scroll flex1>
          <Table styled>
            <Table.Head>
              <Table.Row>
                <Table.Cell
                  className={joinClassName([
                    'sticky-start',
                    COL__HEADER__CLASSNAME
                  ])}
                  style={{ zIndex: 2 }}
                >
                  #
                </Table.Cell>
                <Table.Cell className={COL__HEADER__CLASSNAME}>
                  Action
                </Table.Cell>

                {/** YES - OTHER VIEW TYPES THAN JOB */}
                {NOT__VIEW_TYPE__JOB &&
                  <>
                    {/** YES - OTHER VIEW TYPES THAN EMPLOYER */}
                    {NOT__VIEW_TYPE__EMPLOYER &&
                      <Table.Cell className={COL__HEADER__CLASSNAME}>
                        Employer
                      </Table.Cell>
                    }
                    <Table.Cell className={COL__HEADER__CLASSNAME}>
                      Job
                    </Table.Cell>
                  </>
                }

                {/** YES - OTHER VIEW TYPES THAN CANDIDATE */}
                {NOT__VIEW_TYPE__CANDIDATE &&
                  <>
                    <Table.Cell className={COL__HEADER__CLASSNAME}>
                      Candidate
                    </Table.Cell>
                    {/** YES - OTHER VIEW TYPES THAN RECRUITER */}
                    {YES__SHOW__COLUMN__RECRUITER &&
                      <Table.Cell className={COL__HEADER__CLASSNAME}>
                        Recruiter
                      </Table.Cell>
                    }
                  </>
                }

                <Table.Cell className={COL__HEADER__CLASSNAME}>
                  Stage/Status
                </Table.Cell>
                {YES__SHOW__COLUMN__DATES &&
                  <>
                    <Table.Cell className={COL__HEADER__CLASSNAME}>
                      Introduced
                    </Table.Cell>
                    <Table.Cell className={COL__HEADER__CLASSNAME}>
                      Matched
                    </Table.Cell>
                    <Table.Cell className={COL__HEADER__CLASSNAME}>
                      Submitted
                    </Table.Cell>
                    <Table.Cell className={COL__HEADER__CLASSNAME}>
                      Reviewed
                    </Table.Cell>
                    <Table.Cell className={COL__HEADER__CLASSNAME}>
                      Screened
                    </Table.Cell>
                    <Table.Cell className={COL__HEADER__CLASSNAME}>
                      Onsite
                    </Table.Cell>
                    <Table.Cell className={COL__HEADER__CLASSNAME}>
                      Offered
                    </Table.Cell>
                  </>
                }

                {YES__ADMIN_USER &&
                  <Table.Cell className={COL__HEADER__CLASSNAME}>
                    <Box row>
                      <Icon mr icon='lock'
                        acl={YES__ADMIN_USER}
                        className='icon16 c-white'
                        placement={PLACEMENT__TOP_START}
                        title='Raw 10x10/Employer feedback (rejectionReasonAdditionalInfo)'
                      />
                      Why No
                    </Box>
                  </Table.Cell>
                }

                {/** ONLY FOR ADMIN */}
                {YES__ADMIN_USER && (
                  <Table.Cell className={COL__HEADER__CLASSNAME}>
                    <Box row>
                      <Icon mr icon='lock'
                        acl={YES__ADMIN_USER}
                        className='icon16 c-white'
                        placement={PLACEMENT__TOP_START}
                        title='Raw employer feedback (empFBTxt)'
                      />
                      10x10 only FB
                    </Box>
                  </Table.Cell>
                )}

                <Table.Cell className={COL__HEADER__CLASSNAME}>
                  <Box row>
                    <Icon mr icon='visibility'
                      acl={YES__ADMIN_USER}
                      className='icon16 c-white'
                      placement={PLACEMENT__TOP_START}
                      title='Admin approved feedback (toRecruiterFBTxt)'
                    />
                    To Recruiter FB
                  </Box>
                </Table.Cell>

                <Table.Cell className={COL__HEADER__CLASSNAME}>
                  <Box row>
                    <Icon mr icon='visibility'
                      acl={YES__ADMIN_USER}
                      className='icon16 c-white'
                      placement={PLACEMENT__TOP_START}
                      title='Visible to all users'
                    />
                    Negative Signals
                  </Box>
                </Table.Cell>

                <Table.Cell className={COL__HEADER__CLASSNAME}>
                  <Box row>
                    <Icon mr icon='visibility'
                      acl={YES__ADMIN_USER}
                      className='icon16 c-white'
                      placement={PLACEMENT__TOP_START}
                      title='Visible to all users'
                    />
                    Positive Signals
                  </Box>
                </Table.Cell>

              </Table.Row>
            </Table.Head>
            <Table.Body>

              {/** INDICATOR: LOADING */}
              {YES__BUSY &&
                <Table.Row>
                  <Table.Cell colSpan={13} className='text-muted'>
                    <CircularProgress className='m-2' />
                  </Table.Cell>
                </Table.Row>
              }

              {/** INDICATOR: EMPTY */}
              {YES__EMPTY &&
                <Table.Row>
                  <Table.Cell colSpan={13} className='text-muted placeholder'>
                    No engagements found
                  </Table.Cell>
                </Table.Row>
              }

              {/** DATA */}
              {Arr(state.data).map((
                model,
                index
              ) => (
                <Table.Row
                  key={
                    joinKeyName([
                      model.URL__ENGAGEMENT__VIEW,
                      index
                    ])
                  }
                  className={joinClassName([
                    (model.VALUE__STATE === 'Closed') && 'opacity-50',
                  ])}
                >

                  {/** INDEX */}
                  <Table.Cell className={joinClassName(['align-center sticky-start', COL__COMMON__CLASSNAME])}>
                    {index + 1}
                  </Table.Cell>

                  {/** ACTION */}
                  <Table.Cell className={joinClassName(['align-center', COL__COMMON__CLASSNAME])}>
                    <IconButton primary icon='visibility'
                      onClick={(event) => Core.openPopUp(model.URL__ENGAGEMENT__VIEW, 1600)}
                      title='Click to see engagement details'
                      placement={PLACEMENT__TOP_START}
                    />
                  </Table.Cell>

                  {/** YES - OTHER VIEW TYPES THAN JOB */}
                  {NOT__VIEW_TYPE__JOB &&
                    <>
                      {/** EMPLOYER */}
                      {/** YES - OTHER VIEW TYPES THAN EMPLOYER */}
                      {NOT__VIEW_TYPE__EMPLOYER &&
                        <Table.Cell className={COL__COMMON__CLASSNAME}>
                          <Button flat primary minW120
                            onClick={(event) => Core.openPopUp(model.URL__EMPLOYER__EDIT, 1600)}
                            disabled={NOT(Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_EMPLOYER }))}
                            className='align-left'
                          >
                            {model.NAME__EMPLOYER}
                          </Button>
                        </Table.Cell>
                      }

                      {/** JOB */}
                      <Table.Cell className={COL__COMMON__CLASSNAME}>
                        <Button flat primary minW120
                          onClick={(event) => Core.openPopUp(model.URL__JOB__VIEW, 1600)}
                          disabled={NOT(Core.isAdmin({ action: ACCOUNT_ACTION__LIST_JOBS }))}
                          className='align-left'
                        >
                          {model.NAME__JOB}
                        </Button>
                      </Table.Cell>
                    </>
                  }

                  {/** YES - OTHER VIEW TYPES THAN CANDIDATE */}
                  {NOT__VIEW_TYPE__CANDIDATE &&
                    <>
                      {/** CANDIDATE */}
                      <Table.Cell className={COL__COMMON__CLASSNAME}>
                        <Button flat primary mr1 minW120
                          onClick={(event) => Core.openPopUp(model.URL__CANDIDATE__EDIT, 1600)}
                          disabled={NOT(Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_CANDIDATE }))}
                          className='align-left'
                        >
                          {model.NAME__CANDIDATE}
                        </Button>
                      </Table.Cell>

                      {/** RECRUITER */}
                      {/** YES - OTHER VIEW TYPES THAN RECRUITER */}
                      {YES__SHOW__COLUMN__RECRUITER &&
                        <Table.Cell className={COL__COMMON__CLASSNAME}>
                          <Button flat primary minW120
                            onClick={(event) => Core.openPopUp(model.URL__RECRUITER__EDIT, 1600)}
                            disabled={NOT(Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_ACCOUNT }))}
                            className='align-left'
                          >
                            {model.NAME__RECRUITER}
                          </Button>
                        </Table.Cell>
                      }
                    </>
                  }

                  {/** STAGE/STATUS */}
                  <Table.Cell
                    className={COL__COMMON__CLASSNAME}
                    style={{ minWidth: 256 }}
                  >
                    <Box column>
                      <Box row flex1>
                        <Chip small className="p-0 px-05">
                          {model.VALUE__STAGE}
                        </Chip>
                        <Chip small>
                          {model.VALUE__STATUS}
                        </Chip>
                      </Box>
                      <Box row wrap
                        acl={
                          model.VALUE__REJECTION_REASON ||
                          model.VALUES__STRING__FEEDBACK.length
                        }
                        className='bg-yellow-lighter p-05 rounded-sm hover-bg'
                      >
                        <Chip small
                          acl={
                            model.VALUE__REJECTION_REASON &&
                            NOT(model.VALUES__STRING__FEEDBACK.length)
                          }
                        >
                          {model.VALUE__REJECTION_REASON}
                        </Chip>
                        {model.VALUES__STRING__FEEDBACK.map(
                          (label, index) => (
                            <Chip small mr1
                              key={joinKeyName([
                                label,
                                index
                              ])}
                            >
                              {label}
                            </Chip>
                          )
                        )}
                      </Box>
                    </Box>
                  </Table.Cell>

                  {YES__SHOW__COLUMN__DATES &&
                    <>
                      {/** DATE INTRODUCED */}
                      <Table.Cell className={COL__COMMON__CLASSNAME}>
                        {model.DATE__SHORT__INTRODUCED}
                      </Table.Cell>

                      {/** DATE MATCHED */}
                      <Table.Cell className={COL__COMMON__CLASSNAME}>
                        {model.DATE__SHORT__MATCHED}
                      </Table.Cell>

                      {/** DATE SUBMITTED */}
                      <Table.Cell className={COL__COMMON__CLASSNAME}>
                        {model.DATE__SHORT__SUBMITTED}
                      </Table.Cell>

                      {/** DATE REVIEWED */}
                      <Table.Cell className={COL__COMMON__CLASSNAME}>
                        {model.DATE__SHORT__REVIEWED}
                      </Table.Cell>

                      {/** DATE SCREENED */}
                      <Table.Cell className={COL__COMMON__CLASSNAME}>
                        <Box column>
                          <div>{model.DATE__SHORT__SCREEN_1}</div>
                          <div>{model.DATE__SHORT__SCREEN_2}</div>
                          <div>{model.DATE__SHORT__SCREEN_3}</div>
                        </Box>
                      </Table.Cell>

                      {/** DATE ONSITE */}
                      <Table.Cell className={COL__COMMON__CLASSNAME}>
                        <Box column>
                          <div>{model.DATE__SHORT__ONSITE_1}</div>
                          <div>{model.DATE__SHORT__ONSITE_2}</div>
                        </Box>
                      </Table.Cell>

                      {/** DATE OFFERED */}
                      <Table.Cell className={COL__COMMON__CLASSNAME}>
                        {model.DATE__SHORT__OFFERED}
                      </Table.Cell>
                    </>
                  }

                  {/** WHY NO */}
                  {Core.isAdmin() &&
                    <Table.Cell className={COL__COMMON__CLASSNAME} style={{ minWidth: 320 }}>
                      {model.VALUE__REJECTION_REASON__ADDITIONAL_INFO || MDASH}
                    </Table.Cell>
                  }

                  {/** FEEDBACK TO 10X10 */}
                  {/** ONLY FOR ADMIN */}
                  {YES__ADMIN_USER && (
                    <Table.Cell className={COL__COMMON__CLASSNAME} style={{ minWidth: 480 }}>
                      {model.VALUE__FEEDBACK_TO_10X10 || MDASH}
                    </Table.Cell>
                  )}

                  {/** FEEDBACK TO RECRUITER */}
                  <Table.Cell className={COL__COMMON__CLASSNAME} style={{ minWidth: 480 }}>
                    {model.VALUE__FEEDBACK_TO_RECRUITER || MDASH}
                  </Table.Cell>

                  {/** NEGATIVE SIGNALS */}
                  <Table.Cell className={COL__COMMON__CLASSNAME}>
                    <Box row wrap style={{ minWidth: 240 }}>
                      {model.VALUES__NEGATIVE_SIGNALS.length === 0 && MDASH}
                      {Arr(model.VALUES__NEGATIVE_SIGNALS).slice(0, 6).map((label, index) => (
                        <Chip small mr1
                          key={joinKeyName([
                            'table_collection',
                            'negative',
                            label,
                            index
                          ])}
                          label={label}
                          className='bg-red-light c-black-medium p-0 px-05'
                        />
                      ))}
                      <Icon
                        acl={Arr(model.VALUES__NEGATIVE_SIGNALS).length > 6}
                        icon='more_horiz'
                        className='icon16 c-gray'
                        placement={PLACEMENT__TOP_START}
                        title={
                          <Box column>
                            <Typography className='f-sm'>
                              {model.NAME__EMPLOYER} - {model.NAME__JOB}
                            </Typography>
                            <Typography className='f-sm'>
                              {model.NAME__CANDIDATE}
                            </Typography>
                            <Box row wrap className='bg-white-common rounded pt-05'>
                              {Arr(model.VALUES__NEGATIVE_SIGNALS).map((label, index) => (
                                <Chip small mr1
                                  key={joinKeyName([
                                    'table_collection',
                                    'negative',
                                    label,
                                    index
                                  ])}
                                  label={label}
                                  className='bg-red-light c-black-medium p-0 px-05'
                                />
                              ))}
                            </Box>
                          </Box>
                        }
                        titleStyle={{
                          maxWidth: 320
                        }}
                      />
                    </Box>
                  </Table.Cell>

                  {/** POSITIVE SIGNALS */}
                  <Table.Cell className={COL__COMMON__CLASSNAME}>
                    <Box row wrap style={{ minWidth: 720 }}>
                      {model.VALUES__POSITIVE_SIGNALS.length === 0 && MDASH}
                      {Arr(model.VALUES__POSITIVE_SIGNALS).slice(0, 6).map((label, index) => (
                        <Chip small mr1
                          key={joinKeyName([
                            'table_collection',
                            'positive',
                            label,
                            index
                          ])}
                          label={label}
                          className='bg-green-light c-black-medium p-0 px-05'
                        />
                      ))}
                      <Icon
                        acl={Arr(model.VALUES__POSITIVE_SIGNALS).length > 6}
                        icon='more_horiz'
                        className='icon16 c-gray'
                        placement={PLACEMENT__TOP_START}
                        title={
                          <Box column>
                            <Typography className='f-sm'>
                              {model.NAME__EMPLOYER} - {model.NAME__JOB}
                            </Typography>
                            <Typography className='f-sm'>
                              {model.NAME__CANDIDATE}
                            </Typography>
                            <Box row wrap className='bg-white-common rounded pt-05'>
                              {Arr(model.VALUES__POSITIVE_SIGNALS).map((label, index) => (
                                <Chip small mr1
                                  key={joinKeyName([
                                    'table_collection',
                                    'positive',
                                    label,
                                    index
                                  ])}
                                  label={label}
                                  className='bg-green-light c-black-medium p-0 px-05'
                                />
                              ))}
                            </Box>
                          </Box>
                        }
                        titleStyle={{
                          maxWidth: 320
                        }}
                      />
                    </Box>
                  </Table.Cell>


                </Table.Row>

              ))}
            </Table.Body>
          </Table>
        </Box>

        {/** BOTTOM BAR */}
        <Box column flex1
          acl={YES__VIEW_TYPE__RECRUITER || actionBarBottomRight}
          className='sticky-bottom bg-white border-top'
        >

          {/** RECRUITER SUMMARY */}
          <Box column flex1
            acl={YES__SHOW__RECRUITER_SUMMARY}
            className='p-1 border-bottom'
          >

            <Fieldset
              title='Recruiter Summary'
            >

              {/** INPUT - RECRUITER SUMMARY */}
              <TextField multiline
                maxRows={6}
                value={VALUE__RECRUITER_SUMMARY}
                onChange={(event, value) => {
                  updateState({ recruiterSummary: value });
                }}
              />

              {/** ACTIONS BAR - RECRUITER SUMMARY */}
              <Box row noWrap
                className='mt-05'
              >
                <Button small outlined
                  onClick={async () => {
                    await updateState({ showRecruiterSummary: false });
                    copyString(VALUE__RECRUITER_SUMMARY);
                  }}
                  className='min-w-160 mr-1'
                >
                  <Icon icon='content_copy' className='icon16 mr-05' />
                  Copy to clipboard
                </Button>
                <Button small secondary
                  className='c-white-hover min-w-180'
                  onClick={async () => {
                    await updateState({ showRecruiterSummary: false });
                    openMessageEmailPreview({
                      subject: 'Recruiter Summary',
                      body: VALUE__RECRUITER_SUMMARY.replace(/\n/g, '<br/>')
                    })
                  }}
                >
                  <Icon icon='mail' className='icon16 mr-05' />
                  Open in Email
                </Button>
              </Box>

            </Fieldset>

          </Box>

          {/** ACTIONS BAR - BOTTOM */}
          <Box row flex1
            className='space-between p-1'
            style={{ minHeight: '3rem', zIndex: 3 }}
          >

            {/** ACTIONS BAR - BOTTOM-LEFT */}
            <Box row>
              <Button small secondary
                acl={YES__VIEW_TYPE__RECRUITER}
                className='mr-1'
                label='Summary for Recruiter'
                onClick={() => {
                  updateState({
                    showRecruiterSummary: !YES__SHOW__RECRUITER_SUMMARY
                  })
                }}
              />
            </Box>

            {/** ACTIONS BAR - BOTTOM-RIGHT */}
            <Box row noWrap>
              {actionBarBottomRight}
            </Box>

          </Box>
        </Box>

      </Box>
    ) : (
      <Box column flex1>

        {/** TOP BAR */}
        {ELEMENT__TOP_BAR}

        {/** CONFIRMATION */}
        <Box className='centered-content' style={{ minHeight: 256 }}>
          <Card className='p-2'>
            <Box column>
              <Label className='tt-none'>
                Confirmation Required
              </Label>
              <Typography sub>
                Please confirm that you agree the following data is confidential
              </Typography>
              <Typography className='mt-1'>
                Check YES to continue
              </Typography>
              <Box row className='mt-1'>
                <Checkbox
                  checked={state.yesUserAgreeToTerms || YES__USER_AGREE_TO_TERMS}
                  onChange={async (event, value) => {
                    await updateState({ yesUserAgreeToTerms: value });
                    delay(() => {
                      if (value === true) {
                        const VALUE__USER__NAME = Core.getUserName() || '[user name]';
                        const VALUE__USER__EMAIL = Core.getUser().email || '[user email]';
                        sendSafeEmail({
                          to: process.env.REACT_APP_TEAM_EMAIL,
                          subject: compileText(TEMPLATE__SUBJECT__COLLECTION_USER_AGREE_TO_TERMS, {
                            USER__NAME: VALUE__USER__NAME,
                          }),
                          html: compile(TEMPLATE__BODY__COLLECTION_USER_AGREE_TO_TERMS)({
                            USER__NAME: VALUE__USER__NAME,
                            USER__EMAIL: VALUE__USER__EMAIL,
                          })
                        });
                        setUserAgreeToEngagementsTerms(true);
                      }
                      else {
                        unsetUserAgreeToEngagementsTerms();
                      }
                      updateState(); // force local re-render, for drawer
                    }, 100);
                  }}
                  label='YES'
                />
                <Checkbox
                  checked={state.notUserAgreeToTerms || NOT__USER_AGREE_TO_TERMS}
                  onChange={async (event, value) => {
                    await updateState({ notUserAgreeToTerms: value });
                    delay(() => {
                      if (value === true) {
                        setUserAgreeToEngagementsTerms(false);
                        Core.closeDrawer();
                      }
                      else {
                        unsetUserAgreeToEngagementsTerms();
                        updateState(); // force local re-render, for drawer
                      }
                    }, 100);
                  }}
                  label='NO'
                />
              </Box>
            </Box>
          </Card>
        </Box>

      </Box>
    )
  )

}
