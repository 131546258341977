import {
  compile
} from 'handlebars';
import Core from '../../Core';
import globalErrorHandler from '../../Error/globalErrorHandler.fun';
import {
  getPersonName,
  Obj
} from '../../Object.lib';
import {
  TEMPLATE__CANDIDATE__CAVE__BODY,
  TEMPLATE__CANDIDATE__CAVE__SUBJECT
} from '../../templates/CAVE/RepresentationEmailForCandidate.template';
import fetchRecipientsCAVE from './fetchRecipientsCAVE.fun';

export default async function generateCandidateCAVE({
  engagement = {}
}) {
  try {
    const candidate = Obj(engagement.candidate);
    const job = Obj(engagement.job);
    const recipients = await fetchRecipientsCAVE({
      candidateId: candidate.id,
      container: 'candidate'
    });
    const recipientsRecruiter = await fetchRecipientsCAVE({
      candidateId: candidate.id,
      container: 'recruiter'
    });
    const result = {
      from: recipients.from,
      emails: recipients.to,
      to: [...recipients.to, ...recipientsRecruiter.to],
      cc: recipientsRecruiter.cc,
      bcc: recipientsRecruiter.bcc,
      subject: compile(
        TEMPLATE__CANDIDATE__CAVE__SUBJECT
      )({
        CANDIDATE__FULLNAME: getPersonName(candidate),
        EMPLOYER__NAME: job.employer.name
      }),
      body: compile(
        TEMPLATE__CANDIDATE__CAVE__BODY
      )({
        CANDIDATE__NAME: (
          candidate.nickName ||
          candidate.firstName
        ),
        CANDIDATE__EMAIL: candidate.email || '?',
        CANDIDATE__PHONE: candidate.phone || '?',
        RECRUITER__NAME: (
          candidate.recruiter.talentAdvisor ||
          candidate.recruiter._name
        ),
        EMPLOYER__NAME: job.employer.name,
        EMPLOYER__LINK: (
          (
            job.employer.url
          ) ? (
            `<a href="${job.employer.url}" target="_blank">${job.employer.name}</a>`
          ) : (
            job.employer.name
          )
        ),
        JOB__LINK: (
          (
            job.jobDescriptionPublicURL
          ) ? (
            `<a href="${job.jobDescriptionPublicURL}" target="_blank">${job.jobTitle}</a>`
          ) : (
            job.jobTitle
          )
        ),
        JOB__TITLE: job.jobTitle,
        SIGNATURE: Core.getEmailSignature(),
      })
    };
    return result;
  }
  catch (error) {
    globalErrorHandler(error);
  }
}
