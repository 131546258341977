import _, {
  uniqueId
} from "lodash";
import {
  Component
} from "react";
import {
  rearrangeElement
} from '../../lib/Array.lib';
import {
  joinKeyName
} from '../../lib/String.lib';
import {
  hasNoPadding,
  joinClassName
} from '../Layout/Libraries/Theme.lib';
import Box from '../Layout/Wrappers/Box';
import IconButton from '../Layout/Wrappers/IconButton';
import InputNumber from '../Layout/Wrappers/InputNumber';
import {
  Label
} from '../Layout/Wrappers/Typography';

class InputArray extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      actualArray: this.props.existing || [],
      updatedArray: this.props.existing || [],
      elementComp: this.props.elementComp,
      openOperatorOptions: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    // only update chart if the data has changed
    if (!_.isEqual(prevProps.existing, this.props.existing)) {
      this.setState({
        actualArray: this.props.existing || [],
        updatedArray: this.props.existing || [],
        elementComp: this.props.elementComp
      });
    }
  }

  handlerFieldOnBlur = (element) => {
    let arr = this.state.updatedArray;
    let myIndex = arr.findIndex(
      (el) => parseInt(el.id) === parseInt(element.id)
    );
    arr[myIndex] = element;

    this.setState({ updatedArray: arr }, (st) => {
      !!this.props.parentUpdater &&
        this.props.parentUpdater(this.state.updatedArray);
    });
  };

  handlerDeleteField = (element) => {
    let arr = this.state.updatedArray;
    let newArray = arr.filter((el) => parseInt(el.id) !== parseInt(element.id));

    this.setState({ updatedArray: newArray }, (st) => {
      this.props.parentUpdater &&
        this.props.parentUpdater(this.state.updatedArray);
    });
  };

  markAsDefault = (element) => {
    let arr = _.cloneDeep(this.state.updatedArray);
    let newArray = [];

    arr.forEach((el) => {
      let newElement = {};

      if (element.id === el.id) {
        newElement = { ...element, isDefault: el.id === element.id };
      } else {
        newElement = { ...el, isDefault: el.id === element.id };
      }

      newArray.push(newElement);
    });

    this.setState({ updatedArray: newArray }, (st) => {
      !!this.props.parentUpdater &&
        this.props.parentUpdater(this.state.updatedArray);
      !!this.props.onMarkAsDefault && this.props.onMarkAsDefault(element);
    });
  };

  dataToFields() {
    const dataArray = this.state.updatedArray;
    const ElementComp = this.state.elementComp;
    const newRecordId = _.cloneDeep(this.state.newRecordId);
    const { onlyView, extraInfo, orderEnabled } = this.props;

    if (!ElementComp) { return; }

    const out = dataArray.map((el, index) => {
      if (!el.id) {
        el.id = index;
      }
      return (
        <Box row w100
          key={joinKeyName([
            'InputArray',
            el.id,
            el.timestampTemporary,
            index,
            uniqueId(),
          ])}
          role='InputArrayDataFields'
          className={joinClassName([
            'flex-align-left-top py-05 px-1 rounded bg-purple-hover',
            !!index && 'mt-05',
            onlyView ? 'bg-white-light' : 'bg-white-dark',
          ])}
        >

          <Box row
            acl={!onlyView && orderEnabled}
            role='InputArrayIndex'
            title={
              <>
                To adjust the sequence, edit the sequence number to the desired rank.
              </>
            }
            className='flex-align-left-top'
          >
            <InputNumber
              step={1}
              style={{ minWidth: 36, maxWidth: 36 }}
              className='mr-1'
              value={index}
              onChange={(value) => {
                rearrangeElement(dataArray, index, value);
                this.setState({ updatedArray: dataArray }, (st) => {
                  !!this.props.parentUpdater &&
                    this.props.parentUpdater(dataArray);
                });
              }}
            />
          </Box>

          <ElementComp
            handlerDeleteItem={this.handlerDeleteField}
            element={el}
            isNewRecord={el.id === newRecordId}
            onDone={this.handlerFieldOnBlur}
            markAsDefault={this.markAsDefault}
            onlyView={onlyView}
            extraInfo={extraInfo}
            reRender={() => this.setState({})}
          />

        </Box>
      );
    });

    if (!!newRecordId) {
      this.setState({ newRecordId: "" });
    }
    return out;
  }

  insertOneToArray = (props = {}) => {
    // const dataArray = this.state.updatedArray;
    let newRecordId = new Date().getTime();
    this.setState({
      updatedArray: [{ id: newRecordId, ...props }, ...this.state.updatedArray],
      newRecordId
    });
  };

  handlerOnAdd = () => {
    let { createWithPopup } = this.props;

    if (!!createWithPopup) {
      this.setState({ createWithPopup: true });
    }
    else {
      this.insertOneToArray();
    }
  };

  handlerPopupClose = () => {
    let { createWithPopup } = this.state;
    this.setState({ createWithPopup: !createWithPopup });
  };

  handlerPopupApply = (props) => {
    let { createWithPopup } = this.state;
    this.insertOneToArray(props);

    this.setState({ createWithPopup: !createWithPopup }, () => {
      !!this.props.parentUpdater &&
        this.props.parentUpdater(this.state.updatedArray);
    });
  };

  render() {

    let { heading, CreatePopupComponent, onlyView, extraInfo } = this.props;
    let { createWithPopup } = this.state;

    const className = [
      hasNoPadding(this.props) && 'p-1',
      this.props.className,
    ]

    return (
      <Box column w100
        role='InputArray'
        className={joinClassName(className)}
      >

        <Box row w100 acl={!onlyView}>
          <Label className="m-0 mr-1" style={{ width: 36 }}>
            {heading}
          </Label>
          <IconButton primary
            onClick={this.handlerOnAdd}
            title="Add"
            icon={!this.props.subsection ? 'add_circle' : 'add_circle_outline'}
          />
        </Box>

        {this.dataToFields()}

        {createWithPopup && (
          <CreatePopupComponent
            onCancel={this.handlerPopupClose}
            onApply={this.handlerPopupApply}
            extraInfo={extraInfo}
          />
        )}
      </Box>
    );
  }
}

export default InputArray;
