import Candidate from '../../../lib/Candidate';
import Core from '../../../lib/Core';
import Definition from '../../../lib/Definition';
import Fieldset from '../../Layout/Wrappers/Fieldset';
import RadioGroup from '../../Layout/Wrappers/RadioGroup';
import RichTextBox from '../../Layout/Wrappers/RichTextBox';
import Typography from '../../Layout/Wrappers/Typography';
import {
  CANDIDATE_UI
} from '../CandidateUI.dic';
import {
  CandidateEditController
} from '../Edit/CandidateEdit';
import confirmDifferentPlatformRating from './confirmDifferentPlatformRating';

export default function FieldsetPlatformRating(props) {
  const candidate = CandidateEditController().state;
  const _evalOpenDialog = () => {
    // LIVE CANDIDATE STATE
    const candidate = CandidateEditController().state;
    const calculatedPR = Candidate.calculatePlatformRating({ candidate });
    const recommendedPlatformRatingTag = Definition.getTag({
      categoryKey: 'platformRating',
      tagId: calculatedPR
    });
    return (
      Core.isAdmin() &&
      (
        +candidate.platformRating
        !==
        +recommendedPlatformRatingTag.id
      )
    );
  };
  return (
    <>
      {/* KEEP IT JUST IN CASE IN FUTURE THEY WANT TO USE */}
      {/** * /}
      <Once
        promise={async () => {
          if (_evalOpenDialog()) {
            confirmDifferentPlatformRating({ candidate });
          }
        }}
      />
      {/** */}
      <Fieldset
        title={CANDIDATE_UI.platformRating.title}
      >
        {
          Candidate.calculatePlatformRating({
            candidate,
            component: true
          })
        }
        <RadioGroup
          name="platformRating"
          options={Definition.get("platformRating")}
          value={(
            candidate.platformRating
              ? candidate.platformRating
              : Candidate.calculatePlatformRating({ candidate })
          )}
          onChange={(event, platformRating) => {
            CandidateEditController().setStateStore({
              platformRating,
              errorPlatformRating: "",
              key: 'platformRating'
            }, () => {
              if (_evalOpenDialog()) {
                confirmDifferentPlatformRating({ candidate });
              }
            });
          }}
        />
        {candidate.errorPlatformRating && (
          <Typography className="cred">
            {candidate.errorPlatformRating}
          </Typography>
        )}
      </Fieldset>
      <Fieldset
        title={CANDIDATE_UI.platformRatingNotes.title}
      >
        <RichTextBox
          name="platformRatingNotes"
          value={candidate.platformRatingNotes}
          onChange={platformRatingNotes => {
            CandidateEditController().setStateStore({
              platformRatingNotes,
              key: 'platformRatingNotes'
            });
          }}
        />
      </Fieldset>
    </>
  );
}
