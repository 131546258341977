import lodash from 'lodash';
import {
  join
} from './Array.lib';
import {
  validateEmail
} from './GenericTools.lib';

export function Abbr({ value, chars = 2 }) {
  return Str(value).replace(/\b(\w)[^\s]+\b/g, "$1").replace(/\s/g, "").slice(0, chars).toUpperCase();
}

export function Abbreviation(value) {
  return Abbr({ value, chars: 1 });
}

export function FirstWord(value) {
  return String(value || '').split(' ')[0].trim();
}

export function isNonEmptyString(value) {
  return !isEmptyString(value);
}

export function isEmptyString(value) {
  return !String(value || '').trim();
}

export function isNull(value) {
  return value === null;
}

export function isNullOrUndefined(value) {
  return (value === null) || (value === undefined);
}

/**
 *  
 * @param {string} value A string to encode.
 * @returns A string base64 encoded.
 */
export function encodeBase64(value) {
  return window.btoa(unescape(encodeURIComponent(trim(value))));
}

/**
 * 
 * @param {string} value A string base64 encoded.
 * @returns A decoded UTF-8 string.
 */
export function decodeBase64(value) {
  try {
    // Decode base64 using atob (available in browsers)
    const decodedString = atob(value);
    return decodedString;
  }
  catch (error) {
    console.error('Base64 decoding error:', error);
    // Or handle the error as needed
    return null;
  }
}

export function camelCase(str) {
  return String(str || '').replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
    return index === 0 ? word.toLowerCase() : word.toUpperCase();
  }).replace(/\s+/g, '');
}

/**
 *
 * Removes unwanted characters from strings.
 *
 * Amended to sanitize phone numbers differently by allowing '(', and ')' to allow for formatted phone
 * numbers from Sovren parser. Otherwise filter '(', and ')'
 */
export function sanitizeString(str, key = '') {
  if (typeof str === 'string') {
    if (key === 'phone') {
      str = str.replace(/[^:/@a-z0-9áéíóúñü .,_\-+()]/gim, '');
    } else {
      str = str
        .normalize('NFD') // Normalize accented characters to their base form
        .replace(/[^:/@a-z0-9áéíóúñü \u0300-\u036f.,_\-+]/gim, '') // Remove unwanted characters, including diacritics
        .normalize('NFC'); // Restore accented characters to their original form
    }
    return str.trim();
  } else {
    return str;
  }
};

export function Str(value, defaultValue) {
  return String(value || defaultValue || '');
}

export function trim(value) {
  return Str(value).trim();
}

export function formatNumberToAmericanFormat(number) {
  // Make sure the input is a valid numeric value
  if (isNaN(number)) {
    return "";
  }

  // Convert the number to a string and split it into integer and decimal parts
  const numberParts = number.toString().split(".");
  const integerPart = numberParts[0];
  const decimalPart = numberParts[1] || "";

  // Add commas as thousands separators to the integer part
  const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Format the whole number with the period as the decimal separator
  const formattedNumber = decimalPart !== "" ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart;

  return formattedNumber;
}

export function validateString(value, trim = false) {
  return (typeof value === 'string') ? trim ? value.trim() : value : '';
}

/**
 * 
 * @param {string} html 
 * @returns {string}  plain text
 */
export function convertHtmlToPlainText(html = '') {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = html;
  return tempDiv.textContent || tempDiv.innerText || '';
}

export function htmlHasTextContent(html = '') {
  return trim(convertHtmlToPlainText(html)).length > 0;
}

export function compileText(value, context = {}) {
  function compileRecursive(obj) {
    if (typeof obj === 'string') {
      let compiledText = String(obj);
      Object.keys(context).forEach(key => {
        compiledText = compiledText.replace(new RegExp(`\{\{${key}\}\}`, 'g'), context[key]);
      });
      return compiledText;
    } else if (Array.isArray(obj)) {
      return obj.map(item => compileRecursive(item));
    } else if (typeof obj === 'object' && obj !== null) {
      const compiledObj = {};
      for (const key in obj) {
        compiledObj[key] = compileRecursive(obj[key]);
      }
      return compiledObj;
    } else {
      return obj;
    }
  }
  return compileRecursive(value);
}

export const isValidEmail = validateEmail;

export function padWithZeros(number, length) {
  return String(number).padStart(length, '0');
}

export const isString = lodash.isString;
export const uniqueId = lodash.uniqueId;

export function joinKeyName(names = []) {
  return join(names, '__');
}
