import * as Sentry from "@sentry/browser";

import {
  unsetUserAgreeToEngagementsTerms
} from '../../../components/Layout/Libraries/UserAgreeEngagementsTerms.lib';
import Core from '../../Core';
import Definition from '../../Definition';
import {
  validateEmail
} from '../../GenericTools.lib';
import Http from '../../Http';
import Store from '../../Store';

export const ROLE__SYS_ADMIN = 'SysAdmin';
export const ROLE__LIMITED_RECRUITER = 'LimitedRecruiter';
export const ROLE__TRUSTED_RECRUITER = 'TrustedRecruiter';
export const ROLE__EMPLOYER_RECRUITER = 'EmployerRecruiter';
export const JWT__EXPIRED_URL = '/#/jwt/expired';
export const JWT__SESSION_URL = '/#/jwt/session';

export function logout({ redirect } = {}) {
  Sentry.setUser();
  Store.remove("ui-version");
  Store.remove("history");
  Store.remove("BE");
  Store.remove("FE");
  Store.remove("email");
  Store.remove("session");
  unsetUserAgreeToEngagementsTerms();
  if (!!String(redirect || '').trim()) {
    document.location.href = redirect;
    document.location.reload();
  }
}

export async function login(inputs) {
  Core.showLoader('Login...');
  return Http.post(
    Core.getApi("Accounts/login?include=user"),
    inputs
  ).then(response => setSession({ session: response }));
}

export function setSession({ session = {}, redirect = Store.get("path") }) {
  if (session.id) {
    if (Definition.test("state", session.user.state, /inactive/)) {
      Core.showError('User is inactive');
    } else {
      session.role = session.role || session.user.role;
      Sentry.setUser({
        email: session.user.email,
        id: session.user.id
      });
      Store.set("email", session.user.email);
      Store.set("session", session);
      window["setFullStory"]();
      document.location.reload();
      if (!!redirect) {
        document.location.href = redirect;
      }
    }
  } else {
    Core.showError('Unexpected error');
  }
}

export function redirectToExpired() {
  document.location.href = JWT__EXPIRED_URL;
  document.location.reload();
}

const BLACKLISTED_JWT = [
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2NvdW50SWQiOiI2NDJmNGE4YTFmMjUzZTBmMjQ4ZTcxMmUiLCJpYXQiOjE2OTEwMDEwMDAsImV4cCI6MTY5MTQzMzAwMH0.8_XDRCJ6GP_9JauIR2E_-cqxbzxi-qbmt25GGIp-dd4'
];

export async function exchangeJWTToken({ employerId, jwt }) {
  Core.logout();
  if (BLACKLISTED_JWT.includes(jwt)) {
    return redirectToExpired();
  }
  let session = await Http.post(Core.getApi(`Accounts/jwt?jwt=${jwt}`)).catch(error => {
    console.debug({ error });
    if (error === true) {
      redirectToExpired();
    }
    else {
      Core.showError(error, redirectToExpired);
    }
  });
  if (session) {
    session.employerId = employerId;
    setSession({ session })
  }
}

export async function generateMagicLink({ email }) {
  if (validateEmail(email)) {
    let compiled = await Http.get(Core.getApi(`Employers/_generateMagicLink`), { email }).catch(Core.showError);
    let { rendered = {}, mixins = {}, expiration } = compiled || {};
    let { subject = '', bodyHtml = '' } = rendered;
    let { from } = mixins;
    let error = { error: true };
    if (from && String(bodyHtml || '').includes('POST_PROCESSING')) {
      let sent = await require('../Email/Email.lib').sendSafeEmail({
        from,
        to: email,
        subject,
        html: bodyHtml
      });
      if (sent) {
        return { expiration };
      }
      else {
        return error;
      }
    }
    else {
      return error;
    }
  }
  else {
    Core.showError(`Invalid email`);
  }
  return false;
}

export async function getEmployerPendingsURL({ accountId }) {
  return Http.get(
    Core.getApi('Accounts/_getEmployerPendingsURL'),
    { accountId }
  );
}
