import {
  Component
} from "react";
import {
  Str
} from '../../../../../../lib/String.lib';
import Menu from '../../../../../Layout/Wrappers/Menu';
import Typography from '../../../../../Layout/Wrappers/Typography';

class Or extends Component {
  constructor() {
    super(...arguments);
    this.state = { number: this.props.number || 1 };
  }
  updateAttr = (key, value) => {
    const { onUpdate } = this.props;
    this.setState({ [key]: value }, () => {
      !!onUpdate && onUpdate('number', value)
    })
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.number !== this.props.number) {
      this.setState({ number: this.props.number });
    }
  }
  render() {
    let { number } = this.state;
    let { editable } = this.props;
    return (
      <>
        {
          (
            !!editable
          ) ? (
            <Menu dropdown
              name="level"
              required
              value={number}
              style={{ width: 80 }}
              paperStyle={{ maxWidth: 80, maxHeight: 500 }}
              onChange={(number) => {
                console.debug('number', number);
                this.updateAttr('number', number)
              }}
              options={[
                1, 2, 3, 4, 5, 6, 7, 8, 9, 10
              ].map(
                (num, index) => ({
                  id: index + 1,
                  label: Str(num)
                })
              )}
              className='mr-05'
            />
          ) : (
            <Typography className='mr-05'>
              &nbsp;{number}
            </Typography>
          )
        }
        <Typography>
          of
        </Typography>
      </>
    );
  }
}

export default Or;
