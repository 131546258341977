import {
  Alert,
  AlertTitle
} from "@mui/material";
import React, {
  useState
} from "react";
import Core from "../../lib/Core";
import {
  Fun
} from '../../lib/Function.lib';
import Dialog from '../Layout/Wrappers/Dialog';
import {
  LoadingImage
} from "../Shared/Loader";

export default function AlertDialog() {
  const [state, setState] = useState({ open: false });
  function onClose() {
    const onClose = Fun(state.onClose);
    setState({ ...state, open: false, onClose: Fun });
    onClose();
  }
  Core.setKeyValue('AppAlertDialog', {
    open({ title, message, severity, onClose = () => null, icon } = {}) {
      if (state.open && state.severity === 'error') { return; }
      setState({
        open: true,
        title,
        message,
        severity,
        onClose,
        icon
      });
    },
    close: onClose
  });
  let showTitle = state.severity && state.title !== false;
  return (
    <Dialog
      onClose={onClose}
      open={state.open}
    >
      <Alert
        severity={state.severity || 'info'}
        variant="standard"
        className='p-2 f-lg'
        style={{ maxWidth: sizes.m7 }}
        icon={!state.message ? LoadingImage : state.icon}
      >
        {showTitle && (
          <AlertTitle className="capitalize" id="app-alert-dialog-title">
            {state.title || state.severity}
          </AlertTitle>
        )}
        <div className={showTitle ? 'f-lg mt-2' : 'f-lg'}>{state.message || 'Loading...'}</div>
      </Alert>
    </Dialog>
  );
}

/**
 * 
 * @param {object} options
 * @param {string} options.title 
 * @param {string} options.message If receives true boolean, then just returns;
 * @param {string} options.severity 
 * @param {function} options.onClose 
 * @param {object} options.icon JSX component;
 * @returns {void} As this method is used to catch errors should never return a value;
 */
export function showAlert({ title, message, severity, onClose = () => null, icon } = {}) {
  if (message === true) { setTimeout(hideAlert); return; }
  try {
    if (severity === 'error') { console.error(message); }
    Core.getKeyValue('AppAlertDialog').open({
      title,
      message: React.isValidElement(message) ? message : message?.toString(),
      severity,
      onClose,
      icon
    });
  } catch {
    console.debug(`${title ? `${title}\n\n` : ''}${message}`);
    onClose();
  }
}

export function hideAlert() {
  try { Core.getKeyValue('AppAlertDialog').close(); } catch { }
}