import moment from 'moment';
import {
  join
} from '../Array.lib';
import Core from '../Core';
import globalErrorHandler from '../Error/globalErrorHandler.fun';
import {
  Obj
} from '../Object.lib';
import Streak from '../Streak';
import {
  compileText,
  trim
} from '../String.lib';

export default async function addStreakNote({
  boxKey = '',
  note = '',
  prepend = true,
}) {
  try {
    if (boxKey && note) {
      const box = Obj(await Streak.getBox(boxKey));
      const notes = trim(box.notes);
      await Streak.updateNotes(
        {
          boxKey: boxKey,
          notes: join([
            (prepend === true) && note,
            notes,
            (prepend === false) && note
          ], '\n\n'),
        }
      );
    }
  }
  catch (error) {
    globalErrorHandler(error);
  }
}

export function getSignatureForStreak() {
  return compileText(
    ' - {{USER__NAME}} - {{DATE}}',
    {
      USER__NAME: Core.getUserName(),
      DATE: moment().tz(moment.tz.guess()).format('lll z')
    }
  );
}
